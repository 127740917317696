import {Box, Button, Typography} from '@mui/material';
import {LogoGreyCircle, ReferencingLayout} from 'common';
import errorConfusion from '@/assets/errorConfusion.jpeg';
import {StepContent} from '@/pages/BTL/Referencing/StepContent';
import {getActiveSpan} from '@sentry/react';
import {useIntercom} from 'react-use-intercom';

export const ErrorPage = () => {
  const intercom = useIntercom();
  const traceId = getActiveSpan()?.traceId;
  return (
    <ReferencingLayout>
      <Box sx={{minHeight: '500px'}}>
        <img
          src={errorConfusion}
          width="100%"
          height={200}
          style={{
            objectFit: 'cover',
            objectPosition: '50% 30%',
          }}
          alt="person looking under bed"
        />
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            marginTop: '-45px',
          }}
        >
          <LogoGreyCircle />
        </Box>
        <StepContent>
          <Typography variant="h1" mt={2}>
            Oops! Something went wrong
          </Typography>
          <Typography textAlign="center">
            The Ark team have been notified and will work on a fix. Please try
            again in a moment.
            {traceId
              ? ' If you would like to report this error please use the following reference to help us solve the problem.'
              : ''}
          </Typography>
          {traceId && <Typography variant="subtitle1">{traceId}</Typography>}
          <Button
            variant="contained"
            fullWidth
            color="secondary"
            sx={{mt: 4}}
            onClick={() => {
              intercom.trackEvent(`Render Error`, {
                traceId,
              });
              intercom.showNewMessage('');
            }}
          >
            Contact Support
          </Button>
        </StepContent>
      </Box>
    </ReferencingLayout>
  );
};
