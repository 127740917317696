import React from 'react';
import {Box, Container, useMediaQuery} from '@mui/material';
import {makeStyles} from '@mui/styles';
import TopBar from './TopBar';
import SideBar from './SideBar';
import {Outlet, useLocation} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
  contentContainer: {
    marginTop: 30,
    flexGrow: 1,
    marginLeft: 204,
    paddingLeft: 16,
    paddingRight: 16,
    flex: 1,
    display: 'flex',
    '@media print': {
      marginLeft: 0,
    },
  },
  contentContainerNoSideBar: {
    marginTop: 100,
    flex: 1,
    display: 'flex',
    marginLeft: '0px',
    '@media print': {
      marginTop: 0,
    },
  },
}));

type LayoutProps = {
  children?: React.ReactNode;
};

const Layout = (props: LayoutProps) => {
  const styles = useStyles();
  const {pathname} = useLocation();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const IGNORE_LAYOUT_ROUTES = ['/landlord-sign/'];
  const ignoreLayout = IGNORE_LAYOUT_ROUTES.some(route =>
    pathname.includes(route),
  );

  if (ignoreLayout) {
    return <Outlet />;
  }

  const containerStyle = isMobile
    ? styles.contentContainerNoSideBar
    : styles.contentContainer;

  return (
    <Container maxWidth="xl" sx={{height: '100vh'}}>
      <TopBar />
      <Container
        sx={{
          display: 'flex',
          px: {xs: 0.5},
          height: '100%',
        }}
        maxWidth="xl"
      >
        {!isMobile && <SideBar />}
        <Box className={containerStyle} maxWidth="100%">
          <Outlet />
        </Box>
      </Container>
    </Container>
  );
};

export default Layout;
