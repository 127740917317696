import {Box} from '@mui/material';
import {makeStyles} from '@mui/styles';

type Props = {
  src?: string;
  icon: React.ReactNode;
};

export const ListItemAvatar = ({icon, src}: Props) => {
  const styles = useStyles();

  if (src) {
    return <img src={src} className={styles.avatar} alt="List Item Avatar" />;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={styles.avatar}
    >
      {icon}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    objectFit: 'cover',
    borderRadius: 4,
    height: 60,
    width: 60,
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.grey[500],
  },
}));
