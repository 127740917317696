import {useMemo} from 'react';
import {edgesToNodes} from '../../utils/edgesToNodes';
import {
  usePackageCollectorsForHomeQuery,
  usePackageCollectorsForPackageQuery,
} from '../../generated/graphql';

type BaseProps = {
  homeId?: string;
  packageId?: string;
};

type PropsWithAddressees = BaseProps & {
  includeAddressees: true;
};

type PropsWithoutAddressees = BaseProps & {
  includeAddressees?: false;
};

type Props = PropsWithAddressees | PropsWithoutAddressees;

type CollectorWithAddressees = {
  name: string;
  userId: string | undefined;
};

export type CollectorWithoutAddressees = {
  name: string;
  userId: string;
};

export const usePackageCollectors = <T extends Props>(props: T) => {
  type Collector = T extends PropsWithAddressees
    ? CollectorWithAddressees
    : CollectorWithoutAddressees;

  const homeCollectors = usePackageCollectorsForHomeQuery({
    variables: {
      homeId: props.homeId as string,
    },
    fetchPolicy: 'cache-and-network',
    skip: !props.homeId,
  });

  const packageCollectors = usePackageCollectorsForPackageQuery({
    variables: {
      packageId: props.packageId as string,
    },
    fetchPolicy: 'cache-and-network',
    skip: !props.packageId,
  });

  const collectors: Collector[] = useMemo(() => {
    const home = props.homeId
      ? homeCollectors.data?.home
      : packageCollectors.data?.package?.home;

    const applicantUsers = edgesToNodes(home?.applications.edges).flatMap(
      application => application.applicant.user,
    );
    const residentUsers = home?.activeResidency?.residents.map(
      resident => resident.user,
    );
    const leaseholderUsers = edgesToNodes(
      home?.leasehold?.leaseholders.edges,
    ).map(leaseholder => leaseholder.user);

    const addressees = edgesToNodes(home?.packages?.edges)
      .filter(p => p.addressee != null)
      .flatMap(p => ({
        id: undefined,
        name: p.addressee as string,
      }));

    const collectorsMap = new Map<string, Collector>();

    [
      ...(residentUsers ?? []),
      ...(leaseholderUsers ?? []),
      ...(applicantUsers ?? []),
      ...(props.includeAddressees ? addressees : []),
    ].forEach(user => {
      const key = user.name.toLowerCase().trim();
      if (!collectorsMap.has(key)) {
        collectorsMap.set(key, {
          name: user.name,
          userId: user.id,
        } as Collector);
      }
    });

    return Array.from(collectorsMap.values());
  }, [homeCollectors.data, packageCollectors.data, props]);

  const home = props.homeId
    ? homeCollectors.data?.home
    : packageCollectors.data?.package?.home;

  return {
    collectors,
    home,
    refetch: props.homeId ? homeCollectors.refetch : packageCollectors.refetch,
    loading: homeCollectors.loading || packageCollectors.loading,
  };
};
