import {
  Article,
  ChatBubble,
  Checklist,
  Gavel,
  HomeRounded,
  HomeWorkRounded,
  Newspaper,
  PaymentRounded,
} from '@mui/icons-material';
import SideBarContent, {
  UserSideBarContentProps,
} from '../../layout/SideBar/SideBarContent';

type LandlordSideBarContentProps = UserSideBarContentProps;

export const LandlordSideBarContent = (props: LandlordSideBarContentProps) => {
  const sidebar_items = [
    {
      url: '/home',
      label: 'Home',
      icon: <HomeRounded fontSize="small" />,
    },
    {
      url: '/properties',
      label: 'Properties',
      icon: <HomeWorkRounded fontSize="small" />,
    },
    {
      url: '/news',
      label: 'News',
      icon: <Newspaper fontSize="small" />,
    },
    {
      url: '/documents',
      label: 'Documents',
      icon: <Article fontSize="small" />,
    },
    {
      url: '/payments',
      label: 'Payments',
      icon: <PaymentRounded fontSize="small" />,
    },
    {
      url: '/messages',
      label: 'Messages',
      icon: <ChatBubble fontSize="small" />,
    },
    {
      url: '/compliance',
      label: 'Compliance',
      icon: <Gavel fontSize="small" />,
    },
    // {
    //   url: '/tenants',
    //   label: 'Tenants',
    //   icon: <Groups fontSize="small" />,
    // },
    {
      url: '/tenancy-applications',
      label: 'Applications',
      icon: <Checklist fontSize="small" />,
    },
  ];

  return (
    <SideBarContent items={sidebar_items} onClose={props.onClose}>
      {props.children}
    </SideBarContent>
  );
};
