import {createContext, useCallback, useContext} from 'react';
import {useLocalStorage} from 'usehooks-ts';

export type SidebarCategory =
  | 'engagement'
  | 'communities'
  | 'payments'
  | 'concierge'
  | 'onboarding'
  | 'compliance';

type SideBarAccordionContextType = {
  isOpen: (category: SidebarCategory) => boolean;
  toggleOpen: (category: SidebarCategory) => void;
  toggleAll: () => void;
};

const SideBarAccordionContext = createContext<SideBarAccordionContextType>({
  isOpen: () => false,
  toggleOpen: () => {},
  toggleAll: () => {},
});

export const useSideBarAccordion = () => useContext(SideBarAccordionContext);

type SidebarCategoryOpenStates = Record<SidebarCategory, boolean>;
const defaultSidebarCategoryOpenStates: SidebarCategoryOpenStates = {
  engagement: true,
  communities: true,
  payments: true,
  concierge: true,
  onboarding: true,
  compliance: true,
};

export const SideBarAccordionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [categoryOpenStates, setCategoryOpenStates] =
    useLocalStorage<SidebarCategoryOpenStates>(
      'sideBarCategoryOpenStates',
      defaultSidebarCategoryOpenStates,
      {
        serializer: JSON.stringify,
        deserializer: JSON.parse,
      },
    );

  const toggleOpen = (category: SidebarCategory) => {
    setCategoryOpenStates(prevStates => {
      return {...categoryOpenStates, [category]: !prevStates[category]};
    });
  };

  const isOpen = (category: SidebarCategory) => {
    return categoryOpenStates[category];
  };

  const toggleAll = useCallback(() => {
    setCategoryOpenStates(prevStates => {
      const anyCollapsed = Object.values(prevStates).some(
        categoryIsOpen => categoryIsOpen === false,
      );
      if (anyCollapsed) {
        return defaultSidebarCategoryOpenStates;
      } else {
        return {
          engagement: false,
          communities: false,
          payments: false,
          concierge: false,
          onboarding: false,
          compliance: false,
        };
      }
    });
  }, [setCategoryOpenStates]);

  return (
    <SideBarAccordionContext.Provider value={{isOpen, toggleOpen, toggleAll}}>
      {children}
    </SideBarAccordionContext.Provider>
  );
};
