import {Place} from '@mui/icons-material';
import {useTheme} from '@mui/material';
import {Pill, PillProps} from '@ark/web';

type Props = {location: string} & Partial<PillProps>;

export const LocationPill = (props: Props) => {
  const {location, ...rest} = props;
  const {palette} = useTheme();
  return (
    <Pill
      label={location}
      color={palette.grey[200]}
      textColor={palette.grey[600]}
      startIcon={<Place fontSize="small" sx={{color: palette.grey[600]}} />}
      sx={{minWidth: 60}}
      {...rest}
    />
  );
};
