import React, {useState, useMemo} from 'react';
import {
  Card,
  CardProps,
  CardContent,
  IconButton,
  Typography,
  Box,
} from '@mui/material';
import {Close} from '@mui/icons-material';
import {FileBackdrop} from './FileBackdrop';
import {ThumbnailContent} from './ThumbnailContent';

type FileThumbnailProps = {
  file: File | string;
  onClose?: () => void;
  fullWidth?: boolean;
  imageOnly?: boolean;
} & CardProps;

export const FileThumbnail = ({
  file,
  onClose,
  fullWidth,
  sx,
  imageOnly,
}: FileThumbnailProps) => {
  const [open, setOpen] = useState(false);

  const fileName = useMemo(() => {
    if (!(file instanceof File)) {
      const splitUrl = [...file.split('/')];
      const last = splitUrl.pop()?.split('?')[0];
      return last;
    } else {
      return file.name.split('?').pop()?.toLowerCase();
    }
  }, [file]);

  const extension =
    [...(fileName?.split('.') || [])].pop()?.toLowerCase() || '';

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          '&:hover': {
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            cursor: 'pointer',
          },
          width: fullWidth ? '100%' : 'fit-content',
          maxWidth: fullWidth
            ? '100%'
            : {
                xs: '100%',
                sm: '100%',
                md: '75%',
                lg: '50%',
              },
          ...sx,
        }}
        onClick={() => setOpen(true)}
      >
        <ThumbnailContent
          file={file}
          fileName={fileName || ''}
          extension={extension}
          imageOnly={imageOnly}
        />
        {!imageOnly && (
          <CardContent
            sx={{
              overflow: 'hidden',
              alignSelf: 'center',
              marginLeft: 2,
            }}
          >
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              variant="h5"
            >
              {fileName}
            </Typography>
          </CardContent>
        )}
        {onClose && (
          <Box sx={{display: 'flex', alignItems: 'flex-start', p: 1}}>
            <IconButton
              aria-label="close"
              onClick={e => {
                e.stopPropagation();
                onClose();
              }}
            >
              <Close />
            </IconButton>
          </Box>
        )}
      </Card>
      <FileBackdrop open={open} onClose={() => setOpen(false)} file={file} />
    </>
  );
};
