import {useEffect, useCallback} from 'react';
import {useWindowSize} from './useWindowSize';

export const useOnScrolledToBottom = (
  onBottom: () => void,
  target?: HTMLElement | null,
) => {
  const windowHeight = useWindowSize();

  const height = target ? target.clientHeight : windowHeight.height;

  const handleScroll = useCallback(
    (e: Event) => {
      const scrollingElement = target
        ? e.target
        : (e.target as Document)?.scrollingElement;

      if (height && scrollingElement) {
        const {scrollHeight, scrollTop} = scrollingElement as Element;

        if (scrollHeight - scrollTop - height <= 100) {
          onBottom();
        }
      }
    },
    [height, onBottom],
  );

  useEffect(() => {
    if (!target) {
      window.addEventListener('scroll', handleScroll);

      if (document.documentElement.scrollHeight === window.innerHeight) {
        onBottom();
      }
    } else {
      target.addEventListener('scroll', handleScroll);

      if (target.scrollHeight === height && target.scrollTop === 0) {
        onBottom();
      }
    }

    return () => {
      if (!target) {
        window.removeEventListener('scroll', handleScroll);
      } else {
        target.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);
};
