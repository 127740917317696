import {useCollectPackagesForHomeMutation} from '@/generated/graphql';
import {LoadingButton} from '@mui/lab';
import {DialogContent, Link, Typography} from '@mui/material';
import {useEffect} from 'react';
import {CollectionDialogState} from '../CollectAllPackagesDialog';
import {useLogEvent} from '@/hooks/useLogEvent/useLogEvent';
import {useParams} from 'react-router-dom';
import FormSelect from '@/components/forms/FormSelect';
import {useForm} from 'react-hook-form';
import {Collector} from '@ark/react';

type FormValues = {
  collector: string | null;
};

type Props = {
  address: string;
  setDialogState: (state: CollectionDialogState) => void;
  onClose: () => void;
  applicantUserId: string | null;
  collectors: Collector[];
};

export const CollectAllPackages = ({
  address,
  setDialogState,
  onClose,
  applicantUserId,
  collectors,
}: Props) => {
  const logEvent = useLogEvent();
  const {homeId} = useParams() as {homeId: string};
  const [collectPackages, {loading}] = useCollectPackagesForHomeMutation();
  const {control, handleSubmit, setValue, watch} = useForm<FormValues>({
    defaultValues: {collector: null},
  });

  const onSubmit = async ({collector}: FormValues) => {
    await collectPackages({
      variables: {
        input: {
          home: homeId,
          user: collector,
        },
      },
    });
    onClose();
    logEvent({
      name: 'Packages On Home Page - Collect All Flow - Collection Confirmed',
    });
  };

  useEffect(() => {
    if (applicantUserId) {
      const applicant = collectors.find(o => o.userId === applicantUserId);
      setValue('collector', applicant?.userId || null);
    }
  }, [applicantUserId, setValue, collectors]);

  return (
    <DialogContent sx={{mx: {sm: 2}}}>
      <Typography variant="h3" textAlign="center" my={2}>
        Collect all packages
      </Typography>
      <Typography mb={3} textAlign="center" color="textSecondary">
        Please select who is collecting this package from the list below. This
        will mark all packages as collected for {address}.
      </Typography>
      <form id="collect-all-packages-form" onSubmit={handleSubmit(onSubmit)}>
        <FormSelect
          required
          label="Select Resident"
          name="collector"
          options={collectors.map(collector => ({
            label: collector.name,
            value: collector.userId as string,
          }))}
          control={control}
        />
      </form>
      <Typography color="text.secondary" textAlign="end" mt={1} mb={3}>
        Person not listed?{' '}
        <Link
          onClick={() => {
            setDialogState('ADD_APPLICANT');
          }}
        >
          Add them here
        </Link>
      </Typography>
      <LoadingButton
        loading={loading}
        color="secondary"
        variant="contained"
        fullWidth
        disabled={!watch('collector')}
        type="submit"
        form="collect-all-packages-form"
      >
        Confirm
      </LoadingButton>
    </DialogContent>
  );
};
