import {Box} from '@mui/material';
import {makeStyles} from '@mui/styles';
import tiledBackground from '../assets/tiledBackground.svg';
import {PropsWithChildren} from 'react';
import {ResponsiveDialog} from '../components/ResponsiveDialog';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    width: '100vw',
    backgroundImage: `url(${tiledBackground})`,
    backgroundSize: '250px',
  },
  dialog: {
    '& .MuiDialog-paper': {
      width: 560,
      minHeight: 200,
      borderRadius: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        borderRadius: 0,
      },
    },
  },
}));

type Props = {
  savingStep?: boolean;
};

export const ReferencingLayout = ({
  children,
  savingStep,
}: PropsWithChildren<Props>) => {
  const styles = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={styles.root}
      data-saving-step={`${savingStep}`}
    >
      <ResponsiveDialog open hideBackdrop className={styles.dialog}>
        {children}
      </ResponsiveDialog>
    </Box>
  );
};
