import {memo, forwardRef} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@mui/styles';
import {
  CustomContentProps,
  SnackbarContent,
  VariantType,
  useSnackbar,
} from 'notistack';
import {IconButton, Typography} from '@mui/material';
import {
  CheckCircle,
  Error,
  ReportProblem,
  Info,
  Close,
} from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    alignItems: 'center',
    padding: '6px 16px',
    borderRadius: '4px',
    boxShadow:
      '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    justifyContent: 'space-between',
  },
  default: {
    border: `1px solid ${theme.palette.common.black}`,
  },
  success: {
    border: `1px solid ${theme.palette.success.main}`,
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  warning: {
    border: `1px solid ${theme.palette.warning.main}`,
  },
  info: {
    border: `1px solid ${theme.palette.info.main}`,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
}));

const VariantIconMap: Record<
  VariantType,
  (props: {classes: Record<string, string>}) => JSX.Element | null
> = {
  default: () => null,
  success: ({classes}) => (
    <CheckCircle className={classes.icon} color="success" />
  ),
  error: ({classes}) => <Error className={classes.icon} color="error" />,
  warning: ({classes}) => (
    <ReportProblem className={classes.icon} color="warning" />
  ),
  info: ({classes}) => <Info className={classes.icon} color="info" />,
};

const ThemeResponsiveSnackbar = forwardRef<HTMLDivElement, CustomContentProps>(
  (props, forwardedRef) => {
    const classes = useStyles();
    const {message, variant} = props;
    const {closeSnackbar} = useSnackbar();

    return (
      <SnackbarContent
        ref={forwardedRef}
        className={clsx(classes.root, classes[variant])}
      >
        {VariantIconMap[variant]({classes})}
        <Typography variant="body1" className={classes.message}>
          {message}
        </Typography>
        <IconButton onClick={() => closeSnackbar(props.id)}>
          <Close fontSize="small" />
        </IconButton>
      </SnackbarContent>
    );
  },
);

export default memo(ThemeResponsiveSnackbar);
