import {PropsWithChildren} from 'react';
import {Box, IconButton, Theme, Tooltip} from '@mui/material';
import {Close} from '@mui/icons-material/';
import {makeStyles, useTheme} from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing(0.5)} ${theme.spacing(0.75)}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

type DialogActionBarProps = {
  onClose: () => void;
  closeIcon?: React.ElementType;
};

export const SideDialogActionBar = ({
  children,
  onClose,
  closeIcon,
}: PropsWithChildren<DialogActionBarProps>) => {
  const styles = useStyles();
  const {palette} = useTheme();
  const CloseIcon = closeIcon || Close;

  return (
    <Box className={styles.root}>
      <Tooltip title="Close">
        <IconButton aria-label="Close" onClick={onClose} sx={{mr: 4}}>
          <CloseIcon sx={{color: palette.grey[600], fontSize: 22}} />
        </IconButton>
      </Tooltip>
      <Box display="flex" gap={1}>
        {children}
      </Box>
    </Box>
  );
};
