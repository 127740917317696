import {
  HomePackagesDocument,
  ListPackagesDocument,
  PackageDocument,
  useCollectPackagesMutation,
} from '@/generated/graphql';
import {LoadingButton} from '@mui/lab';
import {
  Autocomplete,
  DialogContent,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import {SyntheticEvent, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {CollectionDialogState} from '../CollectionDialog';

import {useLogEvent} from '@/hooks/useLogEvent/useLogEvent';
import {usePackageCollectors} from '@ark/react';

type Props = {
  setDialogState: (state: CollectionDialogState) => void;
  onClose: () => void;
  userId: string | null;
};

export const CollectPackage = ({setDialogState, onClose, userId}: Props) => {
  const logEvent = useLogEvent();
  const {packageId} = useParams() as {packageId: string};

  const [collector, setCollector] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const {collectors, loading: collectorsLoading} = usePackageCollectors({
    packageId,
  });

  const [collectPackages, {loading}] = useCollectPackagesMutation({
    refetchQueries: [
      PackageDocument,
      ListPackagesDocument,
      HomePackagesDocument,
    ],
  });

  const onSubmit = async () => {
    if (!collector?.value) {
      return;
    }
    await collectPackages({
      variables: {
        input: {
          packageIds: [packageId],
          userId: collector?.value,
        },
      },
    });

    onClose();
    logEvent({
      name: 'View Package Dialog - Collection Flow - Collection Confirmed',
    });
  };

  useEffect(() => {
    if (userId) {
      const user = collectors.find(o => o.userId === userId);
      setCollector(user ? {label: user.name, value: user.userId} : null);
    }
  }, [userId, setCollector, collectors]);

  return (
    <DialogContent sx={{mx: {sm: 2}}}>
      <Typography variant="h3" textAlign="center" my={2}>
        Confirm Package Collection
      </Typography>
      <Typography mb={3} textAlign="center" color="textSecondary">
        Please select who is collecting this package from the list below
      </Typography>
      <Autocomplete
        value={collector}
        loading={collectorsLoading}
        onChange={(
          _: SyntheticEvent,
          v: {label: string; value: string} | null,
        ) => {
          setCollector(v);
        }}
        options={collectors.map(c => ({
          label: c.name,
          value: c.userId as string,
        }))}
        renderInput={params => (
          <TextField {...params} label="Select Resident" sx={{mb: 1}} />
        )}
      />
      <Typography color="text.secondary" textAlign="end" mb={3}>
        Person not listed?{' '}
        <Link
          onClick={() => {
            setDialogState('ADD_APPLICANT');
          }}
        >
          Add them here
        </Link>
      </Typography>
      <LoadingButton
        loading={loading}
        color="secondary"
        variant="contained"
        fullWidth
        disabled={!collector}
        onClick={onSubmit}
      >
        Confirm
      </LoadingButton>
    </DialogContent>
  );
};
