export default function IconButton() {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 6,
        },
      },
    },
  };
}
