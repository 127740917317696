import PageContainer from '@/components/PageContainer';
import {useMarketplaceItemsQuery} from '@/generated/graphql';
import useLoadMoreOnScroll from '@/hooks/useFetchMoreOnScroll';
import {edgesToNodes} from '@/utils/edgesToNodes';
import {Box} from '@mui/material';
import {
  CommunityFilter,
  EmptyList,
  ListSkeleton,
  SearchBar,
  useSelectedCommunities,
} from 'common';
import {SearchSelect, SearchSelectValue} from '@ark/web';
import {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {MarketplaceItemDialog} from '../MarketplaceItemDialog/MarketplaceItemDialog';
import {MarketplaceItem} from './MarketplaceItem';

const statusFilterOptions = [
  {value: 'Live', label: 'Live'},
  {value: 'Closed', label: 'Closed'},
  {value: 'Archived', label: 'Archived'},
];

export const MarketplacePage = () => {
  const [search, setSearch] = useState('');
  const communities = useSelectedCommunities();
  const [statusFilter, setStatusFilter] =
    useState<SearchSelectValue<string>>(statusFilterOptions);
  const {id} = useParams();
  const navigate = useNavigate();

  const {data, fetchMore, loading} = useMarketplaceItemsQuery({
    variables: {
      communities,
      search,
      status: statusFilter.map(filter => filter.value),
    },
    notifyOnNetworkStatusChange: true,
  });
  useLoadMoreOnScroll(data?.marketplaceItems, fetchMore);

  const emptyText = () => {
    if (search) {
      return (
        <EmptyList text="🔍  No items found for your search, please try again" />
      );
    } else {
      return <EmptyList text="🛍️ There are no marketplace items" />;
    }
  };

  return (
    <>
      <PageContainer>
        <Box display="flex" gap={2} mb={2}>
          <Box>
            <SearchBar
              label="Search for a marketplace item..."
              onChange={value => setSearch(value)}
            />
          </Box>
          <CommunityFilter fullWidth />
          <SearchSelect
            filterSelectedOptions
            options={statusFilterOptions}
            value={statusFilter}
            setValue={setStatusFilter}
            label="Status"
            multiple
          />
        </Box>
        {edgesToNodes(data?.marketplaceItems?.edges).map(item => (
          <MarketplaceItem key={item.id} item={item} />
        ))}
        {loading && <ListSkeleton />}
        {data?.marketplaceItems?.totalCount === 0 && emptyText()}
      </PageContainer>
      <MarketplaceItemDialog
        id={id}
        onClose={() => {
          navigate('/marketplace');
        }}
      />
    </>
  );
};
