import {CircularProgress, IconButton, Tooltip} from '@mui/material';
import {useTheme} from '@mui/styles';
import React from 'react';

type Props<T = string> = {
  Icon: React.ElementType;
  tooltip?: string;
  onClick: () => void;
  current?: T;
  displayFor: T[];
  loading?: boolean;
  disabled?: boolean;
};

export const ActionBarButton = <T,>({
  Icon,
  tooltip,
  onClick,
  displayFor,
  current,
  loading,
  disabled,
}: Props<T>) => {
  const {palette} = useTheme();

  if (current && !displayFor.includes(current)) return null;

  return (
    <Tooltip title={tooltip}>
      <span>
        <IconButton
          aria-label="Save"
          onClick={onClick}
          sx={{color: palette.grey[600]}}
          disabled={disabled}
        >
          {!loading && <Icon />}
          {loading && <CircularProgress size={24} color="inherit" />}
        </IconButton>
      </span>
    </Tooltip>
  );
};
