import {Breadcrumbs} from '@/components/Breadcrumbs';
import {FileThumbnail} from '@/components/FileThumbnail';
import {ActionBarButton} from '@/components/dialogs/SideDialog/ActionBarButton';
import SideDialog from '@/components/dialogs/SideDialog/SideDialog';
import {SideDialogActionBar} from '@/components/dialogs/SideDialog/SideDialogActionBar';
import {SideDialogTitle} from '@/components/dialogs/SideDialog/SideDialogTitle';
import {useMarketplaceItemQuery} from '@/generated/graphql';
import {edgesToNodes} from '@/utils/edgesToNodes';
import {fullImageUrl} from '@/utils/files';
import {Close, Archive, Notes, Person, PhotoLibrary} from '@mui/icons-material';
import {Box, DialogContent, Typography, useTheme} from '@mui/material';
import {convertToGBP} from 'common';
import {DateTime} from 'luxon';
import {useState} from 'react';
import {MarketplaceStatusPill} from '../MarketplacePage/MarketplaceStatusPill';
import {ArchiveMarketplaceItemDialog} from './ArchiveMarketplaceItemDialog';
import {MarketplaceItemComments} from './MarketplaceItemComments/MarketplaceItemComments';

type Props = {
  id?: string;
  onClose: () => void;
};

export const MarketplaceItemDialog = (props: Props) => {
  const {palette} = useTheme();
  const [open, setOpen] = useState(false);

  const {data, loading} = useMarketplaceItemQuery({
    variables: {
      id: props.id || '',
    },
    skip: !props.id,
  });

  const item = data?.marketplaceItem;
  const hasImages = Boolean(item?.images?.edges?.length);
  const hasComments = Boolean(item?.comments?.totalCount);

  return (
    <>
      <SideDialog open={Boolean(props.id)} onClose={props.onClose}>
        <SideDialogActionBar onClose={props.onClose} closeIcon={Close}>
          {!item?.archivedOn && (
            <ActionBarButton
              onClick={() => {
                setOpen(true);
              }}
              Icon={Archive}
              current="View"
              displayFor={['View']}
              tooltip="Archive item"
            />
          )}
        </SideDialogActionBar>
        <Box
          mt={2}
          mx={4}
          borderBottom="1px solid"
          borderColor={palette.grey[300]}
        >
          <Breadcrumbs breadcrumbs={['Marketplace', 'View Item']} />
          <SideDialogTitle loading={loading}>{item?.title}</SideDialogTitle>
          <Box display="flex" alignItems="center" my={1} gap={1}>
            <MarketplaceStatusPill
              isClosed={Boolean(item?.closedOn)}
              isArchived={Boolean(item?.archivedOn)}
            />
            {item?.price && (
              <Typography variant="subtitle1">
                {convertToGBP(+item.price)}
              </Typography>
            )}
          </Box>
          <Typography variant="h5" my={1} color={palette.grey[500]}>
            {item?.community?.name}
          </Typography>
        </Box>
        <DialogContent>
          {Boolean(item?.archivedOn) && (
            <Box
              display="flex"
              gap={1}
              alignItems="flex-start"
              flexDirection="column"
              sx={{background: palette.grey[100], borderRadius: 2, p: 1, mb: 2}}
            >
              <Box display="flex" gap={1} alignItems="center">
                <Archive fontSize="small" />
                <Typography variant="subtitle1">
                  Archived on:{' '}
                  {DateTime.fromISO(item?.archivedOn ?? '').toLocaleString(
                    DateTime.DATETIME_MED,
                  )}{' '}
                  by {item?.archivedBy?.name}:
                </Typography>
              </Box>
              <Box
                display="flex"
                bgcolor={palette.common.white}
                borderRadius={1}
                p={1}
                width="100%"
              >
                <Typography variant="body2">
                  {item?.archivedReason || 'No reason provided'}
                </Typography>
              </Box>
            </Box>
          )}
          <Box
            display="flex"
            gap={1}
            alignItems="center"
            sx={{background: palette.grey[100], borderRadius: 2, p: 1}}
          >
            <Person fontSize="small" />
            <Typography variant="subtitle1">
              Seller: {item?.user?.firstName} {item?.user?.lastName}
            </Typography>
          </Box>
          <Box
            display="flex"
            gap={1}
            alignItems="flex-start"
            flexDirection="column"
            sx={{background: palette.grey[100], borderRadius: 2, p: 1, mt: 2}}
          >
            <Box display="flex" gap={1} alignItems="center">
              <Notes fontSize="small" />
              <Typography variant="subtitle1">Description</Typography>
            </Box>
            <Box
              display="flex"
              bgcolor={palette.common.white}
              borderRadius={1}
              p={1}
              width="100%"
            >
              <Typography variant="body2">
                {item?.description || 'No description provided'}
              </Typography>
            </Box>
          </Box>
          {hasImages && (
            <Box
              display="flex"
              gap={1}
              alignItems="flex-start"
              flexDirection="column"
              p={1}
              sx={{background: palette.grey[100], borderRadius: 2, mt: 2}}
            >
              <Box display="flex" gap={1} alignItems="center">
                <PhotoLibrary fontSize="small" />
                <Typography variant="subtitle1">Photos</Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={1} width={'100%'}>
                {edgesToNodes(item?.images.edges).map((node, index) => (
                  <FileThumbnail
                    key={index}
                    file={fullImageUrl(node.image)}
                    imageOnly
                  />
                ))}
              </Box>
            </Box>
          )}
          {props.id && hasComments && <MarketplaceItemComments id={props.id} />}
        </DialogContent>
      </SideDialog>
      <ArchiveMarketplaceItemDialog
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
