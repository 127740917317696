import {
  Box,
  BoxProps,
  CircularProgress,
  CircularProgressProps,
} from '@mui/material';

type Props = BoxProps & {
  circularProgressProps?: CircularProgressProps;
};

export const HorizontalCenterCircularProgress = ({
  circularProgressProps,
  ...props
}: Props) => {
  return (
    <Box display="flex" marginY="32px" justifyContent="center" {...props}>
      <CircularProgress {...circularProgressProps} />
    </Box>
  );
};
