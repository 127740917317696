import {ExpandMore} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  BoxProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import {useState} from 'react';

type Props = {
  title: string;
  startIcon: React.ReactNode;
  startCollapsed?: boolean;
  accordionProps?: AccordionProps;
  typographyProps?: TypographyProps;
  children: React.ReactNode;
} & BoxProps;

export const SideDialogAccordion = (props: Props) => {
  const {
    title,
    startIcon,
    startCollapsed,
    accordionProps,
    typographyProps,
    children,
    ...rest
  } = props;
  const [expanded, setExpanded] = useState<boolean>(!startCollapsed);

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <Box {...rest}>
      <Accordion
        disableGutters
        variant="outlined"
        expanded={expanded}
        onChange={handleChange}
        sx={{
          borderWidth: 0,
          '&.MuiPaper-root': {
            borderRadius: 2,
            px: 0,
          },
        }}
        {...accordionProps}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            '&.MuiAccordionSummary-root': {
              px: 1,
            },
          }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            {startIcon}
            <Typography variant="h4" {...typographyProps}>
              {title}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            '&.MuiAccordionDetails-root': {
              pt: 0,
              px: 1,
            },
          }}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
