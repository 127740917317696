import {Box} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {DateTime, Duration} from 'luxon';
import {useListHomesForConversationsQuery} from '../../generated/graphql';
import {CommunityFilter} from '../CommunityFilter';
import {SearchSelect, SearchSelectValue} from '@ark/web';
import {useUserProfile} from '../../contexts/UserProfile';
import {Bedtime, CheckCircle, Circle} from '@mui/icons-material';
import {colors} from '../../theme/colors';
import {useLogEvent} from '../../hooks/useLogEvent/useLogEvent';

const useStyles = makeStyles(theme => ({
  filtersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));

export type FilterName =
  | 'communities'
  | 'home'
  | 'date'
  | 'status'
  | 'tenantStatus'
  | 'assignedTo'
  | 'sortBy'
  | 'unanswered';

export type FilterValues = {
  home: SearchSelectValue;
  date: SearchSelectValue;
  status: SearchSelectValue;
  tenantStatus: SearchSelectValue;
  orderBy: SearchSelectValue;
  assignedTo: SearchSelectValue;
  unanswered: SearchSelectValue;
};

const sortOptions: {value: string; label: string}[] = [
  {value: 'last_event_time', label: 'Oldest'},
  {value: '-last_event_time', label: 'Newest'},
  {value: 'latest_updated_date', label: 'Waiting Longest'},
  {value: 'urgencies__urgency', label: 'Priority'},
];

const dateFilterOptions: {value: string; label: string}[] = [
  {value: DateTime.now().toISODate() as string, label: 'Today'},
  {
    value: DateTime.now()
      .minus(Duration.fromObject({days: 1}).toMillis())
      .toISODate() as string,
    label: 'Yesterday',
  },
  {
    value: DateTime.now()
      .minus(Duration.fromObject({days: 7}).toMillis())
      .toISODate() as string,
    label: 'Last 7 days',
  },
  {
    value: DateTime.now()
      .minus(Duration.fromObject({days: 30}).toMillis())
      .toISODate() as string,
    label: 'Last 30 days',
  },
  {
    value: DateTime.now()
      .minus(Duration.fromObject({days: 90}).toMillis())
      .toISODate() as string,
    label: 'Last 90 days',
  },
];

const assignedToFilterOptions = (userId: string) => {
  return [
    {value: 'All', label: 'All'},
    {value: userId, label: 'Me'},
    {value: 'Unassigned', label: 'Unassigned'},
  ];
};

const unansweredFilterOptions = [
  {value: 'unanswered', label: 'Unanswered'},
  {value: 'answered', label: 'Answered'},
  {value: 'all', label: 'All'},
];

export const openFilter = {
  value: 'OP',
  label: 'Open',
  icon: <Circle color="error" sx={{fontSize: 14, color: colors.treePoppy}} />,
};
export const tenantOpenFilter = {value: 'OP,SN', label: 'Open'};
const snoozeFilter = {
  value: 'SN',
  label: 'Snoozed',
  icon: (
    <Bedtime color="error" sx={{fontSize: 14, color: colors.fadedPurple}} />
  ),
};
const closedFilter = {
  value: 'CL',
  label: 'Closed',
  icon: (
    <CheckCircle color="error" sx={{fontSize: 14, color: colors['gray.600']}} />
  ),
};

const statusFilterOptions = [openFilter, snoozeFilter, closedFilter];
const tenantStatusFilterOptions = [tenantOpenFilter, closedFilter];

type Props = {
  enabledFilters: FilterName[];
  filterValues: FilterValues;
  setFilterValues: (filters: FilterValues) => void;
};

export const ConversationsFilters = ({
  enabledFilters,
  filterValues,
  setFilterValues,
}: Props) => {
  const styles = useStyles();
  const logEvent = useLogEvent();

  const setSortBy = (value: SearchSelectValue) => {
    setFilterValues({...filterValues, orderBy: value});
    logEvent({
      name: 'Conversation Filter Changed',
      data: {filterType: 'sortBy', newValue: value[0]?.value},
    });
  };
  const setDateFilter = (value: SearchSelectValue) => {
    setFilterValues({...filterValues, date: value});
    logEvent({
      name: 'Conversation Filter Changed',
      data: {filterType: 'date', newValue: value[0]?.value},
    });
  };
  const setStatusFilter = (value: SearchSelectValue) => {
    setFilterValues({...filterValues, status: value});
    logEvent({
      name: 'Conversation Filter Changed',
      data: {filterType: 'status', newValue: value[0]?.value},
    });
  };
  const setTenantStatusFilter = (value: SearchSelectValue) => {
    setFilterValues({...filterValues, tenantStatus: value});
    logEvent({
      name: 'Conversation Filter Changed',
      data: {filterType: 'tenantStatus', newValue: value[0]?.value},
    });
  };
  const setHomeFilter = (value: SearchSelectValue) => {
    setFilterValues({...filterValues, home: value});
    logEvent({
      name: 'Conversation Filter Changed',
      data: {filterType: 'home', newValue: value[0]?.value},
    });
  };
  const setAssignedToFilter = (value: SearchSelectValue) => {
    setFilterValues({...filterValues, assignedTo: value});
    logEvent({
      name: 'Conversation Filter Changed',
      data: {filterType: 'assignedTo', newValue: value[0]?.value},
    });
  };
  const setUnansweredFilter = (value: SearchSelectValue) => {
    setFilterValues({...filterValues, unanswered: value});
    logEvent({
      name: 'Conversation Filter Changed',
      data: {filterType: 'unanswered', newValue: value[0]?.value},
    });
  };
  const {id} = useUserProfile();

  const {data} = useListHomesForConversationsQuery({
    skip: !enabledFilters.includes('home'),
  });

  const homesOptions =
    data?.listHomes?.map((home: {id: string; name: string}) => ({
      value: home.id,
      label: home.name,
    })) || [];

  const autoCompleteWidth = enabledFilters.length > 3 ? 250 : 175;

  return (
    <Box className={styles.filtersContainer}>
      {enabledFilters.includes('communities') && (
        <Box sx={{flexGrow: 1}}>
          <CommunityFilter fullWidth />
        </Box>
      )}
      {enabledFilters.includes('home') && (
        <Box sx={{flexGrow: 1}}>
          <SearchSelect
            filterSelectedOptions
            options={homesOptions}
            value={filterValues.home}
            setValue={setHomeFilter}
            label="Home"
            fullWidth
            minWidth={autoCompleteWidth}
          />
        </Box>
      )}
      {enabledFilters.includes('date') && (
        <Box sx={{flexGrow: 1}}>
          <SearchSelect
            filterSelectedOptions
            options={dateFilterOptions}
            value={filterValues.date}
            setValue={setDateFilter}
            label="Date"
            fullWidth
            minWidth={autoCompleteWidth}
          />
        </Box>
      )}
      {enabledFilters.includes('unanswered') && (
        <Box sx={{flexGrow: 1}}>
          <SearchSelect
            options={unansweredFilterOptions}
            value={filterValues.unanswered}
            setValue={setUnansweredFilter}
            label="Unanswered"
            fullWidth
            minWidth={autoCompleteWidth}
          />
        </Box>
      )}
      {enabledFilters.includes('status') && (
        <Box sx={{flexGrow: 1}}>
          <SearchSelect
            filterSelectedOptions
            options={statusFilterOptions}
            value={filterValues.status}
            setValue={setStatusFilter}
            label="Status"
            multiple
            fullWidth
            minWidth={autoCompleteWidth}
          />
        </Box>
      )}
      {enabledFilters.includes('tenantStatus') && (
        <Box sx={{flexGrow: 1}}>
          <SearchSelect
            filterSelectedOptions
            options={tenantStatusFilterOptions}
            value={filterValues.tenantStatus}
            setValue={setTenantStatusFilter}
            label="Status"
            multiple
            fullWidth
            minWidth={autoCompleteWidth}
          />
        </Box>
      )}
      {enabledFilters.includes('assignedTo') && id && (
        <Box sx={{flexGrow: 1}}>
          <SearchSelect
            options={assignedToFilterOptions(id)}
            value={filterValues.assignedTo}
            setValue={setAssignedToFilter}
            label="Assigned to"
            fullWidth
            minWidth={autoCompleteWidth}
          />
        </Box>
      )}
      <Box sx={{flexGrow: 1}}>
        <SearchSelect
          options={sortOptions}
          value={filterValues.orderBy}
          setValue={setSortBy}
          label="Sort by"
          fullWidth
          minWidth={autoCompleteWidth}
        />
      </Box>
    </Box>
  );
};
