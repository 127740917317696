import {track} from '@amplitude/analytics-browser';
import {useCallback} from 'react';
import {AccountEvents} from './eventTypes/AccountEvents';
import {AmenitiesEvents} from './eventTypes/AmenitiesEvents';
import {CommunityUpdateEvents} from './eventTypes/CommunityUpdateEvents';
import {ConversationEvents} from './eventTypes/ConversationEvents';
import {DocumentPaywallEvents} from './eventTypes/DocumentPaywallEvents';
import {HomePageEvents} from './eventTypes/HomePageEvents';
import {NavigationEvents} from './eventTypes/NavigationEvents';
import {PaymentsEvents} from './eventTypes/PaymentsEvents';
import {PropertyEvents} from './eventTypes/PropertyEvents';
import {ReferencingEvents} from './eventTypes/ReferencingEvents';
import {SwitchFlowEvents} from './eventTypes/SwitchFlowEvents';
import {TenancyEvents} from './eventTypes/TenancyEvents';
import {TenantEvents} from './eventTypes/TenantEvents';
import {AppStoreText} from '@/routes/AppStoreRedirect';
import {KeysEvents} from './eventTypes/KeysEvents';
import {ApplicationsEvents} from './eventTypes/ApplicationsEvents';
import {PackageEvents} from './eventTypes/PackagesEvents';
import {DocumentsEvents} from './eventTypes/DocumentsEvents';
import {MarketplaceEvents} from './eventTypes/MarketplaceEvents';
import {ConversationNotesEvents} from './eventTypes/ConversationNotesEvents';

// Generic event types
export type BaseEvent<EventName, EventData = undefined> = {
  name: EventName;
  data: EventData;
};
export type SimpleEvent<EventName> = {
  name: EventName;
};

type AppRedirectEvent = BaseEvent<
  'App Redirect',
  {
    target: string;
    protocol: string;
    pathname: string;
    email: string;
  }
>;

type AppStoreRedirectEvent = BaseEvent<
  'App Store Redirect',
  {
    store: AppStoreText;
  }
>;

export type AmplitudeEvent =
  | AccountEvents
  | PropertyEvents
  | TenancyEvents
  | ConversationEvents
  | SwitchFlowEvents
  | PaymentsEvents
  | NavigationEvents
  | TenantEvents
  | ReferencingEvents
  | DocumentPaywallEvents
  | HomePageEvents
  | CommunityUpdateEvents
  | AmenitiesEvents
  | AppRedirectEvent
  | AppStoreRedirectEvent
  | KeysEvents
  | ApplicationsEvents
  | PackageEvents
  | DocumentsEvents
  | MarketplaceEvents
  | ConversationNotesEvents;

export const useLogEvent = () => {
  const logEvent = useCallback((event: AmplitudeEvent) => {
    const {name, data} = event as BaseEvent<string, unknown>;
    if (data) {
      track(name, data);
    } else {
      track(name);
    }
  }, []);
  return logEvent;
};
