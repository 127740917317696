//@ts-ignore
import React from 'react';
//@ts-ignore
import {TextField, TextFieldProps} from '@mui/material';
//@ts-ignore
import {Controller, FieldValues} from 'react-hook-form';
//@ts-ignore
import {UseControllerProps} from 'react-hook-form';

type FormTextFieldProps<T extends FieldValues = FieldValues> =
  TextFieldProps & {
    control: UseControllerProps<T>['control'];
    name: UseControllerProps<T>['name'];
    rules?: UseControllerProps<T>['rules'];
  };

export function FormTextField<T extends FieldValues = FieldValues>(
  props: FormTextFieldProps<T>,
) {
  const {control, rules, name, ...rest} = props;
  return (
    //@ts-ignore
    <Controller
      //@ts-ignore
      render={({field, fieldState: {error}}) => {
        return (
          <TextField
            error={!!error}
            helperText={error?.message}
            variant="outlined"
            fullWidth
            {...field}
            {...rest}
          />
        );
      }}
      rules={rules}
      control={control}
      name={name}
      shouldUnregister
    />
  );
}
