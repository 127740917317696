import React from 'react';
import {TextField, TextFieldProps} from '@mui/material';
import {Controller, FieldValues, FieldPath} from 'react-hook-form';
import {UseControllerProps} from 'react-hook-form';

type FormTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TextFieldProps & {
  control: UseControllerProps<TFieldValues, TName>['control'];
  name: UseControllerProps<TFieldValues, TName>['name'];
  rules?: UseControllerProps<TFieldValues, TName>['rules'];
};

export default function FormTextField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: FormTextFieldProps<TFieldValues, TName>) {
  const {control, rules, name, ...rest} = props;
  return (
    <Controller
      render={({field, fieldState: {error}}) => {
        return (
          <TextField
            error={!!error}
            helperText={error?.message}
            variant="outlined"
            fullWidth
            data-testid={`${name}-text-field`}
            {...field}
            {...rest}
          />
        );
      }}
      rules={rules}
      control={control}
      name={name}
    />
  );
}
