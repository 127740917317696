import React from 'react';
import {useLogManagerDashUseMutation} from '@/generated/graphql';

export const useLogAppUse = () => {
  const [logUse] = useLogManagerDashUseMutation();

  const checkAndLog = React.useCallback(async () => {
    const login = window.sessionStorage.getItem('login');
    if (!login) {
      window.sessionStorage.setItem('login', 'true');
      await logUse();
    }
  }, [logUse]);
  React.useEffect(() => {
    checkAndLog();
  }, [checkAndLog]);
};
