import {
  Dialog,
  DialogProps,
  Slide,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {TransitionProps} from '@mui/material/transitions';
import React from 'react';
import {useIntercom} from 'react-use-intercom';

interface SideDialogProps extends DialogProps {
  width?: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: JSX.Element;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const SideDialog: React.FC<SideDialogProps> = ({open, ...props}) => {
  const {update} = useIntercom();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  React.useEffect(() => {
    update({hideDefaultLauncher: open});
  }, [update, open, fullScreen]);

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      TransitionComponent={Transition}
      open={open}
      {...props}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
          padding: !fullScreen ? 2 : undefined,
        },
        '& .MuiDialog-paper': {
          margin: 0,
          backgroundColor: theme.palette.background.default,
          height: '100%',
          maxHeight: 'initial',
          borderRadius: !fullScreen ? 2 : undefined,
          maxWidth: props.width || '60%',
          [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
          },
        },
        '& .MuiDialogTitle-root': {
          marginBottom: '16px',
        },
      }}
    />
  );
};

export default SideDialog;
