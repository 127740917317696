import React from 'react';
import Confetti from 'react-dom-confetti';
import confettiContext from '@/contexts/Confetti';
import {makeStyles} from '@mui/styles';
import {Box} from '@mui/material';

const confettiConfig = {
  angle: 90,
  spread: 75,
  startVelocity: 35,
  elementCount: 130,
  dragFriction: 0.1,
  duration: 2070,
  stagger: 1,
  width: '7px',
  height: '5px',
  perspective: '1000px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const useStyles = makeStyles({
  confettiContainer: {
    position: 'fixed',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '1fr 1fr 1fr',
    width: '100%',
    height: '100%',
    zIndex: 100000,
    display: 'none',
    bottom: 0,
  },
  displayGrid: {
    display: 'grid',
  },
  bottom: {
    gridRow: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const ConfettiContainer = () => {
  const {confettiItem, setConfettiItem} = React.useContext(confettiContext);
  const styles = useStyles();

  return (
    <Box
      className={`${styles.confettiContainer} ${
        confettiItem ? styles.displayGrid : ''
      }`}
      onClick={() => setConfettiItem(null)}
    >
      {Array.from(Array(3).keys()).map(int => (
        <Box
          key={'confetti-' + int}
          className={styles.bottom}
          gridColumn={int + 1}
        >
          <Confetti
            active={
              Boolean(confettiItem) &&
              Boolean(confettiItem?.numPops === 3 || int === 1)
            }
            config={confettiConfig}
          />
        </Box>
      ))}
    </Box>
  );
};

export default ConfettiContainer;
