import downloadAppImage from '@/assets/download-app.svg';
import {useIsMobile} from '@/hooks/useIsMobile';
import {useLogEvent} from '@/hooks/useLogEvent/useLogEvent';
import {useSearchParams} from '@/hooks/useSearchParams';
import {StepContent} from '@/pages/BTL/Referencing/StepContent';
import BrickThemeProvider from '@/themes/BrickThemeProvider';
import {Box, Typography} from '@mui/material';
import {LogoGreyCircle, ReferencingLayout} from 'common';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

export const AppRedirect = () => {
  const [showCloseButton, setShowCloseButton] = useState(false);
  const navigate = useNavigate();
  const {searchParams} = useSearchParams();
  const logEvent = useLogEvent();

  const target = searchParams.target;
  const isMobile = useIsMobile();
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (target) {
      timeout = setTimeout(() => {
        setShowCloseButton(true);
      }, 10000);
      // leaving the log here as it's useful for debugging
      console.log('redirecting to', target);
      window.location.assign(target);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [target, navigate]);

  useEffect(() => {
    if (target) {
      const targetUrl = new URL(target);
      logEvent({
        name: 'App Redirect',
        data: {
          target,
          protocol: targetUrl.protocol,
          pathname: targetUrl.pathname,
          email: targetUrl.searchParams.get('email') ?? '',
        },
      });
    }
  }, [target, logEvent]);

  let content = null;
  if (!isMobile) {
    content = (
      <Typography variant="h4" textAlign="center" sx={{marginTop: 3}}>
        Please open this link on your mobile device.
      </Typography>
    );
  } else if (showCloseButton) {
    content = (
      <Typography variant="h4" textAlign="center" sx={{marginTop: 3}}>
        You can close this window.
      </Typography>
    );
  } else {
    content = (
      <Typography variant="h4" textAlign="center" sx={{marginTop: 3}}>
        You are being redirected to the Ark App.
      </Typography>
    );
  }

  return (
    <BrickThemeProvider>
      <ReferencingLayout>
        <>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              marginTop: 2,
            }}
          >
            <LogoGreyCircle />
          </Box>
          <StepContent sx={{mx: 0, mb: 0}} overflow="hidden">
            {content}
            <img src={downloadAppImage} alt="download app" />
          </StepContent>
        </>
      </ReferencingLayout>
    </BrickThemeProvider>
  );
};
