import MuiAlert, {AlertColor} from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Snackbar, {SnackbarProps} from '@mui/material/Snackbar';
import React from 'react';

export interface SnackbarComponentProps {
  open: boolean;
  setClosed: () => void;
  message: string | null;
  severity?: AlertColor;
}

export interface SnackbarItem extends SnackbarProps {
  message: string;
  severity: AlertColor;
}

const SnackbarComponent: React.FC<SnackbarComponentProps> = props => {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={4000}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      onClose={props.setClosed}
      TransitionComponent={Slide}
    >
      <MuiAlert
        onClose={props.setClosed}
        variant="outlined"
        elevation={3}
        sx={({palette}) => ({backgroundColor: palette.background.default})}
        {...props}
      >
        {props.message}
      </MuiAlert>
    </Snackbar>
  );
};

export default SnackbarComponent;
