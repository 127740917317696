import {ThemeProvider, useTheme} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import {cloneDeep} from 'lodash';
import {
  arkButtonVariant,
  arkOutlinedButtonVariant,
  buttonVariants,
} from './overrides/Button';

type Props = {
  children: React.ReactNode;
};

export const BrickThemeProvider = ({children}: Props) => {
  const theme = useTheme();

  // Use the ark button variant as the contained button variant
  const containedButtonVariant = cloneDeep(arkButtonVariant(theme));
  containedButtonVariant.props.variant = 'contained';

  const outlinedButtonVariant = cloneDeep(arkOutlinedButtonVariant(theme));
  outlinedButtonVariant.props.variant = 'outlined';

  const brickTheme = createTheme(theme, {
    components: {
      MuiButton: {
        variants: [
          ...buttonVariants(theme),
          containedButtonVariant,
          outlinedButtonVariant,
        ],
      },
    },
  });
  return <ThemeProvider theme={brickTheme}>{children}</ThemeProvider>;
};

export default BrickThemeProvider;
