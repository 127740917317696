import React from 'react';

export interface HeadsUpModal {
  title: string;
  description?: string;
  open: boolean;
  prevURL?: string;
  success?: boolean;
  confetti?: boolean;
  successAction: () => void;
}

interface ConfirmContext {
  headsUpModal: null | HeadsUpModal;
  setHeadsUpModal: (modal: HeadsUpModal | null) => void;
  closeModal: () => void;
}

const headsUpContext = React.createContext<ConfirmContext>({
  headsUpModal: null,
  setHeadsUpModal: () => undefined,
  closeModal: () => undefined,
});

export const useHeadsUpModal = ({
  title,
  description,
  prevURL,
  success,
  confetti,
}: {
  title: string;
  description?: string;
  prevURL?: string;
  success?: boolean;
  confetti?: boolean;
}) => {
  const {setHeadsUpModal} = React.useContext(headsUpContext);

  return (handleConfirm: () => void) => () => {
    setHeadsUpModal({
      title,
      prevURL,
      description,
      success,
      confetti,
      open: true,
      successAction: handleConfirm,
    });
  };
};
type HeadsUpProviderProps = {
  children: React.ReactNode;
};

export const HeadsUpProvider = ({children}: HeadsUpProviderProps) => {
  const [headsUpModal, setHeadsUpModal] = React.useState<null | HeadsUpModal>(
    null,
  );

  const closeModal = () => setHeadsUpModal(null);

  return (
    <headsUpContext.Provider
      value={{
        headsUpModal,
        setHeadsUpModal,
        closeModal,
      }}
    >
      {children}
    </headsUpContext.Provider>
  );
};

export default headsUpContext;
