type Node<T> = T;

type Edge<T> =
  | {
      node?: Node<T> | null | undefined;
    }
  | null
  | undefined;

export const edgesToNodes = <T>(edges?: readonly Edge<T>[]): Node<T>[] => {
  return (
    edges?.map(edge => {
      const node = edge?.node;
      if (node == null) {
        throw new Error('edge.node is null');
      }
      return node;
    }) ?? []
  );
};
