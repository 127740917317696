import {Button, DialogActions, DialogContent, Typography} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SuccessDialog = ({onClose}: {onClose: () => void}) => {
  return (
    <>
      <DialogContent>
        <Typography variant="h3" textAlign="center" mb={2}>
          <CheckCircleIcon
            fontSize="small"
            color="success"
            sx={{verticalAlign: 'sub', mr: 1}}
          />
          MFA Set Up Complete
        </Typography>
        <Typography variant="body1" textAlign="center">
          You will be asked to provide a 6 digit code the next time you log in.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="ark" fullWidth color="primary" onClick={onClose}>
          Back to Account Page
        </Button>
      </DialogActions>
    </>
  );
};

export default SuccessDialog;
