import React from 'react';
import {ApolloClient, ApolloProvider, InMemoryCache} from '@apollo/client';
import {relayStylePagination} from '@apollo/client/utilities';
import {useAuthContext} from '@ark/react';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        residencies: relayStylePagination([]),
      },
    },
  },
});

export const AuthorizedApolloProvider: React.FC<{
  children: React.ReactNode;
}> = ({children}) => {
  const {authToken} = useAuthContext();
  const client = React.useMemo(
    () =>
      new ApolloClient({
        uri: `${import.meta.env.VITE_API_ROOT}/api/graphql`,
        headers: {
          Authorization: `Token ${authToken}`,
        },
        cache,
        defaultOptions: {
          mutate: {
            awaitRefetchQueries: true,
          },
        },
      }),
    [authToken],
  );
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
