import {useSendManagerAmplitudeTestMutation} from '@/generated/graphql';
import {useLogAppUse} from '@/hooks/useLogAppUse';
import {useStartReplay} from '@/hooks/useStartReplay';
import {useTrackPageView} from '@/hooks/useTrackPageView';
import {SentryRoutes} from '@/main';
import {track} from '@amplitude/analytics-browser';
import {userProfileContext} from 'common';
import React, {useEffect} from 'react';
import lazyWithPreload from 'react-lazy-with-preload';
import {Navigate, Route} from 'react-router-dom';
import {ParamsToUUIDNavigator} from '../ParamsToUUIDNavigator';

const SigningLanding = lazyWithPreload(
  () => import('../../pages/BTL/Signing/LandlordSigning/LandlordSigning'),
);
const SaveASTPage = lazyWithPreload(
  () => import('../../pages/BTL/GeneratedAgreement/SaveASTPage/SaveASTPage'),
);
const Agreement = lazyWithPreload(
  () => import('../../pages/BTL/GeneratedAgreement/AssociateAgreement'),
);
const SideBar = lazyWithPreload(() => import('../../layout/SideBar'));
const PropertiesPage = lazyWithPreload(() =>
  import('../../pages/BTL/Properties/PropertiesPage').then(module => ({
    default: module.PropertiesPage,
  })),
);
const Section21Details = lazyWithPreload(
  () => import('../../pages/BTL/Documents/Section21/Details'),
);
const Documents = lazyWithPreload(
  () => import('../../pages/BTL/Documents/Documents'),
);
const Payments = lazyWithPreload(
  () => import('../../pages/BTL/Payments/Payments'),
);
const StripeRefresh = lazyWithPreload(
  () => import('../../pages/BTL/Payments/StripeRefresh'),
);

const News = lazyWithPreload(() => import('../../pages/BTL/News/NewsPage'));
const Profile = lazyWithPreload(
  () => import('../../pages/BTL/LandlordProfile/LandlordProfile'),
);
const PropertyDetails = lazyWithPreload(() =>
  import('../../pages/BTL/Properties/PropertyDetails/PropertyDetails').then(
    module => ({default: module.PropertyDetails}),
  ),
);
const Compliance = lazyWithPreload(
  () => import('../../pages/BTL/Compliance/Compliance'),
);
const Conversations = lazyWithPreload(
  () => import('../../pages/BTL/Conversations/Conversations'),
);
// const Tenants = lazyWithPreload(
//   () => import('../../pages/BTL/Tenants/Tenants'),
// );
const ApplicantDetails = lazyWithPreload(() =>
  import('../../components/Referencing/ApplicantDetails/ApplicantDetails').then(
    module => ({
      default: module.ApplicantDetails,
    }),
  ),
);
const Layout = lazyWithPreload(() => import('../../layout'));

const Home = lazyWithPreload(() =>
  import('../../pages/BTL/Home/Home').then(module => ({default: module.Home})),
);

const TenancyApplications = lazyWithPreload(() =>
  import('../../pages/BTL/TenancyApplications').then(module => ({
    default: module.TenancyApplications,
  })),
);

const componentsToPreload = [
  SigningLanding,
  SaveASTPage,
  Agreement,
  SideBar,
  PropertiesPage,
  Section21Details,
  Documents,
  Payments,
  StripeRefresh,
  News,
  Profile,
  PropertyDetails,
  Compliance,
  Conversations,
];

const LandlordRoot: React.FC = () => {
  useStartReplay();
  useTrackPageView();
  const [sendBackendEvent] = useSendManagerAmplitudeTestMutation();
  const {email} = React.useContext(userProfileContext);

  useEffect(() => {
    if (email) {
      track('Adblock Test MD Browser');
      sendBackendEvent({variables: {input: {}}});
      componentsToPreload.forEach(component => component.preload());
    }
  }, [sendBackendEvent, email]);

  useLogAppUse();

  return (
    <SentryRoutes>
      <Route element={<Layout />}>
        <Route path="/home" element={<Home />} />
        <Route
          path="/properties/:homeId/tenancy/:tenancyId/:action"
          element={
            <ParamsToUUIDNavigator parameterNames={['homeId', 'tenancyId']}>
              <PropertyDetails />
            </ParamsToUUIDNavigator>
          }
        />
        <Route
          path="/properties/:homeId/tenancy/:tenancyId"
          element={
            <ParamsToUUIDNavigator parameterNames={['homeId', 'tenancyId']}>
              <PropertyDetails />
            </ParamsToUUIDNavigator>
          }
        />
        <Route
          path="/properties/:homeId"
          element={
            <ParamsToUUIDNavigator parameterNames={['homeId']}>
              <PropertyDetails />
            </ParamsToUUIDNavigator>
          }
        />
        <Route path="/properties" element={<PropertiesPage />} />
        <Route
          path="/news/:itemId"
          element={
            <ParamsToUUIDNavigator parameterNames={['itemId']}>
              <News />
            </ParamsToUUIDNavigator>
          }
        />
        <Route path="/news" element={<News />} />
        <Route
          path="/documents/tenancy-agreements/:id/:tab"
          element={
            <ParamsToUUIDNavigator parameterNames={['id']}>
              <SaveASTPage />
            </ParamsToUUIDNavigator>
          }
        />
        <Route
          path="/documents/tenancy-agreements/:id"
          element={
            <ParamsToUUIDNavigator parameterNames={['id']}>
              <SaveASTPage />
            </ParamsToUUIDNavigator>
          }
        />
        <Route
          path="/documents/section-21/:id"
          element={
            <ParamsToUUIDNavigator parameterNames={['id']}>
              <Section21Details />
            </ParamsToUUIDNavigator>
          }
        />
        <Route path="/documents" element={<Documents />} />
        <Route
          path="/landlord-sign/:agreementUUID/:signatureUUID"
          element={
            <ParamsToUUIDNavigator
              parameterNames={['agreementUUID', 'signatureUUID']}
            >
              <SigningLanding />
            </ParamsToUUIDNavigator>
          }
        />
        <Route
          path="/agreements/:id"
          element={
            <ParamsToUUIDNavigator parameterNames={['id']}>
              <Agreement />
            </ParamsToUUIDNavigator>
          }
        />
        <Route path="/payments/stripe-refresh" element={<StripeRefresh />} />
        <Route
          path="/payments/tenancy/:residencyId"
          element={
            <ParamsToUUIDNavigator parameterNames={['residencyId']}>
              <Payments />
            </ParamsToUUIDNavigator>
          }
        />
        <Route
          path="/payments/:subscriptionId"
          element={
            <ParamsToUUIDNavigator parameterNames={['subscriptionId']}>
              <Payments />
            </ParamsToUUIDNavigator>
          }
        />
        <Route path="/payments" element={<Payments />} />
        <Route
          path="/messages/:conversationId"
          element={
            <ParamsToUUIDNavigator parameterNames={['conversationId']}>
              <Conversations />
            </ParamsToUUIDNavigator>
          }
        />
        <Route path="/messages" element={<Conversations />} />
        <Route path="/profile" element={<Profile />} />
        <Route
          path="/compliance/:complianceItemId/add"
          element={
            <ParamsToUUIDNavigator parameterNames={['complianceItemId']}>
              <Compliance sideBarMode="add" />
            </ParamsToUUIDNavigator>
          }
        />

        <Route
          path="/compliance/:complianceItemId"
          element={
            <ParamsToUUIDNavigator parameterNames={['complianceItemId']}>
              <Compliance />
            </ParamsToUUIDNavigator>
          }
        />
        <Route path="/compliance" element={<Compliance />} />
        <Route
          path="/tenant-reference-report/:tenantReferenceUUID"
          element={
            <ParamsToUUIDNavigator parameterNames={['tenantReferenceUUID']}>
              <ApplicantDetails />
            </ParamsToUUIDNavigator>
          }
        />
        {/* removing until we figure out what we are doing with the tenants list */}
        {/* <Route path="/tenants" element={<Tenants />} /> */}
        <Route path="/tenancy-applications" element={<TenancyApplications />} />
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Route>
    </SentryRoutes>
  );
};

export default LandlordRoot;
