import {SentryRoutes} from '@/main';
import React, {useEffect} from 'react';
import lazyWithPreload from 'react-lazy-with-preload';
import {Route} from 'react-router-dom';

const MembersList = lazyWithPreload(() => import('../../pages/Admin/Members'));
const Profile = lazyWithPreload(() => import('../../pages/Admin/ProfilePage'));
const Settings = lazyWithPreload(() => import('../../pages/Admin/Settings'));
const PageNotFound = lazyWithPreload(() => import('../../pages/ENT/404Page'));
const Layout = lazyWithPreload(() => import('../../layout'));

const componentsToPreload = [
  MembersList,
  Profile,
  Settings,
  PageNotFound,
  Layout,
];

const AdminRoutes: React.FC = () => {
  useEffect(() => {
    componentsToPreload.forEach(component => component.preload());
  }, []);

  return (
    <SentryRoutes>
      <Route element={<Layout />}>
        <Route path="/" element={<MembersList />} />
        <Route path="/members/:id?" element={<MembersList />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings/:id?" element={<Settings />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </SentryRoutes>
  );
};

export default AdminRoutes;
