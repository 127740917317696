import React from 'react';
import {makeStyles} from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import {colors} from '../theme/colors';
import {Tooltip} from '@mui/material';

export interface DialogButtonProps {
  onClick: () => void;
  positionLeft?: boolean;
  icon?: React.ReactElement;
  tooltip?: string;
}

const useStyles = makeStyles({
  button: {
    position: 'absolute',
    top: 16,
    borderRadius: 8,
    padding: 3,
    zIndex: 1,
    backgroundColor: colors.white,
  },
  rightButton: {
    right: 52,
  },
  leftButton: {
    left: 16,
  },
});

export const DialogButton = (props: DialogButtonProps) => {
  const classes = useStyles();

  return (
    <Tooltip title={props.tooltip}>
      <IconButton
        aria-label="button"
        className={`${classes.button} ${
          props.positionLeft ? classes.leftButton : classes.rightButton
        }`}
        onClick={props.onClick}
        size="large"
      >
        {props.icon}
      </IconButton>
    </Tooltip>
  );
};
