import {useReferencingEnabledQuery} from '@/generated/graphql';

export const useReferencingEnabled = () => {
  const {data} = useReferencingEnabledQuery();

  if (
    data?.getUser?.manager?.community?.totalCount &&
    data?.getUser?.manager?.community?.totalCount > 0
  )
    return true;
  return false;
};
