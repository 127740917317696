import React from 'react';
import {ConversationListItemData} from './Conversations';
import {ConversationListItemProps} from './ConversationListItem';
import {EmptyList} from '../EmptyList';
import {useOnScrolledToBottom} from '../../hooks/useOnScrolledToBottom';
import {ListSkeleton} from '../ListSkeleton';

type ConversationsListProps = {
  isFiltered: boolean;
  data: ConversationListItemData[];
  ListItemComponent: React.FC<ConversationListItemProps>;
  onScrolledToBottom: () => void;
  loading: boolean;
  showStatusBorder: boolean;
  loadMoreButton?: React.ReactNode;
};

export const ConversationsList = ({
  isFiltered,
  data,
  ListItemComponent,
  onScrolledToBottom,
  loading,
  showStatusBorder,
  loadMoreButton,
}: ConversationsListProps) => {
  useOnScrolledToBottom(onScrolledToBottom);
  if (data.length === 0 && loading) {
    return <ListSkeleton height={80} sx={{mb: 1, borderRadius: 1.5}} />;
  }
  if (data.length === 0) {
    if (isFiltered) {
      return (
        <EmptyList text="🔍  No conversations found, try updating the filters" />
      );
    }
    return (
      <EmptyList text="You don't have any messages yet. ✉️ Get started with the button on the top right!" />
    );
  }

  return (
    <>
      {data.map(item => (
        <ListItemComponent
          key={item.id}
          data={item}
          showStatusBorder={showStatusBorder}
        />
      ))}
      {loading && <ListSkeleton height={80} sx={{mb: 1, borderRadius: 1.5}} />}
      {loadMoreButton}
    </>
  );
};
