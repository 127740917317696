import {useOnScrolledToBottom} from 'common';

interface ConnectionData {
  pageInfo?: {
    hasNextPage?: boolean | null;
    endCursor?: string | null;
  };
}

const useLoadMoreOnScroll = (
  connectionData: ConnectionData | null | undefined,
  fetchMore: ({variables}: {variables: {after?: string | null}}) => void,
  target?: HTMLElement | null,
) => {
  const hasNextPage = connectionData?.pageInfo?.hasNextPage;
  const endCursor = connectionData?.pageInfo?.endCursor;

  const onBottom = () => {
    if (hasNextPage) {
      fetchMore({
        variables: {
          after: endCursor,
        },
      });
    }
  };

  useOnScrolledToBottom(onBottom, target);
};

export default useLoadMoreOnScroll;
