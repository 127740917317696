import downloadAppImage from '@/assets/download-app.svg';
import {useIsMobile} from '@/hooks/useIsMobile';
import {useLogEvent} from '@/hooks/useLogEvent/useLogEvent';
import {LogoGreyCircle, ReferencingLayout} from 'common';
import {StepContent} from '@/pages/BTL/Referencing/StepContent';
import BrickThemeProvider from '@/themes/BrickThemeProvider';
import {Box, Typography} from '@mui/material';
import {useEffect, useState} from 'react';

export type AppStoreText = 'Play Store' | 'App Store' | undefined;

const playStoreUrl =
  'https://play.google.com/store/apps/details?id=com.projectark.app&hl=en_GB&gl=US';
const appStoreUrl = 'https://apps.apple.com/gb/app/ark-residents/id1514785878';

export const AppStoreRedirect = () => {
  const [showCloseText, setShowCloseText] = useState(false);
  const userAgent = navigator.userAgent;
  const logEvent = useLogEvent();
  const isMobile = useIsMobile();

  let redirectUrl: string | undefined = undefined;
  let appStoreText: AppStoreText = undefined;

  if (/android/i.test(userAgent)) {
    appStoreText = 'Play Store';
    redirectUrl = playStoreUrl;
  } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
    appStoreText = 'App Store';
    redirectUrl = appStoreUrl;
  }

  const redirect = window.open('', '_self');

  useEffect(() => {
    if (redirectUrl && redirect) {
      redirect.location.href = redirectUrl;
      setShowCloseText(true);
      logEvent({
        name: 'App Store Redirect',
        data: {
          store: appStoreText,
        },
      });
    }
  }, [redirectUrl, redirect, logEvent, appStoreText]);

  let content = null;
  if (!isMobile) {
    content = (
      <Typography variant="h4" textAlign="center" sx={{marginTop: 3}}>
        Please open this link on your mobile device.
      </Typography>
    );
  } else if (showCloseText) {
    content = (
      <Typography variant="h4" textAlign="center" sx={{marginTop: 3}}>
        You can close this window.
      </Typography>
    );
  } else {
    content = (
      <Typography variant="h4" textAlign="center" sx={{marginTop: 3}}>
        You are being redirected to the {appStoreText}.
      </Typography>
    );
  }

  return (
    <BrickThemeProvider>
      <ReferencingLayout>
        <>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              marginTop: 2,
            }}
          >
            <LogoGreyCircle />
          </Box>
          <StepContent sx={{mx: 0, mb: 0}} overflow="hidden">
            {content}
            <img src={downloadAppImage} alt="download app" />
          </StepContent>
        </>
      </ReferencingLayout>
    </BrickThemeProvider>
  );
};
