import {useGetLandlordQuery, useGetUserTypeQuery} from '@/generated/graphql';
import {useClientType} from '@/hooks/useClientType/useClientType';
import {ConciergeSideBarContent} from '@/routes/Concierge/ConciergeSideBarContent';
import {LandlordSideBarContent} from '@/routes/Landlord/LandlordSideBarContent';
import {ManagerSideBarContent} from '@/routes/Manager/ManagerSideBarContent';
import {MenuRounded as MenuIcon} from '@mui/icons-material';
import {Avatar, Box, IconButton, Menu, SwipeableDrawer} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {stringToColor, userProfileContext} from 'common';
import React from 'react';
import ProfileMenuContent from './ProfileMenuContent';
import {useIsMobile} from '@/hooks/useIsMobile';

const useStyles = makeStyles(theme => ({
  avatarIcon: {
    width: 30,
    height: 30,
    border: `1px solid ${theme.palette.background.default}`,
    '&:hover': {
      cursor: 'pointer',
      border: `1px solid ${theme.palette.common.slate}`,
    },
    color: theme.palette.common.white,
  },
}));

const ProfileMenu: React.FC = () => {
  const isMobile = useIsMobile();
  const styles = useStyles();
  const {firstName, email} = React.useContext(userProfileContext);
  const {data} = useGetLandlordQuery();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );
  const {data: userType} = useGetUserTypeQuery();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    isMobile
      ? setOpenDrawer(!openDrawer)
      : setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    isMobile ? setOpenDrawer(!openDrawer) : setAnchorElUser(null);
  };

  const clientType = useClientType();

  const isLandlord = clientType === 'BTL';
  const isEnterprise = clientType === 'ENT';

  const showAvatar = (isLandlord && !isMobile) || isEnterprise;

  const LandlordMobileMenuIcon = () => (
    <IconButton onClick={handleOpenUserMenu}>
      <MenuIcon
        fontSize="large"
        sx={{
          color: 'grey.700',
        }}
      />
    </IconButton>
  );

  return (
    <Box>
      <Box>
        {isLandlord && isMobile && <LandlordMobileMenuIcon />}
        {showAvatar && (
          <Avatar
            className={styles.avatarIcon}
            sx={{
              backgroundColor: stringToColor(email ? email : ''),
            }}
            onClick={handleOpenUserMenu}
            alt={
              firstName ? firstName.toUpperCase() : email?.toUpperCase() || ''
            }
            src={
              data?.getLandlord?.avatar
                ? `${import.meta.env.VITE_IMAGE_PREFIX || ''}${data?.getLandlord
                    ?.avatar}`
                : 'src'
            }
          />
        )}
        <Menu
          sx={{mt: '36px'}}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <ProfileMenuContent onSelect={handleCloseUserMenu} />
        </Menu>
      </Box>
      {isMobile && (
        <SwipeableDrawer
          onOpen={() => setOpenDrawer(true)}
          onClose={() => setOpenDrawer(false)}
          open={openDrawer}
          anchor="right"
        >
          <Box position="relative" padding="60px 32px 0">
            <>
              {isLandlord && (
                <LandlordSideBarContent onClose={handleCloseUserMenu} />
              )}
              {isEnterprise && !!userType?.getUser?.manager && (
                <ManagerSideBarContent onClose={handleCloseUserMenu} />
              )}
              {isEnterprise &&
                !userType?.getUser?.manager &&
                !!userType?.getUser?.concierge && (
                  <ConciergeSideBarContent onClose={handleCloseUserMenu} />
                )}
              <ProfileMenuContent onSelect={handleCloseUserMenu} />
            </>
          </Box>
        </SwipeableDrawer>
      )}
    </Box>
  );
};

export default ProfileMenu;
