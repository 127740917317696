import {useSetUserTrackingEmail} from '../hooks/useSetUserTrackingEmail';
import React from 'react';

interface UserContext {
  authToken: null | string;
  setAuthToken: (token: string | null) => void;
  isLoggedIn: boolean;
  signOut: () => void;
}

const AUTH_STORAGE_KEY = '__A__';

export const userContext = React.createContext<UserContext>({
  authToken: null,
  setAuthToken: () => {},
  isLoggedIn: false,
  signOut: () => {},
});

type Props = {
  children: React.ReactNode;
};
export const UserProvider = ({children}: Props) => {
  const {setUserTrackingEmail} = useSetUserTrackingEmail();
  const currentAuth = localStorage.getItem(AUTH_STORAGE_KEY);
  const [authToken, setAuthToken] = React.useState(currentAuth);

  const setAuthTokenFunc = React.useCallback(
    (token: string | null) => {
      if (token) {
        localStorage.setItem(AUTH_STORAGE_KEY, token);
      } else {
        localStorage.removeItem(AUTH_STORAGE_KEY);
      }
      setAuthToken(token);
    },
    [setAuthToken],
  );

  const isLoggedIn = React.useMemo(() => !!authToken, [authToken]);

  const signOut = React.useCallback(() => {
    setAuthTokenFunc(null);
  }, [setAuthTokenFunc]);

  const fetchUserDetails = React.useCallback(async () => {
    if (!authToken || authToken === 'null') {
      setAuthTokenFunc(null);
      return;
    }
    try {
      const resp = await fetch(
        `${import.meta.env.VITE_API_ROOT}/auth/users/me/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${authToken}`,
          },
          mode: 'cors',
          credentials: 'same-origin',
        },
      );
      if (resp.status === 401) {
        setAuthTokenFunc(null);
      }
      if (resp.status !== 200) {
        throw Error('Invalid request');
      } else {
        const data: {email: string} = await resp.json();
        setUserTrackingEmail(data.email);
      }
    } catch (e) {
      // do nothing
    }
  }, [authToken, setAuthTokenFunc, setUserTrackingEmail]);

  React.useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  return (
    <userContext.Provider
      value={{
        authToken,
        isLoggedIn,
        setAuthToken: setAuthTokenFunc,
        signOut: signOut,
      }}
    >
      {children}
    </userContext.Provider>
  );
};
