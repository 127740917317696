import ConfettiContainer from '@/components/ConfettiContainer';
import {ConfirmProvider} from '@/contexts/confirmModal';
import {HeadsUpProvider} from '@/contexts/headsUpModal';
import AuthenticatedRoot from '@/routes/AuthenticatedRoot';
import ThemeCustomization from '@/themes';
import {init} from '@amplitude/analytics-browser';
import {CssBaseline} from '@mui/material';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {ArkLoader, userContext} from 'common';
import React, {Suspense, useEffect} from 'react';
import ReactGA from 'react-ga4';
import lazyWithPreload from 'react-lazy-with-preload';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import {useIntercom} from 'react-use-intercom';
import './App.css';
import {ConfettiProvider} from './contexts/Confetti';
import {AppRedirect} from './routes/AppRedirect';
import {AppStoreRedirect} from './routes/AppStoreRedirect';
import {ErrorPage} from './components/ErrorPage';
import {ErrorBoundary} from '@sentry/react';
import {ParamsToUUIDNavigator} from './routes/ParamsToUUIDNavigator';
import {UserTypeProvider} from './contexts/UserTypeContext/UserTypeContext';
import * as Sentry from '@sentry/react';
import {useClarity} from 'use-clarity';
import {SnackbarProvider} from 'notistack';
import ThemeResponsiveSnackbar from './components/ThemeResponsiveSnackbar';

const SwitchSignupPage = lazyWithPreload(
  () => import('./pages/BTL/Login/SwitchSignup'),
);
const SigningLanding = lazyWithPreload(
  () => import('./pages/BTL/Signing/TenantSigning/TenantSigning'),
);
const FacebookAuth = lazyWithPreload(
  () => import('./pages/FacebookAuth/FacebookAuth'),
);
const StopReminders = lazyWithPreload(
  () => import('./pages/BTL/Documents/Section21/StopReminders'),
);
const PrivacyPolicy = lazyWithPreload(
  () => import('./pages/PrivacyPolicy/PrivacyPolicy'),
);

const BTLLoginPage = lazyWithPreload(() => import('./pages/BTL/Login/Login'));
const SignupPage = lazyWithPreload(() => import('./pages/BTL/Login/Signup'));

const EnterpriseLogin = lazyWithPreload(() => import('./pages/ENT/Login'));
const TypeformSignup = lazyWithPreload(
  () => import('./pages/BTL/Login/TypeformSignup'),
);

const ResetPasswordPage = lazyWithPreload(() =>
  import('./pages/BTL/Login/ResetPassword').then(module => ({
    default: module.ResetPassword,
  })),
);
const SetPasswordPage = lazyWithPreload(
  () => import('./pages/BTL/Login/SetPassword'),
);
const TenantReferencePage = lazyWithPreload(
  () => import('./pages/BTL/Referencing/TenantReference/TenantReference'),
);
const LandlordReferencePage = lazyWithPreload(
  () => import('./pages/BTL/Referencing/LandlordReference/LandlordReference'),
);
const EmployerReferencePage = lazyWithPreload(
  () => import('./pages/BTL/Referencing/EmployerReference/EmployerReference'),
);
const ReferenceSignup = lazyWithPreload(
  () => import('./pages/BTL/Referencing/ReferenceSignup/ReferenceSignup'),
);
const VisitorSignIn = lazyWithPreload(() =>
  import('visitor-lib').then(module => ({
    default: module.VisitorSignIn,
  })),
);

const componentsToPreload = [
  SwitchSignupPage,
  SigningLanding,
  FacebookAuth,
  StopReminders,
  PrivacyPolicy,
  SignupPage,
  TypeformSignup,
  ResetPasswordPage,
  SetPasswordPage,
  TenantReferencePage,
  LandlordReferencePage,
  EmployerReferencePage,
  BTLLoginPage,
  EnterpriseLogin,
  ReferenceSignup,
  VisitorSignIn,
];

type Props = {
  children: React.ReactNode;
};
const PrivateRoute = ({children}: Props) => {
  const location = useLocation();
  const {isLoggedIn} = React.useContext(userContext);
  const searchProps = location?.search.toString()
    ? `&${location?.search.toString().split('?')[1]}`
    : '';
  const nextPath = location?.pathname;
  const fromTypeform =
    nextPath?.startsWith('/agreements') ||
    nextPath?.startsWith('/documents/section-21');
  const redirectPath = fromTypeform ? '/typeform-signup' : '/login';

  if (!isLoggedIn) {
    return (
      <Navigate to={`${redirectPath}?next=${nextPath}${searchProps}`} replace />
    );
  }

  return children;
};

const GA_ID = import.meta.env.VITE_GA_ID;
if (GA_ID) {
  ReactGA.initialize(`${GA_ID}`, {
    gaOptions: {siteSpeedSampleRate: 100},
  });
}
function App() {
  const {boot, update, trackEvent} = useIntercom();
  const {currentSessionUrl, clarityUserId} = useClarity();
  useEffect(() => {
    boot();
  }, [boot]);
  useEffect(() => {
    componentsToPreload.forEach(component => component.preload());
  }, []);
  const subdomain = window.location.host.split('.')[0].toLowerCase();
  if (import.meta.env.VITE_AMPLITUDE_API_KEY_BTL) {
    init(import.meta.env.VITE_AMPLITUDE_API_KEY_BTL);
  }

  React.useEffect(() => {
    if (currentSessionUrl) {
      trackEvent('session', {
        link: currentSessionUrl,
      });
      Sentry.setContext('clarity', {
        link: currentSessionUrl,
      });
    }
  }, [currentSessionUrl]);

  React.useEffect(() => {
    if (clarityUserId) {
      update({
        customAttributes: {
          clarity_id: clarityUserId,
        },
      });
      Sentry.setTag('clarity.usedId', clarityUserId);
    }
  }, [clarityUserId]);

  return (
    <ThemeCustomization>
      <ErrorBoundary fallback={<ErrorPage />}>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-gb">
          <ConfirmProvider>
            <HeadsUpProvider>
              <SnackbarProvider
                maxSnack={4}
                Components={{
                  default: ThemeResponsiveSnackbar,
                  success: ThemeResponsiveSnackbar,
                  error: ThemeResponsiveSnackbar,
                  warning: ThemeResponsiveSnackbar,
                  info: ThemeResponsiveSnackbar,
                }}
              >
                <ConfettiProvider>
                  <CssBaseline />
                  <BrowserRouter>
                    <Suspense fallback={<ArkLoader />}>
                      <Routes>
                        <Route
                          path="/login"
                          element={
                            subdomain === 'dashboard' ? (
                              <EnterpriseLogin />
                            ) : (
                              <BTLLoginPage />
                            )
                          }
                        />
                        <Route path="/signup" element={<SignupPage />} />
                        <Route
                          path="/typeform-signup"
                          element={<TypeformSignup />}
                        />
                        <Route
                          path="/reset-password/:uid/:token"
                          element={
                            <ParamsToUUIDNavigator parameterNames={['uid']}>
                              <SetPasswordPage />
                            </ParamsToUUIDNavigator>
                          }
                        />
                        <Route
                          path="/reset-password"
                          element={<ResetPasswordPage />}
                        />
                        <Route
                          path="/switch-signup"
                          element={<SwitchSignupPage />}
                        />
                        <Route
                          path="/sign/:agreementId/:signatureId"
                          element={
                            <ParamsToUUIDNavigator
                              parameterNames={['agreementId', 'signatureId']}
                            >
                              <SigningLanding />
                            </ParamsToUUIDNavigator>
                          }
                        />
                        <Route
                          path="/facebook-auth"
                          element={<FacebookAuth />}
                        />
                        <Route
                          path="/stop-reminders/:section21Id"
                          element={
                            <ParamsToUUIDNavigator
                              parameterNames={['section21Id']}
                            >
                              <StopReminders />
                            </ParamsToUUIDNavigator>
                          }
                        />
                        <Route path="/privacy" element={<PrivacyPolicy />} />
                        <Route
                          path="/tenant-reference/:tenantReferenceId"
                          element={
                            <ParamsToUUIDNavigator
                              parameterNames={['tenantReferenceId']}
                            >
                              <TenantReferencePage />
                            </ParamsToUUIDNavigator>
                          }
                        />
                        <Route
                          path="/landlord-reference/:landlordReferenceId"
                          element={
                            <ParamsToUUIDNavigator
                              parameterNames={['landlordReferenceId']}
                            >
                              <LandlordReferencePage />
                            </ParamsToUUIDNavigator>
                          }
                        />
                        <Route
                          path="/employer-reference/:employerReferenceId"
                          element={
                            <ParamsToUUIDNavigator
                              parameterNames={['employerReferenceId']}
                            >
                              <EmployerReferencePage />
                            </ParamsToUUIDNavigator>
                          }
                        />
                        <Route
                          path="/reference-signup"
                          element={<ReferenceSignup />}
                        />
                        <Route
                          path="/visitor-signin/:communityId"
                          element={
                            <ParamsToUUIDNavigator
                              parameterNames={['communityId']}
                            >
                              <VisitorSignIn />
                            </ParamsToUUIDNavigator>
                          }
                        />
                        <Route path="/app-redirect" element={<AppRedirect />} />
                        <Route
                          path="/download-app"
                          element={<AppStoreRedirect />}
                        />
                        <Route
                          path="*"
                          element={
                            <PrivateRoute>
                              <UserTypeProvider>
                                <AuthenticatedRoot />
                              </UserTypeProvider>
                            </PrivateRoute>
                          }
                        />
                      </Routes>
                    </Suspense>
                  </BrowserRouter>
                  <ConfettiContainer />
                </ConfettiProvider>
              </SnackbarProvider>
            </HeadsUpProvider>
          </ConfirmProvider>
        </LocalizationProvider>
      </ErrorBoundary>
    </ThemeCustomization>
  );
}
export default App;
