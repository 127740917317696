import {HelpOutlineRounded} from '@mui/icons-material';
import {Box, Button} from '@mui/material';
import React from 'react';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  button: {
    opacity: '56%',
    marginBottom: 8,
  },
});

const FooterContent: React.FC = () => {
  const styles = useStyles();
  return (
    <Box marginTop="16px" width="120px">
      <Button
        className={styles.button}
        startIcon={<HelpOutlineRounded />}
        onClick={() =>
          window.open(
            'https://ark-rent.notion.site/FAQs-81fcc16ba5204f9eab83b43bd9dc0d6a',
          )
        }
        fullWidth
        color="inherit"
      >
        FAQs
      </Button>
    </Box>
  );
};

export default FooterContent;
