import {ResponsiveDialogClose} from '@/components/dialogs/ResponsiveDialogClose';
import {usePackageCollectors} from '@ark/react';
import {HorizontalCenterCircularProgress, ResponsiveDialog} from 'common';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {AddApplicant} from '../../PackageDialog/CollectionDialog/AddApplicant/AddApplicant';
import {CollectAllPackages} from './CollectAllPackages/CollectAllPackages';

export type CollectionDialogState = 'SELECT_COLLECTOR' | 'ADD_APPLICANT';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const CollectAllPackagesDialog = ({open, onClose}: Props) => {
  const {homeId} = useParams() as {homeId: string};
  const [dialogState, setDialogState] =
    useState<CollectionDialogState>('SELECT_COLLECTOR');
  const [applicantUserId, setApplicantUserId] = useState<string | null>(null);

  const {collectors, loading, home} = usePackageCollectors({homeId});

  return (
    <ResponsiveDialog open={open} onClose={onClose} alwaysHideIntercom>
      <ResponsiveDialogClose onClose={onClose} />
      {loading && (
        <HorizontalCenterCircularProgress sx={{marginY: 16, marginX: 20}} />
      )}
      {dialogState === 'SELECT_COLLECTOR' && home?.address && (
        <CollectAllPackages
          address={home?.address}
          setDialogState={setDialogState}
          onClose={onClose}
          applicantUserId={applicantUserId}
          collectors={collectors}
        />
      )}
      {dialogState === 'ADD_APPLICANT' && home?.community?.id && (
        <AddApplicant
          homeId={homeId}
          communityId={home?.community?.id}
          onBack={() => {
            setDialogState('SELECT_COLLECTOR');
          }}
          onComplete={applicant => {
            setApplicantUserId(applicant.user.id);
            setDialogState('SELECT_COLLECTOR');
          }}
        />
      )}
    </ResponsiveDialog>
  );
};
