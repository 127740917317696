import {DialogActions, DialogContent, Typography} from '@mui/material';
import FormTextField from '@/components/forms/FormTextField';
import {useForm} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import {useUpdateMobileMutation} from '@/generated/graphql';

const AddPhoneDialog = () => {
  const [updateMobile, {loading}] = useUpdateMobileMutation();
  const {handleSubmit, setError, control} = useForm({
    defaultValues: {
      mobile: '',
    },
  });
  const onMobileSubmit = async ({mobile}: {mobile: string}) => {
    const res = await updateMobile({
      variables: {input: {mobile}},
    });
    if (res.data?.updateMobileNumber?.error) {
      setError('mobile', {message: res.data?.updateMobileNumber?.error});
    }
  };

  return (
    <>
      <DialogContent>
        <form id="add-phone-form" onSubmit={handleSubmit(onMobileSubmit)}>
          <Typography variant="h3" textAlign="center" mb={2}>
            Please provide your phone number
          </Typography>
          <Typography variant="body1" mb={2} textAlign="center">
            We don’t seem to have your phone number. Please add it below, and we
            will then send a code for you to confirm.
          </Typography>
          <FormTextField
            name="mobile"
            label="Phone number"
            control={control}
            rules={{required: 'Mobile is required'}}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          variant="ark"
          color="primary"
          form="add-phone-form"
          fullWidth
          type="submit"
        >
          Send Code
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default AddPhoneDialog;
