import {Dialog, DialogProps, useMediaQuery, useTheme} from '@mui/material';
import React from 'react';
import {useIntercom} from 'react-use-intercom';

export type ResponsiveDialogProps = DialogProps & {
  alwaysHideIntercom?: boolean;
};
export const ResponsiveDialog: React.FC<ResponsiveDialogProps> = ({
  open,
  alwaysHideIntercom,
  ...restProps
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {update} = useIntercom();

  React.useEffect(() => {
    update({hideDefaultLauncher: alwaysHideIntercom || (open && fullScreen)});
  }, [update, open, fullScreen, alwaysHideIntercom]);

  return <Dialog fullScreen={fullScreen} open={open} {...restProps} />;
};
