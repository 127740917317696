import React from 'react';
import {
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from '@mui/material';
import FormTextField from '@/components/forms/FormTextField';
import {useForm} from 'react-hook-form';
import {useMFA} from '@/hooks/useMFA';
import {userProfileContext} from 'common';
import {LoadingButton} from '@mui/lab';

const ConfirmPhoneDialog = ({onSuccess}: {onSuccess: () => void}) => {
  const {palette} = useTheme();
  const [loading, setLoading] = React.useState(false);
  //@ts-ignore
  const {mobile} = React.useContext(userProfileContext);

  const {addMFADevice, verifyMFADevice} = useMFA();

  const sendCode = async () => {
    setLoading(true);
    //This is a random number, as using the real phone number results in an error...
    //@ts-ignore
    const {errors} = await addMFADevice('text')({mobile: '07849537467'});
    if (errors) {
      console.log(errors);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    sendCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {handleSubmit, control, setError} = useForm({
    defaultValues: {
      code: '',
    },
  });

  const onCodeSubmit = React.useCallback(
    async ({code}: {code: string}) => {
      const {errors} = await verifyMFADevice('text')({code});
      if (errors) {
        //@ts-ignore
        if (errors.code) {
          //@ts-ignore
          setError('code', {message: errors.code});
        }
      } else {
        onSuccess();
      }
    },
    [setError, onSuccess, verifyMFADevice],
  );

  return (
    <>
      <DialogContent>
        <form id="confirm-phone-form" onSubmit={handleSubmit(onCodeSubmit)}>
          <Typography variant="h3" textAlign="center" mb={2}>
            Get codes sent to your phone
          </Typography>
          <Typography variant="body1" textAlign="center" mb={2}>
            Each time you log in, we’ll send a one-time password via SMS to the
            phone number on record for you.
          </Typography>
          {loading && (
            <Typography
              variant="body1"
              mb={1}
              textAlign="center"
              fontWeight="bold"
            >
              We've sending a code to{' '}
              <span style={{color: palette.secondary.main}}>{mobile}</span>
            </Typography>
          )}
          {!loading && (
            <>
              <Typography
                variant="body1"
                mb={1}
                textAlign="center"
                fontWeight="bold"
              >
                We've just sent a code to{' '}
                <span style={{color: palette.secondary.main}}>{mobile}</span>
              </Typography>
              <Typography
                variant="body1"
                mb={2}
                textAlign="center"
                fontWeight="bold"
              >
                Please enter it below to verify
              </Typography>
            </>
          )}
          <FormTextField
            name="code"
            label="Confirmation Code"
            control={control}
            rules={{required: 'Code is required'}}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          variant="ark"
          fullWidth
          color="primary"
          type="submit"
          form="confirm-phone-form"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ConfirmPhoneDialog;
