import {makeStyles} from '@mui/styles';
import {Box, BoxProps} from '@mui/system';

const useListItemEndStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: 8,
  },
}));

const ListItemEnd: React.FC<BoxProps> = ({children, ...rest}) => {
  const styles = useListItemEndStyles();
  return (
    <Box {...rest} className={styles.root}>
      {children}
    </Box>
  );
};
export default ListItemEnd;
