import {LoadingButton} from '@mui/lab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import confirmContext from '@/contexts/confirmModal';
import React, {useState} from 'react';

const Confirm = () => {
  const {confirmModal, closeModal} = React.useContext(confirmContext);
  const [loading, setLoading] = useState(false);
  if (!confirmModal) {
    return <div />;
  }

  return (
    <Dialog
      open={confirmModal.open}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="small-title">
        {confirmModal.title}
      </DialogTitle>
      <DialogContent>
        {confirmModal.description && (
          <DialogContentText id="alert-dialog-description">
            {confirmModal.description}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {!confirmModal.singleOption && (
          <Button onClick={closeModal} color="secondary" variant="outlined">
            Cancel
          </Button>
        )}
        <LoadingButton
          onClick={async () => {
            setLoading(true);
            await confirmModal.confirmAction();
            closeModal();
            setLoading(false);
          }}
          color="secondary"
          autoFocus
          variant="contained"
          loading={loading}
        >
          {confirmModal.singleOption ? 'OK' : 'Confirm'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
