import React from 'react';
import {Box, SxProps} from '@mui/material';
import {Article, PlayCircle} from '@mui/icons-material';
import {Document, Thumbnail} from 'react-pdf';
import {isUrlPdf, isVideo} from '@/utils/files';
import {isImage} from '@/utils/images';
import {colors} from '@ark/react';

type ThumbnailContentProps = {
  file: File | string;
  fileName: string;
  extension: string;
  imageOnly?: boolean;
  sx?: SxProps;
};

export const ThumbnailContent = ({
  file,
  fileName,
  extension,
  imageOnly,
  sx,
}: ThumbnailContentProps) => {
  const showPdf =
    isUrlPdf(fileName) &&
    !(file instanceof File) &&
    file?.includes(import.meta.env.VITE_BACKEND_FILE_ROOT);
  const isVideoFile = isVideo(extension);

  const placeholderSx: SxProps = {
    height: 112,
    width: 112,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    color: 'grey.500',
    backgroundColor: 'grey.300',
    padding: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const imgSx: SxProps = {
    height: 112,
    width: imageOnly ? 'auto' : 112,
    maxWidth: '100%',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    objectFit: 'cover',
  };

  if (isImage(extension)) {
    return (
      <Box
        component="img"
        src={file instanceof File ? URL.createObjectURL(file) : file}
        alt={fileName}
        sx={{...imgSx, ...sx}}
      />
    );
  }

  if (showPdf) {
    return (
      <Document file={file} loading={() => <Box sx={placeholderSx} />}>
        <Thumbnail
          pageNumber={1}
          height={112}
          loading={() => <Box sx={placeholderSx} />}
        />
      </Document>
    );
  }

  if (isVideoFile) {
    return (
      <Box
        sx={{
          ...imgSx,
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'black',
          ...sx,
        }}
      >
        <Box
          component="video"
          src={file instanceof File ? URL.createObjectURL(file) : file}
          sx={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
        <PlayCircle
          sx={{position: 'absolute', color: colors['gray.500'], fontSize: 48}}
        />
      </Box>
    );
  }

  return <Article fontSize="large" sx={{...placeholderSx, ...sx}} />;
};
