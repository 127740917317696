import {Breadcrumbs} from '@/components/Breadcrumbs';
import {Box, Grid, Typography, useTheme} from '@mui/material';
import PackageDialogHeaderSkeleton from './Skeleton';
import {Pill} from '@ark/web';
import {LocationPill} from '../LocationPill';

type PackageDialogHeaderProps = {
  id: string;
  addressee: string;
  address: string;
  communityName: string;
  collected: boolean;
  loading: boolean;
  location?: string;
};

const PackageDialogHeader = ({
  id,
  addressee,
  address,
  communityName,
  collected,
  loading,
  location,
}: PackageDialogHeaderProps) => {
  const formatId = (id: string) => {
    return `${id.slice(0, 4)}-${id.slice(-4)}`;
  };
  const {palette} = useTheme();

  if (loading || !id) return <PackageDialogHeaderSkeleton />;

  return (
    <Box mt={2} mx={4} borderBottom="1px solid" borderColor={palette.grey[300]}>
      <Breadcrumbs breadcrumbs={['Packages', 'View Package']} />
      <Typography variant="h1" mb={1}>
        {addressee}
      </Typography>
      <Typography variant="h5" mb={1} color={palette.grey[500]}>
        {address}
      </Typography>
      <Grid container spacing={2} mt={2} mb={2}>
        <Grid item md={3} xs={6}>
          <Typography variant="h4">COMMUNITY</Typography>
          <Typography variant="body1" mt={{md: 2, xs: 1}}>
            {communityName}
          </Typography>
        </Grid>
        <Grid item md={3} xs={6}>
          <Typography variant="h4">ID</Typography>
          <Typography variant="body1" mt={{md: 2, xs: 1}}>
            {formatId(id)}
          </Typography>
        </Grid>
        <Grid item md={3} xs={6}>
          <Typography variant="h4">STATUS</Typography>
          <Pill
            label={collected ? 'Collected' : 'For Collection'}
            color={collected ? palette.success.main : palette.warning.main}
            sx={{mt: {md: 2, xs: 1}}}
          />
        </Grid>
        {location && (
          <Grid item md={3} xs={6}>
            <Typography variant="h4">LOCATION</Typography>
            <LocationPill location={location} sx={{mt: {md: 2, xs: 1}}} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PackageDialogHeader;
