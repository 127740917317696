import React from 'react';
import {makeStyles, useTheme} from '@mui/styles';
import {AppBar, Box, Container, Typography} from '@mui/material';
import MainLogo from './MainLogo';
import NotificationsMenu from './NotificationsMenu/NotificationsMenu';
import ProfileMenu from './ProfileMenu';
import {useNotifications} from '@/hooks/useInitializeNotifications';
import {Cast} from '@mui/icons-material';
import {useClientType} from '@/hooks/useClientType/useClientType';

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.background.default,
    '@media print': {
      display: 'none',
    },
  },
  container: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1464px',
    padding: '8px 40px 8px 64px',
    [theme.breakpoints.down('md')]: {
      padding: '16px',
    },
  },
  menuBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
}));

const TopBar: React.FC = () => {
  const styles = useStyles();
  const {palette} = useTheme();

  const clientType = useClientType();
  const {status, subscribe} = useNotifications();
  const showNotificationsBar = status === 'default';

  return (
    <AppBar position="fixed" elevation={0} className={styles.appBar}>
      {clientType === 'ENT' && showNotificationsBar && (
        <Box
          sx={{
            backgroundColor: palette.secondary.main,
            padding: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              cursor: 'pointer',
              opacity: 0.9,
            },
          }}
          onClick={subscribe}
        >
          <Cast fontSize="small" />
          <Typography ml={1} variant="h4" color="white">
            We recommend turning on desktop notifications to get the most out of
            Ark. Click here to turn them on.
          </Typography>
        </Box>
      )}
      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          borderBottom: '1px solid',
          borderBottomColor: 'grey.300',
        }}
      >
        <Box className={styles.container}>
          <MainLogo />
          <Box className={styles.menuBox}>
            {clientType === 'ENT' && <NotificationsMenu />}
            <ProfileMenu />
          </Box>
        </Box>
      </Container>
    </AppBar>
  );
};

export default TopBar;
