import {useSubscribeToNotifications} from '@/hooks/useInitializeNotifications';
import {useSelectedCommunities} from 'common';
import React from 'react';
import {SidebarItemBadge} from '../SidebarItemBadge';
import {useDocumentSentApplicationsQuery} from '@/generated/graphql';

type Props = {
  children: React.ReactNode;
};

export const ApplicationsBadge = (props: Props) => {
  const communities = useSelectedCommunities();

  const {data, refetch} = useDocumentSentApplicationsQuery({
    variables: {
      communities,
    },
    pollInterval: 300_000,
  });

  useSubscribeToNotifications(notification => {
    if (
      notification?.actionObject?.__typename === 'ApplicationNode' ||
      notification?.target?.__typename === 'ApplicationNode'
    ) {
      refetch();
    }
  }, 'ApplicationsBadge');

  return (
    <SidebarItemBadge
      badgeContent={data?.applications?.totalCount || 0}
      tooltipContent="Number of applications that require action"
    >
      {props.children}
    </SidebarItemBadge>
  );
};
