import {Box, BoxProps} from '@mui/material';
import brickLogo from '../assets/brickLogo.png';
import Color from 'color';
import {colors} from '../theme/colors';

export const LogoGreyCircle = (props: BoxProps) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: new Color(colors.hintOfRed).darken(0.01).hex(),
        borderRadius: '35px',
        width: '70px',
        height: '70px',
      }}
      {...props}
    >
      <img
        style={{
          width: 50,
        }}
        alt="ARK Logo"
        src={brickLogo}
      />
    </Box>
  );
};
