import {useEffect} from 'react';
import ReactGA from 'react-ga4';
import {useLogEventOnFirstRender} from './useLogEventOnFirstRender';
import {useLocation} from 'react-router-dom';

export const useTrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location]);

  useLogEventOnFirstRender({
    name: 'Page View',
    data: {
      path: location.pathname,
      searchParams: location.search,
    },
  });
};
