import {useEffect} from 'react';
import {AmplitudeEvent, useLogEvent} from './useLogEvent/useLogEvent';

export const useLogEventOnFirstRender = (event?: AmplitudeEvent) => {
  const logEvent = useLogEvent();
  useEffect(() => {
    if (event) {
      logEvent(event);
    }
  }, [event, logEvent]);
};

export type OnFirstRenderEventProp = {
  onFirstRenderEvent?: AmplitudeEvent;
};
