import {SideDialogAccordion} from '@/components/dialogs/SideDialog/SideDialogAccordion';
import {useMarketplaceItemCommentsQuery} from '@/generated/graphql';
import {ChatBubble} from '@mui/icons-material';
import {Box, Typography, useTheme} from '@mui/material';
import {DateTime} from 'luxon';
import {LoadingButton} from '@mui/lab';

type Props = {id: string};

export const MarketplaceItemComments = ({id}: Props) => {
  const {palette} = useTheme();
  const {data, fetchMore, loading} = useMarketplaceItemCommentsQuery({
    variables: {
      id,
    },
  });
  const hasNextPage = data?.marketplaceItem?.comments?.pageInfo?.hasNextPage;

  return (
    <SideDialogAccordion
      title="Comments"
      startIcon={<ChatBubble fontSize="small" />}
      typographyProps={{variant: 'subtitle1'}}
      sx={{my: 2}}
    >
      {data?.marketplaceItem?.comments?.edges?.map(comment => (
        <Box
          key={comment?.node?.id}
          display="flex"
          bgcolor={palette.common.white}
          borderRadius={1}
          p={1}
          width="100%"
          mb={1}
          justifyContent="space-between"
          flexDirection={['column', 'row']}
        >
          <Typography variant="body2" maxWidth="85%">
            {comment?.node?.comment}
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Typography variant="subtitle2">{comment?.node?.name}</Typography>
            <Typography variant="caption">
              {DateTime.fromISO(comment?.node?.createdAt ?? '').toLocaleString(
                DateTime.DATETIME_MED,
              )}
            </Typography>
          </Box>
        </Box>
      ))}
      {hasNextPage && (
        <LoadingButton
          variant="ark"
          onClick={() =>
            fetchMore({
              variables: {
                after: data?.marketplaceItem?.comments?.pageInfo?.endCursor,
              },
            })
          }
          loading={loading}
          sx={{
            display: 'block',
            margin: 'auto',
          }}
        >
          Load more
        </LoadingButton>
      )}
    </SideDialogAccordion>
  );
};
