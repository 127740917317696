import {useCallback, useContext, useEffect, useState} from 'react';
import {captureException} from '@sentry/react';
import {userContext} from 'common';

const transformDevice = (device?: {name: string; is_primary: boolean}) => {
  if (!device) {
    return undefined;
  }
  return {
    name: device.name,
    isPrimary: device.is_primary,
  };
};

interface MFApaths {
  'mfa/user-active-methods': [
    undefined,
    {
      name: string;
      is_primary: boolean;
    }[],
  ];
  'app/deactivate/': [undefined, Record<string, never>];
  'app/activate/': [
    undefined,
    {
      details: string;
    },
  ];
  'app/activate/confirm/': [
    {
      code: string;
    },
    undefined,
  ];
  'text/deactivate/': [undefined, Record<string, never>];
  'text/activate/': [
    {
      mobile: string;
    },
    {
      details: string;
    },
  ];
  'text/activate/confirm/': [
    {
      code: string;
    },
    undefined,
  ];
}

const GET_PATHS = ['mfa/user-active-methods'];

export const useMFA = () => {
  const {authToken} = useContext(userContext);
  const [initialized, setInitialized] = useState(false);

  const [mfaDevices, setMfaDevices] = useState<{
    app?: {name: string; isPrimary: boolean};
    text?: {name: string; isPrimary: boolean};
  }>({});

  const makeRequest = useCallback(
    <T extends keyof MFApaths>(path: T) =>
      async (data?: MFApaths[T][0]) => {
        try {
          const response = await fetch(
            `${import.meta.env.VITE_API_ROOT}/enterprise/auth/${path}`,
            {
              method: GET_PATHS.includes(path) ? 'GET' : 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${authToken}`,
              },
              body: JSON.stringify(data),
            },
          );
          if (response.status === 400) {
            return {
              data: null,
              errors: (await response.json()) as MFApaths[T][0],
            };
          } else if (response.status === 200) {
            return {
              data: (await response.json()) as MFApaths[T][1],
              errors: null,
            };
          } else {
            return {
              data: null,
              errors: {
                nonFieldError: [
                  'Something went wrong. Please try again later.',
                ],
              },
            };
          }
        } catch (e) {
          captureException(e);
          return {
            data: null,
            errors: {
              nonFieldError: ['Something went wrong. Please try again later.'],
            },
          };
        }
      },
    [authToken],
  );

  const listMFADevices = useCallback(async () => {
    const response = await makeRequest('mfa/user-active-methods')();
    if (response.data) {
      setMfaDevices({
        app: transformDevice(response.data.find(d => d.name === 'app')),
        text: transformDevice(response.data.find(d => d.name === 'text')),
      });
    }
    setInitialized(true);
  }, [setMfaDevices, makeRequest]);

  useEffect(() => {
    listMFADevices();
  }, [listMFADevices]);

  const removeMFADevice = (type: 'app' | 'text') => {
    switch (type) {
      case 'app':
        return makeRequest('app/deactivate/');
      case 'text':
        return makeRequest('text/deactivate/');
      default:
        return () => Promise.resolve({data: null, errors: null});
    }
  };

  const addMFADevice = (type: 'app' | 'text') => {
    switch (type) {
      case 'app':
        return makeRequest('app/activate/');
      case 'text':
        return makeRequest('text/activate/');
      default:
        return () => Promise.resolve({data: null, errors: null});
    }
  };

  const verifyMFADevice = (type: 'app' | 'text') => {
    switch (type) {
      case 'app':
        return makeRequest('app/activate/confirm/');
      case 'text':
        return makeRequest('text/activate/confirm/');
      default:
        return () => Promise.resolve({data: null, errors: null});
    }
  };

  return {
    mfaDevices,
    refetch: listMFADevices,
    removeMFADevice,
    addMFADevice,
    verifyMFADevice,
    initialized,
  };
};
