import IconButton from '@mui/material/IconButton';
import {ChevronLeft} from '@mui/icons-material';

export interface DialogBackProps {
  onClick: () => void;
}

export const DialogBack = (props: DialogBackProps) => {
  return (
    <IconButton
      sx={{position: 'absolute', top: 8, left: 8}}
      onClick={props.onClick}
    >
      <ChevronLeft />
    </IconButton>
  );
};
