import {Box, BoxProps} from '@mui/system';

type Props = {
  statusColor?: string;
} & BoxProps;

export const ListItem = (props: Props) => {
  const {sx, children, onClick, statusColor, ...rest} = props;

  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        gap: 1,
        justifyContent: 'space-between',
        bgcolor: 'background.paper',
        border: 1,
        borderColor: 'divider',
        borderRadius: '4px',
        p: {xs: 1, md: 1.4},
        mb: 1,
        width: '100%',
        transition: 'transform 0.2s',
        borderLeftColor: statusColor ? statusColor : 'none',
        borderLeftWidth: statusColor ? 4 : 1,
        ...(onClick && {
          '&:hover': {
            borderColor: 'grey.400',
            borderLeftWidth: statusColor ? 4 : 1,
            borderLeftColor: statusColor ? statusColor : 'grey.400',
            cursor: 'pointer',
            transform: 'scale(1.01)',
            transition: 'transform 0.2s',
            boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.05)',
          },
        }),
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
