import {Stack, Box, BoxProps, StackProps} from '@mui/material';

type ListItemContentProps = StackProps & {
  leftAdornment?: React.ReactNode;
  contentProps?: BoxProps;
};

const ListItemContent: React.FC<ListItemContentProps> = ({
  children,
  leftAdornment,
  contentProps,
  ...rest
}) => {
  return (
    <Stack
      direction="row"
      sx={{
        overflow: 'hidden',
        alignItems: 'center',
      }}
      {...rest}
    >
      {leftAdornment && (
        <Box
          sx={{
            marginRight: 2,
            alignItems: 'flex-start',
            display: 'flex',
          }}
        >
          {leftAdornment}
        </Box>
      )}
      <Box
        sx={{
          minWidth: 0,
          overflow: 'hidden',
        }}
        {...contentProps}
      >
        {children}
      </Box>
    </Stack>
  );
};

export default ListItemContent;
