//@ts-nocheck
import React from 'react';
//@ts-ignore
import {Box} from '@mui/material';
//@ts-ignore
import {makeStyles} from '@mui/styles';
//@ts-ignore
import {
  NavigateBeforeRounded,
  NavigateNextRounded,
  CloseRounded,
  Delete,
  //@ts-ignore
} from '@mui/icons-material';
import {colors} from '../theme/colors';

export type ImageType = {
  id: string;
  image: string;
  __typename: string;
};

interface CarouselProps {
  images?: Array<string | ImageType> | null;
  index: number;
  setIndex: (index: number) => void;
  dialog?: boolean;
  onDelete?: (index: number) => void;
  imagePrefix: string;
}

//@ts-ignore
const useStyles = makeStyles(theme => ({
  imageContainer: {
    padding: '16px',
    margin: 'auto',
    justifyContent: 'space-between',
    position: 'relative',
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    height: 400,
    [theme.breakpoints.down('sm')]: {
      height: 300,
    },
  },
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.5)',
    background: 'rgba(255, 255, 255, 0.5)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    zIndex: 1,
  },
  icon: {
    background: 'rgba(0, 0, 0, 0.5)',
    color: colors.white,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 20,
    left: 20,
    zIndex: 1,
    opacity: 0.8,
    cursor: 'pointer',
    '&:hover': {
      opacity: 1,
    },
  },
  deleteOverlay: {
    position: 'absolute',
    top: 20,
    right: 20,
    width: '32px',
    height: '32px',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    color: colors.white,
    borderRadius: '50%',
    opacity: 0.8,
    cursor: 'pointer',
    '&:hover': {
      opacity: 1,
    },
  },
}));

export const Carousel: React.FC<CarouselProps> = props => {
  const styles = useStyles();
  const length = props.images ? props.images.length : 0;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const image = props.images ? props.images[props.index] : null;

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'ArrowLeft' || e.key === 'ArrowUp') && props.index > 0) {
      props.setIndex(props.index - 1);
    } else if (
      (e.key === 'ArrowRight' || e.key === 'ArrowDown') &&
      props.images &&
      props.index < length - 1
    ) {
      props.setIndex(props.index + 1);
    }
  };

  return (
    //@ts-ignore
    <>
      {props.images && (
        <Box
          ref={inputRef}
          className={styles.imageContainer}
          onKeyDown={handleKeyDown}
          tabIndex={0}
        >
          {props.dialog && (
            <CloseRounded
              className={styles.icon}
              onClick={() => props.setIndex(-1)}
              fontSize="large"
            />
          )}
          {props.index !== 0 && (
            //@ts-ignore
            <NavigateBeforeRounded
              className={styles.arrowIcon}
              sx={{
                left: '0%',
                transform: 'translate(50%, -50%)',
              }}
              fontSize="large"
              onClick={() => {
                if (props.index > 0) {
                  props.setIndex(props.index - 1);
                }
              }}
            />
          )}
          <img
            className={styles.image}
            src={
              typeof image === 'string'
                ? `${props.imagePrefix}${image}`
                : `${props.imagePrefix}${image?.image}`
            }
            alt=""
          />
          {typeof image !== 'string' && !!props.onDelete && (
            <div className={styles.deleteOverlay}>
              <Delete onClick={(e: void) => props.onDelete(props.index)} />
            </div>
          )}
          {props.index !== length - 1 && (
            <NavigateNextRounded
              className={styles.arrowIcon}
              sx={{
                right: '0%',
                transform: 'translate(-50%, -50%)',
              }}
              fontSize="large"
              onClick={() => {
                if (props.images && props.index < length - 1) {
                  props.setIndex(props.index + 1);
                }
              }}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default Carousel;
