import {Box, Grid, Skeleton} from '@mui/material';

const PackageDialogHeaderSkeleton = () => {
  return (
    <Box mt={2} mx={4} borderBottom="1px solid" borderColor="grey.300">
      <Skeleton variant="text" width={300} height={20} />
      <Skeleton variant="text" width={200} height={40} />
      <Skeleton variant="text" width={300} height={20} />
      <Grid container spacing={2} width={{md: '100%', lg: '75%'}} my={2} mb={4}>
        <Grid item xs={4} sm={4} md={4}>
          <Skeleton variant="text" width={150} height={20} />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Skeleton variant="text" width={150} height={20} />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Skeleton variant="text" width={150} height={20} />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Skeleton variant="text" width={120} height={20} />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Skeleton variant="text" width={120} height={20} />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Skeleton variant="text" width={120} height={20} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PackageDialogHeaderSkeleton;
