import {useMemo} from 'react';
import {useCommunitiesSettingsQuery} from '../../../generated/graphql';
import {CommunitySettings, mergeSettings} from '../CommunitySettings';
import {edgesToNodes} from '../../../utils/edgesToNodes';

export const useCommunitiesSettings = (communityIds: string[]) => {
  const {data, loading} = useCommunitiesSettingsQuery({
    variables: {
      communityIds,
    },
  });

  const mergedSettings = useMemo(() => {
    return mergeSettings(
      edgesToNodes(data?.communities?.edges).map(
        community => community.settings,
      ),
    );
  }, [data?.communities?.edges]);

  const communitySettingsById = useMemo(() => {
    return edgesToNodes(data?.communities?.edges).reduce(
      (acc, community) => {
        acc[community.id] = community.settings;
        return acc;
      },
      {} as Record<string, CommunitySettings>,
    );
  }, [data?.communities?.edges]);

  return {
    mergedSettings,
    communitySettingsById,
    loading,
  };
};
