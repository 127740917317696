import {colors} from '../theme/colors';

export const convertToGBP = (amount: number) => {
  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
  };

  if ((amount * 100.0) % 100 === 0) {
    options.minimumFractionDigits = 0;
  }

  return new Intl.NumberFormat('en-GB', options).format(amount);
};

export const statusToStyling = (status: string) => {
  if (
    status === 'CR' ||
    status === 'PF' ||
    status === 'PR' ||
    status === 'RA'
  ) {
    return {text: 'Pending', color: colors.cornflowerBlue};
  }
  if (status === 'SU') {
    return {text: 'Paid', color: colors.deYork};
  }
  if (status === 'CA') {
    return {text: 'Cancelled', color: colors.alto};
  }
  return {text: 'Unsuccessful', color: colors.carnation};
};
