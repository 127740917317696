import React from 'react';
import {CircleNotificationsRounded} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';
import {
  Box,
  Menu,
  SwipeableDrawer,
  Fab,
  IconButton,
  Badge,
  useTheme,
} from '@mui/material';
import {Close} from '@mui/icons-material';
import NotificationsMenuContent from './NotificationsMenuContent';
import {useUnreadNotificationsCountQuery} from '@/generated/graphql';
import {useSubscribeToNotifications} from '@/hooks/useInitializeNotifications';

//@ts-ignore
import ArkNotif from '@/assets/favicon-notifcation.ico';
//@ts-ignore
import Ark from '@/assets/favicon.ico';
import {useIsMobile} from '@/hooks/useIsMobile';

const useStyles = makeStyles(theme => ({
  notificationsIcon: {
    color: theme.palette.grey[300],
    width: 34,
    height: 34,
    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },
}));

const NotificationsMenu: React.FC = () => {
  const styles = useStyles();
  const {palette} = useTheme();
  const isMobile = useIsMobile();

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );
  const scrollRef = React.useRef<HTMLElement | null>(null);
  const mobileRef = React.useRef<HTMLElement | null>(null);

  const {data, refetch} = useUnreadNotificationsCountQuery();

  useSubscribeToNotifications(() => {
    refetch();
  });

  React.useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      //@ts-ignore
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    //@ts-ignore
    link.href = (data?.notifications?.totalCount || 0) > 0 ? ArkNotif : Ark;
  }, [data]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    isMobile
      ? setOpenDrawer(!openDrawer)
      : setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    isMobile ? setOpenDrawer(!openDrawer) : setAnchorElUser(null);
  };

  return (
    <Box>
      <Box>
        <IconButton
          sx={{'&:hover': {backgroundColor: palette.common.white}}}
          onClick={handleOpenUserMenu}
        >
          <Badge
            badgeContent={data?.notifications?.totalCount}
            color="secondary"
          >
            <CircleNotificationsRounded className={styles.notificationsIcon} />
          </Badge>
        </IconButton>
        <Menu
          sx={{mt: '36px'}}
          id="notifications-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
          PaperProps={{
            //@ts-ignore
            ref: scrollRef,
          }}
        >
          <NotificationsMenuContent
            close={handleCloseUserMenu}
            scrollRef={scrollRef}
          />
        </Menu>
      </Box>
      {isMobile && (
        <SwipeableDrawer
          onOpen={() => setOpenDrawer(true)}
          onClose={() => setOpenDrawer(false)}
          open={openDrawer}
          anchor="right"
          SlideProps={{
            ref: mobileRef,
          }}
        >
          <Fab
            sx={{
              position: 'fixed',
              top: 24,
              right: 16,
            }}
            color="primary"
            onClick={() => setOpenDrawer(false)}
          >
            <Close />
          </Fab>
          <NotificationsMenuContent
            close={handleCloseUserMenu}
            scrollRef={mobileRef}
          />
        </SwipeableDrawer>
      )}
    </Box>
  );
};

export default NotificationsMenu;
