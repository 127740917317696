import React, {useEffect} from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from '@mui/material';

import QRCode from 'react-qr-code';
import FormTextField from '@/components/forms/FormTextField';
import {useForm} from 'react-hook-form';
import {useMFA} from '@/hooks/useMFA';

const AddAppMFA = ({onSuccess}: {onSuccess: () => void}) => {
  const {palette} = useTheme();
  const [codeURI, setCodeURI] = React.useState('');

  const {addMFADevice, verifyMFADevice} = useMFA();

  const onLoad = async () => {
    const {data} = await addMFADevice('app')();
    if (data) {
      setCodeURI(data.details);
    }
  };

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {handleSubmit, control, setError} = useForm({
    defaultValues: {
      code: '',
    },
  });

  const onSubmit = React.useCallback(
    async ({code}: {code: string}) => {
      const {errors} = await verifyMFADevice('app')({code});
      if (errors) {
        //@ts-ignore
        if (errors.code) {
          //@ts-ignore
          setError('code', {message: errors.code});
        }
      } else {
        onSuccess();
      }
    },
    [setError, onSuccess, verifyMFADevice],
  );

  return (
    <>
      <DialogContent>
        <form id="app-setup" onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h3" textAlign="center" mb={2}>
            Get codes from an Authenticator App
          </Typography>
          <Typography variant="body1" mb={2} textAlign="center">
            Each time you log in, you’ll use an{' '}
            <span style={{color: palette.secondary.main}}>
              authenticator app
            </span>{' '}
            to generate a one-time password.
          </Typography>
          <Typography
            variant="body1"
            my={2}
            textAlign="center"
            fontWeight="bold"
          >
            Step 1: Scan the QR code below with your authenticator app.
          </Typography>
          <Box justifyContent="center" display="flex" mb={2}>
            <QRCode value={codeURI} size={180} />
          </Box>
          <Typography
            variant="body1"
            my={2}
            textAlign="center"
            fontWeight="bold"
          >
            Step 2: Enter the code you see in the authenticator app.
          </Typography>
          <FormTextField
            name="code"
            label="Confirmation Code"
            control={control}
            rules={{required: 'Code is required'}}
            inputProps={{maxLength: 6}}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="ark"
          fullWidth
          color="primary"
          form="app-setup"
          type="submit"
        >
          Confirm
        </Button>
      </DialogActions>
    </>
  );
};

export default AddAppMFA;
