import React from 'react';
import {Button, Menu, MenuItem, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Sort as SortIcon} from '@mui/icons-material';
import classNames from 'classnames';

export type SortOptions = {
  value: string;
  label: string;
}[];

export interface SortProps {
  options: SortOptions;
  selectedValue?: string;
  setSelectedValue: (val: string) => void;
  startText?: string;
  fullWidth?: boolean;
}

const useStyles = makeStyles(theme => ({
  sort: {
    padding: '6px 15px',
  },
  fullWidth: {
    width: '100%',
  },
  menuItem: {
    paddingTop: 4,
    paddingBottom: 4,
  },
}));

export const Sort: React.FC<SortProps> = (props: SortProps) => {
  const styles = useStyles();
  const [optionsAnchor, setOptionsAnchor] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOptionsAnchor(event.currentTarget);
  };

  const handleClose = (
    event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>,
  ) => {
    event.stopPropagation();
    setOptionsAnchor(null);
  };

  const valueToLabel = (val: string) =>
    props.options.find(option => option.value === val)?.label;

  const sortText = valueToLabel(props.selectedValue || '');

  return (
    <>
      <Button
        onClick={handleOptions}
        className={classNames(styles.sort, {
          [styles.fullWidth]: props.fullWidth,
        })}
        color="inherit"
        endIcon={<SortIcon fontSize="small" />}
      >
        <Typography variant="body1" lineHeight="1.9" noWrap marginRight="4px">
          {props.startText}
        </Typography>
        <Typography variant="subtitle1" lineHeight="1.75" noWrap>
          {sortText}
        </Typography>
      </Button>
      <Menu
        anchorEl={optionsAnchor}
        keepMounted
        open={Boolean(optionsAnchor)}
        onClose={handleClose}
      >
        {props.options.map(option => (
          <MenuItem
            key={option.value}
            className={styles.menuItem}
            onClick={(e: React.MouseEvent) => {
              props.setSelectedValue(option.value);
              handleClose(e as React.MouseEvent<HTMLLIElement>);
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
