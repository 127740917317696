import {PaletteMode, PaletteOptions, createTheme} from '@mui/material';
import {grey} from '@mui/material/colors';
import {colors} from './colors';
import {typography} from './typography';

declare module '@mui/material/styles' {
  interface Palette {
    pill: {
      yellow: string;
      pink: string;
      purple: string;
    };
  }
  interface PaletteOptions {
    pill: {
      yellow: string;
      pink: string;
      purple: string;
    };
  }
  interface PaletteColor {
    faded?: string;
  }
  interface SimplePaletteColorOptions {
    faded?: string;
  }
  interface TypeText {
    alternative: string;
  }
  interface CommonColors {
    slate: string;
  }
}

const pill = {
  yellow: colors.selectiveYellow,
  pink: colors.pink,
  purple: colors.fadedPurple,
};

const text = {
  alternative: colors.black,
};

const common = {
  slate: colors.slate,
};

const light: PaletteOptions = {
  primary: {
    light: colors.twitterBlue,
    main: colors.internationalKleinBlue,
  },
  secondary: {
    main: colors.brick,
  },
  success: {
    main: colors.caribbeanGreen,
  },
  warning: {
    light: colors.saffron,
    main: colors.pizazz,
  },
  action: {
    active: colors.black,
    hover: grey[200],
  },
  error: {
    main: colors.carnation,
  },
  background: {
    default: colors.white,
    paper: grey[50],
  },
  info: {
    main: colors.twitterBlue,
    faded: colors.fadedBlue,
  },
  pill,
  text,
  common,
};

const dark: PaletteOptions = {
  primary: {
    main: colors.brick,
  },
  secondary: {
    main: colors.white,
  },
  success: {
    main: colors.caribbeanGreen,
    dark: colors.japaneseLaurel,
  },
  warning: {
    main: colors.pizazz,
  },
  action: {
    active: colors.black,
    hover: '#1e1e1e',
  },
  error: {
    main: colors.carnation,
  },
  background: {
    default: '#2e2e2e',
    paper: '#121212',
  },
  grey: {
    '100': '#212121',
    '200': '#424242',
    '300': '#616161',
    '400': '#bdbdbd',
    '600': '#e0e0e0',
    '700': '#eeeeee',
    '800': '#f5f5f5',
    '900': '#fafafa',
  },
  pill,
};

const Palette = (mode: PaletteMode) => {
  const palette = mode === 'dark' ? dark : light;

  return createTheme({
    palette: {
      mode,
      ...palette,
    },
    typography,
    breakpoints: {},
  });
};

export default Palette;
