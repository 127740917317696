import {setUserId as setAmplitudeUser} from '@amplitude/analytics-browser';
import {setUser as setSentryUser} from '@sentry/react';
import ReactGA from 'react-ga4';
import {useEffect, useMemo} from 'react';

export const useSetUserTrackingEmail = (email?: string | undefined) => {
  const setUserTrackingEmail = useMemo(
    () => (email: string) => {
      setAmplitudeUser(email);
      setSentryUser({
        email,
      });
      ReactGA.set({user_id: email});
    },
    [],
  );

  useEffect(() => {
    if (email) {
      setUserTrackingEmail(email);
    }
  }, [email, setUserTrackingEmail]);

  return {setUserTrackingEmail};
};
