import React from 'react';
import {SidebarItemBadge} from '../SidebarItemBadge';
import {useUnansweredMessagesQuery} from '@/generated/graphql';
import {useSelectedCommunities, useUserProfile} from 'common';
import {useSubscribeToNotifications} from '@/hooks/useInitializeNotifications';

type Props = {
  children: React.ReactNode;
};

export const MessagesBadge = (props: Props) => {
  const communities = useSelectedCommunities();
  const {id: userId} = useUserProfile();

  const {data, refetch} = useUnansweredMessagesQuery({
    variables: {
      communities,
      assignedTo: `${userId},Unassigned`,
    },
    skip: !userId,
    pollInterval: 60_000,
  });

  useSubscribeToNotifications(notification => {
    if (
      notification?.target?.__typename === 'ConversationNode' ||
      notification?.target?.__typename === 'ConversationAssignmentNode'
    ) {
      refetch();
    }
  }, 'MessagesBadge');

  return (
    <SidebarItemBadge
      badgeContent={data?.conversations?.totalCount || 0}
      tooltipContent="Number of unanswered messages"
    >
      {props.children}
    </SidebarItemBadge>
  );
};
