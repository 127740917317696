import {Box, Skeleton, Typography} from '@mui/material';
import {ChevronRight} from '@mui/icons-material';
import {Fragment} from 'react';

type Label = string | null | undefined;

export type Breadcrumb = Label | {label: Label; onClick: () => void};

interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
}

export const Breadcrumbs = ({breadcrumbs}: BreadcrumbsProps) => {
  return (
    <Box display="flex" alignItems="center" gap={1} mb={2} color="grey.500">
      {breadcrumbs.map((breadcrumb, index) => {
        const label = breadcrumbToLabel(breadcrumb);
        const onClick =
          breadcrumb && typeof breadcrumb === 'object'
            ? breadcrumb.onClick
            : undefined;

        return (
          <Fragment key={label}>
            <Box onClick={onClick} sx={onClick && {cursor: 'pointer'}}>
              <Typography
                variant="subtitle1"
                sx={onClick && {':hover': {textDecoration: 'underline'}}}
              >
                {label ? label : <Skeleton width={90} />}
              </Typography>
            </Box>
            {index !== breadcrumbs.length - 1 && <ChevronRight />}
          </Fragment>
        );
      })}
    </Box>
  );
};

const breadcrumbToLabel = (breadcrumb: Breadcrumb) => {
  if (typeof breadcrumb === 'string') {
    return breadcrumb;
  }
  if (breadcrumb === null || breadcrumb === undefined) {
    return null;
  }
  return breadcrumb.label;
};
