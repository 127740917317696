import React from 'react';
import {communityContext} from '../../contexts/Community';
import {useCommunitySelectQuery} from '../../generated/graphql';
import {SearchSelect, SearchSelectValue} from '@ark/web';
import {useCommunitiesSettings, type CommunitySetting} from '@ark/react';
import {edgesToNodes} from '../../utils/edgesToNodes';

export interface CommunitySelectProps {
  value: SearchSelectValue;
  fullWidth?: boolean;
  onChange: (value: SearchSelectValue) => void;
  disabled?: boolean;
  error?: boolean;
  multiple?: boolean;
  showAll?: boolean;
  disabledCommunityIds?: string[];
  settings?: CommunitySetting[];
  disabledTooltip?: string;
}

export const CommunitySelect = (props: CommunitySelectProps) => {
  const {communities} = React.useContext(communityContext);
  const {data} = useCommunitySelectQuery();
  const {communitySettingsById} = useCommunitiesSettings(
    communities?.map(x => x.id) || [],
  );

  const relevantCommunities = React.useMemo(() => {
    if (props.showAll) {
      return edgesToNodes(data?.communities?.edges);
    } else {
      return communities;
    }
  }, [data, communities, props.showAll]);

  const options = React.useMemo(() => {
    return relevantCommunities?.map(community => ({
      value: community.id,
      label: community.name as string,
    }));
  }, [relevantCommunities]);

  //Here we disable the community select if there is only one community
  //and we trigger any onChange function given
  const value = React.useMemo(() => {
    if (
      (!props.value || props.value.length === 0) &&
      relevantCommunities?.length === 1
    ) {
      const fixedValue = [
        {
          value: relevantCommunities[0].id,
          label: relevantCommunities[0].name as string,
        },
      ];

      props.onChange(fixedValue);
      return fixedValue;
    }
    return props.value;
  }, [relevantCommunities, props]);

  return (
    <SearchSelect
      filterSelectedOptions
      error={props.error}
      options={options || []}
      value={value}
      sx={!props.fullWidth ? {maxWidth: 256} : undefined}
      label="Community"
      setValue={props.onChange}
      multiple={props.multiple !== false}
      disabled={props.disabled || options?.length === 1}
      fullWidth={props.fullWidth}
      getOptionDisabled={option => {
        if (props.disabledCommunityIds) {
          if (props.disabledCommunityIds.includes(option.value)) {
            return true;
          }
        }
        // Disable the option if the community does not have the required settings
        if (props.settings) {
          for (const setting of props.settings) {
            if (
              !communitySettingsById[option.value] ||
              !communitySettingsById[option.value][setting]
            ) {
              return true;
            }
          }
        }
        if (relevantCommunities && !props.showAll) {
          return !relevantCommunities.find(x => x.id === option.value);
        }
        return false;
      }}
      disabledTooltip={props.disabledTooltip}
    />
  );
};
