import FormSelect from '@/components/forms/FormSelect';
import {useCreateApplicantAndApplicationMutation} from '@/generated/graphql';
import {useLogEvent} from '@/hooks/useLogEvent/useLogEvent';
import {
  PackageCollectorsForHomeDocument,
  PackageCollectorsForPackageDocument,
} from '@ark/react';
import {LoadingButton} from '@mui/lab';
import {DialogContent, Typography} from '@mui/material';
import {DialogBack, FormEmailField, FormTextField} from 'common';
import {useForm} from 'react-hook-form';

enum OwnershipType {
  Owned = 'OW',
  Renting = 'RE',
  'Shared Ownership' = 'SO',
}

const ownershipOptions = [
  {label: 'Owned', value: OwnershipType.Owned},
  {label: 'Renting', value: OwnershipType.Renting},
  {label: 'Shared Ownership', value: OwnershipType['Shared Ownership']},
];

type FormValues = {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  email: string;
  ownershipType: OwnershipType | null;
};

const defaultValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  ownershipType: null,
};

type Props = {
  onBack: () => void;
  onComplete: (applicant: {id: string; user: {id: string}}) => void;
  homeId: string;
  communityId: string;
};

export const AddApplicant = ({
  onBack,
  onComplete,
  homeId,
  communityId,
}: Props) => {
  const logEvent = useLogEvent();

  const {control, handleSubmit} = useForm<FormValues>({
    defaultValues,
  });

  const [createApplicantAndApplication] =
    useCreateApplicantAndApplicationMutation();

  const onSubmit = handleSubmit(async values => {
    const response = await createApplicantAndApplication({
      variables: {
        input: {
          home: homeId,
          community: communityId,
          ...values,
          ownershipType: values.ownershipType || '',
        },
      },
      refetchQueries: [
        PackageCollectorsForHomeDocument,
        PackageCollectorsForPackageDocument,
      ],
    });

    const applicant = response?.data?.createApplicantAndApplication?.applicant;

    applicant && onComplete(applicant);
    logEvent({name: 'View Package Dialog - Collection Flow - Applicant Added'});
  });

  return (
    <>
      <DialogBack onClick={onBack} />
      <DialogContent sx={{mx: {sm: 2}}}>
        <Typography variant="h3" textAlign="center" my={2}>
          Add Resident
        </Typography>
        <Typography mb={3} textAlign="center" color="textSecondary">
          Add any personal details for the resident that you may have. They will
          then be verified by a manager.
        </Typography>
        <form onSubmit={onSubmit} id="add-applicant-form">
          <FormTextField
            name="firstName"
            label="First Name"
            control={control}
            required
          />
          <FormTextField
            name="lastName"
            label="Last Name"
            control={control}
            required
          />
          <FormEmailField
            control={control}
            name="email"
            label="Email"
            rules={{required: 'This is required'}}
          />
          <FormTextField
            control={control}
            name="phoneNumber"
            label="Phone Number"
          />
          <FormSelect
            label="Ownership Type"
            name="ownershipType"
            control={control}
            options={ownershipOptions}
            sx={{mb: 4}}
          />
        </form>
        <LoadingButton
          color="secondary"
          variant="contained"
          fullWidth
          type="submit"
          form="add-applicant-form"
        >
          Add
        </LoadingButton>
      </DialogContent>
    </>
  );
};
