import {Box, BoxProps, Fade} from '@mui/material';
import {merge} from 'lodash';

export const StepContent = (props: BoxProps) => {
  const overridableProps: BoxProps = {
    display: 'flex',
    flexDirection: 'column',
    mx: {
      xs: 4,
      sm: 8,
    },
    mt: 1,
    mb: 4,
    gap: 3,
    alignItems: 'center',
    textAlign: 'center',
  };
  return (
    <Fade in={true} timeout={1000}>
      <Box {...props} sx={merge(overridableProps, props.sx)} />
    </Fade>
  );
};
