import {Slide} from '@mui/material';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import React, {ReactNode} from 'react';
import ReactGA from 'react-ga4';
import {useLocation, useNavigate} from 'react-router-dom';
import SideBarAccordion, {CategoryItem} from './SideBarAccordion';
import SidebarItem from './SidebarItem';
import {GenericSidebarItemBadgeComponent} from '@/components/SidebarItemBadges/SidebarItemBadge';

const useSideBarStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

export type SideBarItem = {
  label: string;
  icon: ReactNode;
  url: string;
  BadgeComponent?: GenericSidebarItemBadgeComponent;
};

export type UserSideBarContentProps = {
  onClose?: () => void;
  children?: React.ReactNode;
};

type SideBarContentProps = {
  items: (SideBarItem | CategoryItem)[];
} & UserSideBarContentProps;

const SideBarContent = (props: SideBarContentProps) => {
  const styles = useSideBarStyles();
  const navigate = useNavigate();
  const {pathname} = useLocation();

  return (
    <Slide direction="right" in={true} timeout={300}>
      <Box maxWidth="190px">
        <Box className={styles.container}>
          {props.items.map(item => {
            if ('url' in item) {
              return (
                <SidebarItem
                  key={item.label}
                  selected={pathname.startsWith(item.url)}
                  label={item.label}
                  icon={item.icon}
                  handleClick={() => {
                    ReactGA.event({
                      category: 'test_category',
                      action: 'sidebar_item_click',
                    });
                    navigate(item.url);
                    props.onClose?.();
                  }}
                  BadgeComponent={item.BadgeComponent}
                />
              );
            } else if (item.items && item.items.length > 0) {
              return <SideBarAccordion key={item.category} {...item} />;
            }
          })}
          {props.children}
        </Box>
      </Box>
    </Slide>
  );
};

export default SideBarContent;
