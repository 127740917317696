import {TextField, TextFieldProps} from '@mui/material';
import {Controller, FieldPath, FieldValues} from 'react-hook-form';

import {UseControllerProps} from 'react-hook-form';

type FormEmailFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TextFieldProps & {
  control: UseControllerProps<TFieldValues, TName>['control'];
  name: UseControllerProps<TFieldValues, TName>['name'];
  rules?: UseControllerProps<TFieldValues, TName>['rules'];
};

export function FormEmailField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: FormEmailFieldProps<TFieldValues, TName>) {
  const {control, name, rules, ...rest} = props;
  return (
    <Controller
      render={({field, fieldState: {error}}) => {
        return (
          <TextField
            error={!!error}
            helperText={error?.message}
            variant="outlined"
            fullWidth
            type="email"
            {...field}
            {...rest}
          />
        );
      }}
      rules={{
        pattern: {
          value: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/,
          message: 'Invalid email address',
        },
        ...rules,
      }}
      control={control}
      name={name}
      shouldUnregister
    />
  );
}
