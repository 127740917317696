import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';

export type AuthContext = {
  authToken: null | string;
  setAuthToken: (token: string | null) => void;
  isLoggedIn?: boolean;
  logout: () => void;
  loading: boolean;
  loginAttempted: boolean;
};

export const AuthContext = createContext<AuthContext>({
  authToken: null,
  setAuthToken: () => {},
  isLoggedIn: false,
  logout: () => {},
  loading: true,
  loginAttempted: false,
});

interface StorageHandler {
  getItem(key: string): Promise<string | null> | string | null;
  setItem(key: string, value: string | null): Promise<void> | void;
  removeItem(key: string): Promise<void> | void;
}

interface AuthProviderProps {
  storageHandler: StorageHandler;
  children: ReactNode;
}
export const AuthProvider = (props: AuthProviderProps) => {
  const [loginAttempted, setLoginAttempted] = useState(false);
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const doAsync = async () => {
      try {
        const savedAuthToken = await props.storageHandler.getItem('userToken');
        savedAuthToken && setAuthToken(savedAuthToken);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    doAsync();
    // Don't put storage handler in the dependency array because it seems to change on every render and causes the userToken to be persisted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const persistAuthToken = useCallback(async (token: string | null) => {
    await props.storageHandler.setItem('userToken', token);
    setAuthToken(token);
    setLoginAttempted(true);
    // Don't put storage handler in the dependency array because it seems to change on every render and causes the userToken to be persisted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = useCallback(async () => {
    await props.storageHandler.removeItem('userToken');
    await props.storageHandler.removeItem('currentCommunity');
    setLoginAttempted(false);
    setAuthToken(null);
    // Don't put storage handler in the dependency array because it seems to change on every render and causes the userToken to be persisted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authToken,
        setAuthToken: persistAuthToken,
        loading,
        logout,
        loginAttempted,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
