import Confirm from '@/components/Confirm';
import React from 'react';

export interface ConfirmModal {
  title: string;
  description?: string;
  open: boolean;
  confirmAction: () => void | Promise<void>;
  closeAction?: () => void;
  singleOption?: boolean;
}

interface ConfirmContext {
  confirmModal: null | ConfirmModal;
  setConfirmModal: (modal: ConfirmModal | null) => void;
  closeModal: () => void;
}

const confirmContext = React.createContext<ConfirmContext>({
  confirmModal: null,
  setConfirmModal: () => undefined,
  closeModal: () => undefined,
});

export const useConfirmModal = ({
  title,
  description,
  closeAction,
  singleOption,
}: {
  title: string;
  description?: string;
  closeAction?: () => void;
  singleOption?: boolean;
}) => {
  const {setConfirmModal} = React.useContext(confirmContext);

  return (handleConfirm: () => void | Promise<void>) => () => {
    setConfirmModal({
      title,
      description,
      closeAction,
      singleOption,
      open: true,
      confirmAction: handleConfirm,
    });
  };
};

type ConfirmProviderProps = {
  children: React.ReactNode;
};

export const ConfirmProvider = ({children}: ConfirmProviderProps) => {
  const [confirmModal, setConfirmModal] = React.useState<null | ConfirmModal>(
    null,
  );

  const closeModal = () => {
    confirmModal?.closeAction && confirmModal.closeAction();
    setConfirmModal(null);
  };

  return (
    <confirmContext.Provider
      value={{
        confirmModal,
        setConfirmModal,
        closeModal,
      }}
    >
      <Confirm />
      {children}
    </confirmContext.Provider>
  );
};

export default confirmContext;
