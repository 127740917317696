// @ts-nocheck
import {DateTime, DurationObjectUnits} from 'luxon';

export const timestampToDateString = (timestamp: string) => {
  const date = new Date(+timestamp * 1000);
  return date.toLocaleDateString('en-UK', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const formatDate = (date: Date) => {
  return date.toLocaleDateString('en-UK', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

const units: (keyof DurationObjectUnits)[] = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
];

export const timeAgo = (date: string) => {
  const dateTime = DateTime.fromISO(date);
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find(unit => diff.get(unit) !== 0) || 'second';

  const relativeFormatter =
    (Intl &&
      Intl.RelativeTimeFormat &&
      new Intl.RelativeTimeFormat('en', {
        numeric: 'auto',
      })) ||
    false;

  if (!relativeFormatter) {
    return '';
  }

  //@ts-ignore
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};
