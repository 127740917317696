import {DefaultTheme} from '@mui/styles';

export default function Tab(theme: DefaultTheme) {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
          fontSize: '16px',
          '&:not(.Mui-selected)': {
            color: theme.palette.text.secondary,
          },
          textTransform: 'none',
          fontWeight: 'bold',
          '@media (min-width: 600px)': {
            minWidth: 'initial',
          },
        },
        labelIcon: {
          minHeight: 'initial',
        },
        textColorInherit: {
          opacity: 1,
        },
      },
    },
  };
}
