import {useMemo} from 'react';
import {useUserCommunitySettingsQuery} from '../../../generated/graphql';
import {edgesToNodes} from '../../../utils/edgesToNodes';
import {CommunitySettings, mergeSettings} from '../CommunitySettings';

type UserType = 'manager' | 'concierge' | 'resident' | 'leaseholder';

export const useUserCommunitySettings = (userTypes: UserType[]) => {
  if (userTypes.length === 0) {
    throw new Error('User types must be provided');
  }
  const {data, loading} = useUserCommunitySettingsQuery();

  const user = data?.getUser;
  const mergedSettings = useMemo(() => {
    let settings: CommunitySettings[] = [];
    if (userTypes.includes('manager')) {
      settings = edgesToNodes(user?.manager?.communities?.edges).map(
        community => community.settings,
      );
    }
    if (userTypes.includes('concierge')) {
      settings = [
        ...settings,
        ...edgesToNodes(user?.concierge?.communities?.edges).map(
          community => community.settings,
        ),
      ];
    }
    if (userTypes.includes('resident')) {
      for (const residency of user?.resident?.currentResidencies ?? []) {
        const residencySettings = residency.home?.community?.settings;
        if (residencySettings) {
          settings.push(residencySettings);
        }
      }
    }
    if (userTypes.includes('leaseholder')) {
      for (const leasehold of edgesToNodes(
        user?.leaseholder?.leaseholds.edges,
      )) {
        const leaseholdSettings = leasehold.home?.community?.settings;
        if (leaseholdSettings) {
          settings.push(leaseholdSettings);
        }
      }
    }

    return mergeSettings(settings);
  }, [
    user?.concierge?.communities?.edges,
    user?.leaseholder?.leaseholds.edges,
    user?.manager?.communities?.edges,
    user?.resident?.currentResidencies,
    userTypes,
  ]);

  return {mergedSettings, loading};
};
