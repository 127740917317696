import {AVATARCOLORS} from './colors';

export const stringToColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const index = Math.abs(hash) % 5;
  return AVATARCOLORS[index];
};

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children:
      name.indexOf(' ') === -1
        ? name[0].toUpperCase()
        : `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`.toUpperCase(),
  };
};
