import SideDialog from '@/components/dialogs/SideDialog/SideDialog';
import {
  PackageDocument,
  usePackageQuery,
  useRemindResidentMutation,
} from '@/generated/graphql';
import {track} from '@amplitude/analytics-browser';
import {UndoRounded} from '@mui/icons-material';
import {Alert, Box, Button, DialogActions, DialogContent} from '@mui/material';
import {DateTime} from 'luxon';
import React, {useState} from 'react';
import {OldDialogActionBar} from '@/components/dialogs/SideDialog/OldDialogActionBar';
import {useLogEvent} from '@/hooks/useLogEvent/useLogEvent';
import {HorizontalCenterCircularProgress} from 'common';
import {CollectionDialog} from './CollectionDialog/CollectionDialog';
import PackageDialogHeader from './PackageDialogHeader';
import PackageTimeline from './PackageTimeline';

export interface PackageDialogProps {
  id: string | undefined;
  onClose: () => void;
}

const PackageDialog = ({id, onClose}: PackageDialogProps) => {
  const logEvent = useLogEvent();
  const [reminded, setReminded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [openCollectionDialog, setOpenCollectionDialog] = useState(false);
  const [remindResident, {loading: reminding}] = useRemindResidentMutation({
    refetchQueries: [PackageDocument],
  });

  const {data, loading} = usePackageQuery({
    variables: {
      id: id!,
    },
    skip: !id,
  });

  const reminders =
    data?.package?.reminders?.map(reminder => {
      return {
        id: reminder.id,
        createdAt: `${DateTime.fromISO(reminder.createdAt).toFormat(
          'hh:mm a - dd/MM/yyyy',
        )}`,
        name: reminder.user.name,
      };
    }) || [];

  const handleRemindResident = async () => {
    try {
      setErrorMessage(null);
      setReminded(true);
      await remindResident({
        variables: {
          input: {
            id,
          },
        },
      });
      logEvent({
        name: 'View Package Dialog - Remind Resident Button Clicked',
      });
    } catch (e) {
      setErrorMessage('Network Error. Please try again.');
      setReminded(false);
      return;
    }
  };

  const handleClose = () => {
    onClose();
    setErrorMessage(null);
    setReminded(false);
    track('Close Package Details', {
      id: data?.package?.id,
      home: data?.package?.home?.id,
    });
  };

  return (
    <SideDialog open={!!id && id !== 'new'} onClose={handleClose}>
      <OldDialogActionBar
        onClose={handleClose}
        Action={
          data?.package?.status !== 'CO' && !reminded && !loading
            ? {
                label: 'Remind resident',
                onAction: () => {
                  handleRemindResident();
                },
                Icon: UndoRounded,
              }
            : undefined
        }
      />
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      {id && (
        <PackageDialogHeader
          id={id}
          addressee={data?.package?.addressee || 'Package'}
          address={`${data?.package?.home?.name}, ${data?.package?.home?.community?.name}, ${data?.package?.home?.postcode}`}
          communityName={`${data?.package?.home?.community?.name}`}
          collected={data?.package?.status === 'CO'}
          loading={loading}
          location={data?.package?.location ?? undefined}
        />
      )}
      <DialogContent>
        {(loading || reminding) && (
          <Box mt={2}>
            <HorizontalCenterCircularProgress />
          </Box>
        )}
        {data?.package && (
          <PackageTimeline
            scannedInOn={`${DateTime.fromISO(data?.package?.createdAt).toFormat(
              'hh:mm a - dd/MM/yyyy',
            )}`}
            scannedInBy={data?.package?.scannedInBy?.name || ''}
            imageUrl={data?.package?.image}
            reminders={reminders}
            collectedOn={
              data?.package?.collectedOn
                ? `${DateTime.fromISO(data.package.collectedOn).toFormat(
                    'hh:mm a - dd/MM/yyyy',
                  )}`
                : undefined
            }
            collectedBy={data?.package?.collectedBy?.name}
          />
        )}
      </DialogContent>
      <DialogActions>
        {data?.package?.status === 'DE' && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenCollectionDialog(true)}
          >
            Collection
          </Button>
        )}
      </DialogActions>
      <CollectionDialog
        open={openCollectionDialog}
        onClose={() => {
          setOpenCollectionDialog(false);
          logEvent({
            name: 'View Package Dialog - Collection Button Clicked',
          });
        }}
      />
    </SideDialog>
  );
};

export default PackageDialog;
