import {
  SidebarCategory,
  useSideBarAccordion,
} from '@/contexts/SideBarAccordionContext';
import {KeyboardArrowDown} from '@mui/icons-material';
import {Collapse} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {capitalize} from 'lodash';
import {ReactNode, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import SideBarContent, {SideBarItem} from './SideBarContent';
import SidebarItem from './SidebarItem';

const useSideBarStyles = makeStyles(theme => ({
  dropdown: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short,
    }),
    marginLeft: theme.spacing(1),
  },
  dropdownOpen: {
    transform: 'rotate(-180deg)',
  },
  dropdownClosed: {
    transform: 'rotate(0)',
  },
}));

export interface CategoryItem {
  category: SidebarCategory;
  icon: ReactNode;
  items: SideBarItem[];
}

const SideBarAccordion = (props: CategoryItem) => {
  const {pathname} = useLocation();
  const isSelected = !!props.items.find(item => pathname.startsWith(item.url));
  const {toggleOpen, isOpen} = useSideBarAccordion();

  const styles = useSideBarStyles();

  useEffect(() => {
    if (isSelected && !isOpen(props.category)) {
      toggleOpen(props.category);
    }
  }, [isSelected]);

  return (
    <>
      <SidebarItem
        label={capitalize(props.category)}
        icon={props.icon}
        handleClick={() => {
          toggleOpen(props.category);
        }}
        selected={isSelected}
        isAccordion
      >
        <KeyboardArrowDown
          className={`${styles.dropdown} ${
            isOpen(props.category) ? styles.dropdownOpen : styles.dropdownClosed
          }`}
        />
      </SidebarItem>
      <Collapse in={isOpen(props.category)} sx={{ml: 2}}>
        <SideBarContent items={props.items} />
      </Collapse>
    </>
  );
};

export default SideBarAccordion;
