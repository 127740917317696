import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {
  offsetLimitPagination,
  relayStylePagination,
} from '@apollo/client/utilities';
import * as Sentry from '@sentry/react';
import {UserProvider, userContext} from 'common';
import {useContext, useEffect, useMemo, useState} from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import {IntercomProvider} from 'react-use-intercom';
import {ClarityProvider} from 'use-clarity';
import App from './App';
import SnackbarComponent from './components/Snackbar';
import * as serviceWorker from './serviceWorker';

const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_ID;
const CLARITY_ID = import.meta.env.VITE_CLARITY_ID;

const mergeIfSameUrl = (existing: string, incoming: string) => {
  if (existing?.split('?')[0] === incoming?.split('?')[0]) {
    return existing;
  }
  return incoming;
};

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        listConversations: offsetLimitPagination([
          'communityId',
          'urgency',
          'managerId',
          'unresolved',
          'residentId',
          'sortBy',
          'assignedTo',
          'unanswered',
        ]),
        conversations: relayStylePagination([
          'community',
          'manager',
          'search',
          'unresolved',
          'resident',
          'isUrgent',
          'orderBy',
          'hasResponseTime',
          'communities',
          'status',
          'date',
          'home',
          'contact',
        ]),
        residents: relayStylePagination([
          'community',
          'manager',
          'search',
          'with_rating',
          'downloaded',
          'orderBy',
          'communities',
        ]),
        applications: relayStylePagination([
          'communities',
          'manager',
          'search',
          'orderBy',
          'status',
        ]),
        packages: relayStylePagination([
          'communities',
          'manager',
          'status',
          'search',
          'fullTextSearch',
          'orderBy',
        ]),
        keys: relayStylePagination([
          'communities',
          'search',
          'hasCurrentKeyholder',
          'status',
          'orderBy',
          'type',
        ]),
        residencies: relayStylePagination(['home']),
        complianceRequirements: relayStylePagination([
          'orderBy',
          'status',
          'search',
        ]),
        communities: relayStylePagination([
          'orderBy',
          'status',
          'search',
          'manager',
          'isManager',
          'ids',
        ]),
        communityProducts: relayStylePagination([
          'communities',
          'active',
          'orderBy',
        ]),
        homes: relayStylePagination([
          'communities',
          'icontainsSearch',
          'trigramSearch',
          'contact',
          'community',
        ]),
        communityUpdates: relayStylePagination(['communities', 'search']),
        clientUsers: relayStylePagination(['search', 'communities']),
        surveys: relayStylePagination(['communities']),
        notifications: relayStylePagination(['read', 'platform']),
        contacts: relayStylePagination([
          'search',
          'community',
          'communities',
          'home',
          'downloaded',
          'orderBy',
        ]),
        communityDocuments: relayStylePagination(['communities']),
        communityEvents: relayStylePagination(['communities', 'orderBy']),
        leaseholderPayments: relayStylePagination(['search', 'communities']),
        invoices: relayStylePagination(['type']),
        amenities: relayStylePagination(['communities']),
        amenityReservations: relayStylePagination([
          'amenity',
          'toDate',
          'fromDate',
        ]),
        communityGroups: relayStylePagination([
          'search',
          'communities',
          'orderBy',
        ]),
        tenantReferences: relayStylePagination(['hasBeenSent']),
        tenancyApplications: relayStylePagination([
          'search',
          'status',
          'communities',
          'hasBeenSent',
        ]),
        communityVisitors: relayStylePagination(['search', 'communities']),
        documents: relayStylePagination(['search', 'communities', 'home']),
        marketplaceItems: relayStylePagination([
          'search',
          'communities',
          'status',
        ]),
        blocks: relayStylePagination([
          'search',
          'communities',
          'hasRequirements',
        ]),
        blockComplianceRequirements: relayStylePagination([
          'search',
          'blocks',
          'status',
        ]),
      },
    },
    MarketplaceItemNode: {
      fields: {
        comments: relayStylePagination([]),
      },
    },
    CommunityProductNode: {
      fields: {
        purchases: relayStylePagination([]),
      },
    },
    KeyNode: {
      fields: {
        keyholders: relayStylePagination(['isActive']),
        events: relayStylePagination([]),
      },
    },
    CommunityUpdateType: {
      fields: {
        comments: relayStylePagination([]),
      },
    },
    ApplicationNode: {
      fields: {
        applicationAttempts: relayStylePagination([]),
      },
    },
    CommunityNode: {
      fields: {
        team: relayStylePagination([]),
        communityGroups: relayStylePagination(['orderBy']),
      },
    },
    CommunityGroupNode: {
      fields: {
        users: relayStylePagination([]),
        homes: relayStylePagination([]),
      },
    },
    CommunityEventNode: {
      fields: {
        guests: relayStylePagination([]),
      },
    },
    ResidencyDocumentNode: {
      fields: {
        views: relayStylePagination([]),
        url: {
          merge: mergeIfSameUrl,
        },
        file: {
          merge: mergeIfSameUrl,
        },
      },
    },
    CommunityDocumentNode: {
      fields: {
        views: relayStylePagination([]),
        url: {
          merge: mergeIfSameUrl,
        },
        file: {
          merge: mergeIfSameUrl,
        },
      },
    },
    HomeNode: {
      fields: {
        packages: relayStylePagination(['status']),
      },
    },
  },
});

const apiUrl = `${import.meta.env.VITE_API_ROOT}/api/graphql`;
const asyncUrl = `${import.meta.env.VITE_API_ROOT}/api/graphql-async`;
const publicApiUrl = `${apiUrl}/public`;

type Props = {
  children: React.ReactNode;
};

export const createApolloClient = (
  authToken: string | null,
  onGQLError?: () => void,
) => {
  let uri;
  uri = !authToken ? publicApiUrl : apiUrl;

  if (import.meta.env.VITE_USE_ASYNC === '1' && authToken) {
    uri = asyncUrl;
  }
  const headers = authToken
    ? {
        Authorization: `Token ${authToken}`,
      }
    : undefined;

  const httpLink = new HttpLink({
    uri,
    headers,
  });

  const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors || networkError) {
      onGQLError?.();
    }
  });

  return new ApolloClient({
    link: from([errorLink, httpLink]),
    cache,
    defaultOptions: {
      mutate: {
        awaitRefetchQueries: true,
      },
    },
    connectToDevTools: import.meta.env.VITE_ENVIRONMENT !== 'production',
  });
};

const AuthorizedApolloProvider = ({children}: Props) => {
  const {authToken} = useContext(userContext);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const client = useMemo(() => {
    return createApolloClient(authToken, () => setShowErrorSnackbar(true));
  }, [authToken, setShowErrorSnackbar]);

  return (
    <ApolloProvider client={client}>
      {children}
      <SnackbarComponent
        open={showErrorSnackbar}
        setClosed={() => setShowErrorSnackbar(false)}
        message="Something went wrong. Please try again."
        severity="error"
      />
    </ApolloProvider>
  );
};

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    tracesSampleRate:
      import.meta.env.VITE_ENVIRONMENT === 'production' ? 0.01 : 1,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    environment: import.meta.env.VITE_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [apiUrl, publicApiUrl],
      }),
    ],
    maxValueLength: 10000,
    ignoreErrors: [/Loading chunk [\d]+ failed/],
    replaysOnErrorSampleRate: 1,
    replaysSessionSampleRate: 0,
    tracePropagationTargets: [import.meta.env.VITE_API_ROOT],
  });
}
export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <UserProvider>
    <AuthorizedApolloProvider>
      <ClarityProvider clarityId={CLARITY_ID}>
        <IntercomProvider appId={INTERCOM_APP_ID || ''}>
          <App />
        </IntercomProvider>
      </ClarityProvider>
    </AuthorizedApolloProvider>
  </UserProvider>,
);

serviceWorker.unregister();
