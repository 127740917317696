import {Box} from '@mui/material';
import './styles.css';

export const ArkLoader = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Box width="15vh" height="15vw">
        <svg
          className={`animated-svg active active-2`}
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1876.000000 1714.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            className="svg-group"
            transform="translate(0.000000,1714.000000) scale(0.100000,-0.100000)"
            fill="#FB4824"
            stroke="#FB4824"
            strokeWidth="150"
          >
            <path
              d="M2821 8698 c-1546 -4637 -2809 -8433 -2807 -8435 1 -2 1403 -2 3115 -1 l3113 3 -98 76 c-675 521 -1237 1245 -1581 2039 -144 331 -269 740 -328 1075 -8 50 -18 100 -21 113 -4 21 -2 22 81 22 60 0 87 -4 90 -13 158 -469 344 -852 600 -1237 386 -581 860 -1055 1445 -1444 234 -156 353 -224 615 -352 727 -354 1512 -534 2335 -534 823 0 1608 180 2335 534 262 128 381 196 615 352 585 389 1059 863 1445 1444 256 385 442 768 600 1238 3 8 30 12 90 12 83 0 85 -1 81 -22 -3 -13 -13 -63 -21 -113 -59 -335 -184 -745 -328 -1075 -345 -795 -905 -1518 -1581 -2039 l-98 -76 3113 -3 c1712 -1 3114 -1 3115 1 2 2 -1261 3798 -2807 8435 l-2811 8432 -3748 0 -3748 0 -2811 -8432z m7351 1092 c411 -1609 747 -2931 747 -2937 1 -10 -317 -13 -1559 -13 -1057 0 -1560 3 -1560 10 0 10 1526 5831 1535 5858 3 9 19 12 47 10 l43 -3 747 -2925z"
              className="svg-elem-1"
            />
          </g>
        </svg>
      </Box>
    </Box>
  );
};
