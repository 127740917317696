import React from 'react';
import {Box, IconButton, Theme, Tooltip} from '@mui/material';
import {Delete, Edit, Add, Close} from '@mui/icons-material/';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  iconGroup: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  icon: {
    color: theme.palette.grey[600],
  },
}));

type Action = {
  onAction: () => void;
  Icon: React.ElementType;
  label?: string;
};

type DialogActionBarProps = {
  onClose: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  onAdd?: () => void;
  Action?: Action;
};

export const OldDialogActionBar = ({
  onClose,
  onDelete,
  onEdit,
  onAdd,
  Action,
}: DialogActionBarProps) => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Tooltip title="Close">
        <IconButton aria-label="Close" onClick={onClose} sx={{mr: 4}}>
          <Close className={styles.icon} />
        </IconButton>
      </Tooltip>
      <Box className={styles.iconGroup}>
        {onDelete && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" onClick={onDelete}>
              <Delete className={styles.icon} />
            </IconButton>
          </Tooltip>
        )}
        {onAdd && (
          <Tooltip title="Add new">
            <IconButton aria-label="Save" onClick={onAdd}>
              <Add className={styles.icon} />
            </IconButton>
          </Tooltip>
        )}
        {onEdit && (
          <Tooltip title="Edit">
            <IconButton aria-label="Edit" onClick={onEdit}>
              <Edit className={styles.icon} />
            </IconButton>
          </Tooltip>
        )}
        {Action && (
          <Tooltip title={Action.label || ''}>
            <IconButton aria-label="Action" onClick={Action.onAction}>
              <Action.Icon className={styles.icon} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};
