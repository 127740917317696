import {useLogEvent} from '@/hooks/useLogEvent/useLogEvent';
import {PropsWithChildren, useEffect} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

type Props = {
  parameterNames: string[];
};

const decode = (str: string) => {
  return atob(str);
};

const fromRelayId = (relayId: string) => {
  try {
    const [type, uuid] = decode(relayId).split(':');
    return {type, uuid};
  } catch (error) {
    const message = `Invalid relay id: '${relayId}'`;
    throw new Error(message);
  }
};

export const ParamsToUUIDNavigator = ({
  parameterNames,
  children,
}: PropsWithChildren<Props>) => {
  const logEvent = useLogEvent();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    let url = location.pathname;
    let shouldNavigate = false;

    parameterNames.forEach(parameterName => {
      const param = params[parameterName];
      if (
        param &&
        !param?.match(
          '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$',
        )
      ) {
        const uuid = fromRelayId(param).uuid;
        if (!uuid) return;

        logEvent({
          name: 'Relay Id found in URL',
          data: {url: location.pathname, parameterName},
        });

        url = url.replace(param, uuid);
        shouldNavigate = true;
      }
    });

    if (shouldNavigate) {
      navigate(url);
    }
  }, [location.pathname, navigate, params, parameterNames, logEvent]);

  return children;
};
