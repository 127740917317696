import React from 'react';

export interface ConfettiItem {
  numPops: 1 | 3;
}

interface ConfettiContext {
  confettiItem: null | ConfettiItem;
  setConfettiItem: (item: null | ConfettiItem) => void;
}

const confettiContext = React.createContext<ConfettiContext>({
  confettiItem: null,
  setConfettiItem: () => undefined,
});

type ConfettiProviderProps = {
  children: React.ReactNode;
};

export const ConfettiProvider = ({children}: ConfettiProviderProps) => {
  const [confettiItem, setConfettiItem] = React.useState<null | ConfettiItem>(
    null,
  );

  React.useEffect(() => {
    setTimeout(() => {
      if (confettiItem) {
        setConfettiItem(null);
      }
    }, 2500);
  }, [confettiItem]);

  return (
    <confettiContext.Provider
      value={{
        confettiItem,
        setConfettiItem,
      }}
    >
      {children}
    </confettiContext.Provider>
  );
};

export default confettiContext;
