import React from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';

type ResponsiveDialogCloseProps = {
  onClose: () => void;
};

export const ResponsiveDialogClose = ({
  onClose,
}: ResponsiveDialogCloseProps) => {
  return (
    <IconButton
      aria-label="close"
      sx={{position: 'absolute', top: 8, right: 8}}
      onClick={onClose}
      size="large"
    >
      <CloseRoundedIcon />
    </IconButton>
  );
};
