import {DefaultTheme} from '@mui/styles';
import Color from 'color';

export const arkButtonVariant = (theme: DefaultTheme) => {
  return {
    props: {variant: 'ark'},
    style: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&.Mui-disabled': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.light,
      },
      '&.MuiLoadingButton-loading .MuiLoadingButton-loadingIndicator': {
        color: theme.palette.common.white,
      },
    },
  };
};

export const arkOutlinedButtonVariant = (theme: DefaultTheme) => {
  return {
    props: {variant: 'arkOutline'},
    style: {
      backgroundColor: theme.palette.common.white,

      color: theme.palette.secondary.main,
      border: '1px solid',
      borderColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: new Color(theme.palette.secondary.dark)
          .lightness(96)
          .hex(),
        color: theme.palette.secondary.dark,

        border: '1px solid',
        borderColor: theme.palette.secondary.dark,
      },
      '&.Mui-disabled': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.light,
      },
    },
  };
};

export const fadedButtonVariant = (theme: DefaultTheme) => {
  return {
    props: {variant: 'faded'},
    style: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.grey['400'],
      border: `1px solid ${theme.palette.grey['400']}`,
      '&:hover': {
        backgroundColor: theme.palette.grey['100'],
        color: theme.palette.grey['500'],
        border: `1px solid ${theme.palette.grey['500']}`,
      },
      '&.Mui-disabled': {
        color: theme.palette.grey['600'],
        backgroundColor: theme.palette.grey['100'],
        border: `1px solid ${theme.palette.grey['600']}`,
      },
    },
  };
};

export const buttonVariants = (theme: DefaultTheme) => {
  return [
    {
      props: {variant: 'dark'},
      style: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.grey[700],
          color: theme.palette.common.white,
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.grey[700],
        },
        '&.MuiLoadingButton-loading .MuiLoadingButton-loadingIndicator': {
          color: theme.palette.common.white,
        },
      },
    },
    arkButtonVariant(theme),
    arkOutlinedButtonVariant(theme),
    fadedButtonVariant(theme),
  ];
};

export const Button = (theme: DefaultTheme) => {
  return {
    MuiButton: {
      variants: buttonVariants(theme),
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
  };
};
