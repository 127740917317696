import {ModeSwitch} from '@/components/ModeSwitch';
import ColorModeContext from '@/contexts/colorModeContext';
import {useGetUserTypeQuery} from '@/generated/graphql';
import {useClientType} from '@/hooks/useClientType/useClientType';
import {useFeatures} from '@/hooks/useFeatures/useFeatures';
import SidebarItem from '@/layout/SideBar/SidebarItem';
import {useApolloClient} from '@apollo/client';
import {
  AccountCircle,
  Groups,
  InsertDriveFile,
  Logout,
  MailRounded,
  MenuBook,
  Policy,
  Settings,
} from '@mui/icons-material';
import {FormControlLabel, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import {useTheme} from '@mui/styles';
import {userContext} from 'common';
import React from 'react';
import ReactGA from 'react-ga4';
import {useLocation, useNavigate} from 'react-router-dom';

interface SideBarContentProps {
  onSelect: () => void;
}

const ProfileMenuContent = (props: SideBarContentProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const client = useApolloClient();

  const {signOut} = React.useContext(userContext);
  const {data} = useGetUserTypeQuery();
  const [{Tags, DarkMode}] = useFeatures();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const clientType = useClientType();
  const isAdmin = data?.getUser?.clientAdmin;
  const isBTL = clientType === 'BTL';
  const isEnterprise = clientType === 'ENT';

  return (
    <Box minWidth="124px">
      <Box sx={{p: isMobile ? 0 : '0 6px'}}>
        {(data?.getUser?.clientAdmin ||
          data?.getUser?.communityManager ||
          data?.getUser?.manager ||
          data?.getUser?.concierge) && (
          <SidebarItem
            selected={pathname.startsWith('/profile')}
            label="Profile"
            icon={<AccountCircle fontSize="small" />}
            handleClick={() => {
              props.onSelect();
              navigate('/profile');
            }}
          />
        )}
        {isAdmin && (
          <SidebarItem
            selected={pathname.startsWith('/members')}
            label="Members"
            icon={<Groups fontSize="small" />}
            handleClick={() => {
              props.onSelect();
              navigate('/members');
            }}
          />
        )}
        {isAdmin && Tags && (
          <SidebarItem
            selected={pathname.startsWith('/settings')}
            label="Settings"
            icon={<Settings fontSize="small" />}
            handleClick={() => {
              props.onSelect();
              navigate('/settings');
            }}
          />
        )}
        {isBTL && (
          <>
            <SidebarItem
              selected={false}
              label="Privacy Policy"
              icon={<Policy fontSize="small" />}
              handleClick={() => {
                props.onSelect();
                window.open(
                  'https://ark-public.s3.eu-west-1.amazonaws.com/Ark+-+Website+and+App+Privacy+Policy.pdf',
                );
              }}
            />
            <SidebarItem
              selected={false}
              label="Terms of Service"
              icon={<InsertDriveFile fontSize="small" />}
              handleClick={() => {
                props.onSelect();
                window.open(
                  'https://ark-public.s3.eu-west-1.amazonaws.com/Ark+-+BTL+Terms+%26+Conditions+July+2022.pdf',
                );
              }}
            />
          </>
        )}
        <SidebarItem
          selected={false}
          label="Support"
          icon={<MailRounded fontSize="small" />}
          handleClick={() => {
            props.onSelect();
            window.open(
              "mailto:noah@projectark.co.uk?subject=I'd like some help&body=Hi Noah,...",
            );
          }}
        />
        {isEnterprise && (
          <SidebarItem
            selected={false}
            label="User Guide"
            icon={<MenuBook fontSize="small" />}
            handleClick={() => {
              props.onSelect();
              window.open(
                'https://ark-rent.notion.site/ark-rent/Building-Better-Communities-User-Guide-4de3b241d35d45a2ba534d58ec49bd15',
              );
              ReactGA.event({
                category: 'Help request',
                action: 'Clicked support button',
              });
            }}
          />
        )}
        <SidebarItem
          selected={false}
          label="Log Out"
          icon={<Logout fontSize="small" />}
          handleClick={async () => {
            await client.clearStore();
            signOut();
          }}
        />
        {DarkMode && (
          <Box alignContent="center" justifyContent="center" ml={2}>
            <ColorModeContext.Consumer>
              {({toggleColorMode}) => (
                <FormControlLabel
                  onChange={toggleColorMode}
                  control={
                    <ModeSwitch
                      sx={{m: 1}}
                      checked={theme.palette.mode === 'dark' ? true : false}
                    />
                  }
                  label=""
                  labelPlacement="start"
                />
              )}
            </ColorModeContext.Consumer>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProfileMenuContent;
