import React from 'react';
import {CalendarToday} from '@mui/icons-material';
import {Box, Typography, useTheme} from '@mui/material';
import {timeAgo} from '../formatters/dates';
import {DateTime} from 'luxon';

export interface DateItemProps {
  date?: string;
  extraText?: string;
  color?: string;
  relative?: boolean;
  dateText?: string;
}

export const DateItem: React.FC<DateItemProps> = props => {
  const theme = useTheme();

  let dateText = props.dateText ?? '--';
  if (props.date) {
    dateText = props.relative
      ? timeAgo(props.date)
      : DateTime.fromISO(props.date).toFormat('dd/MM/yyyy');
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={0.5}
    >
      <CalendarToday
        sx={{
          fontSize: 16,
          color: props.color || theme.palette.text.secondary,
        }}
      />
      <Typography
        variant="body2"
        whiteSpace={'nowrap'}
        color={props.color || theme.palette.text.secondary}
      >
        {props.extraText}
        {dateText}
      </Typography>
    </Box>
  );
};
