export const calculateGridSize = (
  position: number,
  length: number,
  isMobile: boolean,
) => {
  if (isMobile) {
    if (length <= 2 || position === 0) {
      return length === 1 ? {rows: 2, columns: 4} : {rows: 1, columns: 4};
    } else return {rows: 1, columns: 2};
  } else {
    if (length === 1) {
      return {rows: 2, columns: 4};
    }
    if (length === 2) {
      return {rows: 2, columns: 2};
    }
    if (position === 0 && length === 3) {
      return {rows: 2, columns: 3};
    }
    if (position === 0 && length > 3) {
      return {rows: 2, columns: 2};
    }
    if (position === 3 && length === 4) {
      return {rows: 1, columns: 2};
    }
  }
  return {rows: 1, columns: 1};
};

export const calculateGridSizeEdit = (
  position: number,
  length: number,
  isMobile: boolean,
) => {
  if (isMobile) {
    if (position === 0) {
      return {rows: 1, columns: 4};
    }
    if (length >= 2) {
      return {rows: 1, columns: 2};
    }
  } else {
    if (position === 0 && length < 3) {
      return {rows: 2, columns: 3};
    }
    if (position === 0 && length >= 3) {
      return {rows: 2, columns: 2};
    }
  }
  return {rows: 1, columns: 1};
};

export const getEditButtonSize = (length: number, isMobile: boolean) => {
  if (isMobile) {
    if (length === 1) {
      return {rows: 1, columns: 4};
    }
    if (length >= 2) {
      return {rows: 1, columns: 2};
    }
  } else {
    if (length === 1) {
      return {rows: 2, columns: 1};
    }
    if (length === 2) {
      return {rows: 1, columns: 1};
    }
    if (length >= 3) {
      return {rows: 1, columns: 2};
    }
  }
};
