export const typography = {
  fontFamily: ['Onest', '-apple-system'].join(','),
  fontWeightBold: 600,
  fontWeightMedium: 400,
  fontWeightLight: 200,
  body2: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'left' as const,
  },
  body1: {
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '20px',
    textAlign: 'left' as const,
    fontWeight: 400,
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    fontStyle: 'normal',
    textAlign: 'left' as const,
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    fontStyle: 'normal',
    textAlign: 'left' as const,
  },
  h6: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '18px',
    textAlign: 'left' as const,
  },
  h5: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'left' as const,
  },
  h4: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    textAlign: 'left' as const,
  },
  h3: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'left' as const,
  },
  h2: {
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    textAlign: 'left' as const,
  },
  h1: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '29px',
  },
  title1: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: '34px',
  },
};
