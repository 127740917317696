import {track} from '@amplitude/analytics-browser';
import {useCallback} from 'react';
import {ConversationsFiltersEvents} from './ConversationsFiltersEvents';

export type BaseEvent<EventName, EventData = undefined> = {
  name: EventName;
  data: EventData;
};
export type SimpleEvent<EventName> = {
  name: EventName;
};

export type AmplitudeEvent = ConversationsFiltersEvents;

export const useLogEvent = () => {
  const logEvent = useCallback((event: AmplitudeEvent) => {
    const {name, data} = event as BaseEvent<string, unknown>;
    if (data) {
      track(name, data);
    } else {
      track(name);
    }
  }, []);
  return logEvent;
};
