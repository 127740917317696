import {camelCase, isEqual} from 'lodash';
import React, {useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

export const useSearchParams = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();

  const [state, setState] = React.useState<{
    [key: string]: string;
  }>({});

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const newState: {[key: string]: string} = {};
    searchParams.forEach(
      (value: string, key: string) =>
        (newState[camelCase(key)] = decodeURIComponent(value)),
    );

    if (!isEqual(state, newState)) {
      setState(newState);
    }
  }, [location.search]);

  const updateSearchParams = useCallback(
    (newParams: {[key: string]: string}) => {
      for (const [key, value] of Object.entries(newParams)) {
        state[key] = value;
      }
      const newUrl = `${pathname}?${new URLSearchParams(state).toString()}`;
      navigate(newUrl);
    },
    [state, pathname],
  );

  const navigateMaintainingSearchParams = useCallback(
    (newPath: string) => {
      const newUrl = `${newPath}?${new URLSearchParams(state).toString()}`;
      navigate(newUrl);
    },
    [state],
  );

  return {
    searchParams: state,
    updateSearchParams,
    navigateMaintainingSearchParams,
  };
};
