import Color from 'color';

const brick = '#FB4824';
const darkenedBrick = new Color(brick).darken(0.2).hex();

export const colors = {
  black: '#000',
  springWood: '#F5F0EB',
  lightBeige: '#FBF9F7',
  white: '#FFF',
  slate: '#4A3943',
  brick,
  fadedBrick: 'rgba(251, 72, 36, 0.6)',
  darkenedBrick,
  pizazz: '#FF8C00',
  fadedPurple: 'rgba(107, 85, 145, 0.6)',
  internationalKleinBlue: '#0033b6',
  fadedBlue: 'rgba(0,78,103, 0.6)',
  twitterBlue: '#1DA1F2',
  saltpan: '#EAF3E9',
  caribbeanGreen: '#00C08C',
  japaneseLaurel: '#038000',
  muddyWaters: '#B8846B',
  carnation: '#F25757',
  saffron: '#F3CA3E',
  pink: '#e59bb3',
  selectiveYellow: '#FBBC04',
};
