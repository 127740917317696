import {useLogEvent} from '@/hooks/useLogEvent/useLogEvent';
import {Box, ListItem, Typography} from '@mui/material';
import {DateTime} from 'luxon';
import {useNavigate, useParams} from 'react-router-dom';
import {LocationPill} from '../LocationPill';

type Props = {
  id: string;
  addressee: string;
  actionDateTime: DateTime | undefined;
  location?: string;
};

export const PackageItem = (props: Props) => {
  const logEvent = useLogEvent();
  const navigate = useNavigate();
  const {homeId} = useParams();

  return (
    <ListItem
      sx={theme => ({
        backgroundColor: theme.palette.background.default,
        borderRadius: 1,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        border: `solid 1px transparent`,
        '&:hover': {
          borderColor: theme.palette.grey[300],
        },
      })}
      onClick={() => {
        navigate(`/homes/${homeId}/packages/${props.id}`, {
          state: {from: '/packages'},
        });
        logEvent({name: 'Packages On Home Page - Package List Item Clicked'});
      }}
    >
      <Typography>{props.addressee}</Typography>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        gap={2}
        alignItems={'center'}
      >
        <Typography variant="body2">
          {props.actionDateTime?.toFormat('f')}
        </Typography>
        {props.location && <LocationPill location={props.location} />}
      </Box>
    </ListItem>
  );
};
