export type CommunitySettings = {
  newsfeed: boolean;
  messages: boolean;
  surveys: boolean;
  documents: boolean;
  events: boolean;
  facilities: boolean;
  marketplace: boolean;
  accounts: boolean;
  products: boolean;
  occupancySurvey: boolean;
  compliance: boolean;
};

export type CommunitySetting = keyof CommunitySettings;

export const mergeSettings = (
  settings: CommunitySettings[],
): CommunitySettings => {
  const mergedSettings: CommunitySettings = {
    newsfeed: false,
    messages: false,
    surveys: false,
    documents: false,
    events: false,
    facilities: false,
    marketplace: false,
    accounts: false,
    products: false,
    occupancySurvey: false,
    compliance: false,
  };

  for (const setting of settings) {
    for (const [key, value] of Object.entries(setting) as [
      keyof typeof setting,
      boolean,
    ][]) {
      mergedSettings[key] = mergedSettings[key] || value;
    }
  }

  return mergedSettings;
};
