import {useTheme} from '@mui/styles';
import {Pill} from '@ark/web';

const useMarketplaceStatus = (isClosed: boolean, isArchived: boolean) => {
  const {palette} = useTheme();

  if (isArchived) {
    return {
      color: palette.grey[700],
      label: 'Archived',
    };
  }

  if (isClosed) {
    return {
      color: palette.grey[500],
      label: 'Closed',
    };
  }

  return {
    color: palette.success.main,
    label: 'Live',
  };
};

type Props = {
  isClosed: boolean;
  isArchived: boolean;
};

export const MarketplaceStatusPill = ({isClosed, isArchived}: Props) => {
  const {color, label} = useMarketplaceStatus(isClosed, isArchived);
  return <Pill color={color} label={label} />;
};
