import React from 'react';
import {makeStyles} from '@mui/styles';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';
import {
  SelectProps,
  TextFieldProps,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  LinearProgress,
  useTheme,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';

export interface FormSelectOption {
  label: string;
  value: string;
  color?: string;
  disabled?: boolean;
}

export type FormSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<SelectProps, 'variant'> &
  TextFieldProps & {
    control: UseControllerProps<TFieldValues, TName>['control'];
    name: UseControllerProps<TFieldValues, TName>['name'];
    options: FormSelectOption[];
    rules?: UseControllerProps<TFieldValues, TName>['rules'];
    fontSize?: string;
    loading?: boolean;
    emptyMessage?: string;
  };

const useStyles = makeStyles({
  formControl: {
    minWidth: 155,
    width: '100%',
  },
  select: {},
  listIcon: {
    padding: 0,
    minHeight: 'initial',
    marginLeft: 8,
  },
});

export default function FormSelect<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: FormSelectProps<TFieldValues, TName>) {
  const {
    control,
    options,
    rules,
    name,
    label,
    fontSize,
    disabled,
    loading,
    emptyMessage,
    sx,
    multiple,
    ...rest
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field, fieldState: {error}}) => {
        const selectedColor = options.find(
          option => option.value === field.value,
        )?.color;
        return (
          <FormControl
            variant="outlined"
            size="small"
            className={classes.formControl}
            error={Boolean(error?.message)}
            disabled={disabled || loading}
            sx={sx}
          >
            {label && <InputLabel id={name}>{label}</InputLabel>}
            <Select
              labelId={name}
              id={name}
              fullWidth
              label={label}
              error={!!error}
              multiple={multiple}
              {...field}
              {...rest}
              onChange={e => {
                props.onChange?.(e, false);
                field.onChange(e);
              }}
              style={{
                color: selectedColor,
                fontSize: fontSize,
              }}
              className={classes.select}
              renderValue={selected =>
                options
                  .filter(option => {
                    if (multiple) {
                      return (selected as string[]).includes(option.value);
                    }
                    return selected === option.value;
                  })
                  .map(option => option.label)
                  .join(', ')
              }
            >
              {options.length > 0 &&
                options.map((option, idx) => (
                  <MenuItem
                    value={option.value}
                    key={idx}
                    style={{color: option.color}}
                    disabled={option.disabled}
                  >
                    <Typography variant="inherit" noWrap>
                      {option.label}
                    </Typography>
                    {Array.isArray(field.value) &&
                      field.value.includes(option.value) && (
                        <ListItemIcon className={classes.listIcon}>
                          <CheckIcon
                            fontSize="small"
                            style={{color: theme.palette.success.main}}
                          />
                        </ListItemIcon>
                      )}
                  </MenuItem>
                ))}
              {options.length === 0 && emptyMessage && (
                <Typography ml="16px">{emptyMessage}</Typography>
              )}
            </Select>
            {loading && (
              <LinearProgress
                sx={{
                  marginTop: '-2px',
                  marginX: '1px',
                  height: '2px',
                  borderBottomLeftRadius: '4px',
                  borderBottomRightRadius: '2px',
                }}
              />
            )}
            {error && (
              <Typography
                color={theme.palette.error.main}
                variant="body2"
                mt={0.5}
                ml={2}
              >
                {error.message}
              </Typography>
            )}
          </FormControl>
        );
      }}
    />
  );
}
