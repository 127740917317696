import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  Link,
  Typography,
} from '@mui/material';
import {ResponsiveDialog} from 'common';
import {DialogClose} from 'common';
import {useForm} from 'react-hook-form';
import {makeStyles} from '@mui/styles';
import BrickLogo from '@/assets/brickLogo.png';
import FormSelect from '@/components/forms/FormSelect';
import AddAppMFA from './ConfirmAppDialog';
import ConfirmPhoneDialog from './ConfirmPhoneDialog';
import AddPhoneDialog from './AddPhoneDialog';
import {userProfileContext} from 'common';
import SuccessDialog from './SuccessDialog';

const useStyles = makeStyles(theme => ({
  logo: {
    background: theme.palette.grey[100],
    height: '70px',
    borderRadius: '50%',
    padding: '10px',
    width: '70px',
    margin: 'auto',
    objectFit: 'contain',
    alignSelf: 'center',
    marginTop: '20px',
  },
}));

interface InitialContentProps {
  setMethod: (method: 'app' | 'text') => void;
}

const InitialContent = (props: InitialContentProps) => {
  const {control, handleSubmit} = useForm({
    defaultValues: {
      method: 'app' as 'app' | 'text',
    },
  });

  const onSubmit = ({method}: {method: 'app' | 'text'}) => {
    props.setMethod(method);
  };
  return (
    <>
      <DialogContent>
        <form id="select-mfa-type" onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h3" textAlign="center" mb={2}>
            Help keep your Ark account secure with multi-factor authentication
          </Typography>
          <Typography variant="body1" mb={2} textAlign="center">
            Each time you log in, you’ll be asked to enter a one-off security
            code in addition to your password. Choose how you want to receive
            your code.
          </Typography>
          <FormSelect
            name="method"
            control={control}
            options={[
              {
                label: 'Authenticator app',
                value: 'app',
              },
              {
                label: 'Text message',
                value: 'text',
              },
            ]}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="ark"
          fullWidth
          color="primary"
          form="select-mfa-type"
          type="submit"
        >
          Set up MFA
        </Button>
      </DialogActions>
      <Link
        target="_blank"
        href="https://ark-rent.notion.site/Ark-and-MFA-330175f88eca4aa78cd556a1ff33fcd3?pvs=4"
      >
        <Typography variant="body1" mb={4} textAlign="center" color="#2D9CDB">
          What’s an authenticator app?
        </Typography>
      </Link>
    </>
  );
};

const InitialSetup = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const styles = useStyles();
  const [openDialog, setOpenDialog] = React.useState<
    null | 'app' | 'text' | 'success'
  >(null);

  //@ts-ignore
  const {mobile} = React.useContext(userProfileContext);

  const handleClose = () => {
    onClose();
    setOpenDialog(null);
  };

  const onSuccess = () => {
    setOpenDialog('success');
  };

  return (
    <ResponsiveDialog open={open} onClose={handleClose} maxWidth="sm">
      <DialogClose onClose={handleClose} />
      <img src={BrickLogo} alt="logo" className={styles.logo} />
      {openDialog === null && <InitialContent setMethod={setOpenDialog} />}
      {openDialog === 'app' && <AddAppMFA onSuccess={onSuccess} />}
      {openDialog === 'text' && mobile && (
        <ConfirmPhoneDialog onSuccess={onSuccess} />
      )}
      {openDialog === 'text' && !mobile && <AddPhoneDialog />}
      {openDialog === 'success' && <SuccessDialog onClose={handleClose} />}
    </ResponsiveDialog>
  );
};

export default InitialSetup;
