import btl0 from '@/assets/backgrounds/btl/0.jpg';
import btl1 from '@/assets/backgrounds/btl/1.jpg';
import btl2 from '@/assets/backgrounds/btl/2.jpg';
import btl3 from '@/assets/backgrounds/btl/3.jpg';
import btl4 from '@/assets/backgrounds/btl/4.jpg';
import ent0 from '@/assets/backgrounds/enterprise/0.jpg';
import ent1 from '@/assets/backgrounds/enterprise/1.jpg';
import ent2 from '@/assets/backgrounds/enterprise/2.jpg';
import ent3 from '@/assets/backgrounds/enterprise/3.jpg';
import ent4 from '@/assets/backgrounds/enterprise/4.jpg';
import ent5 from '@/assets/backgrounds/enterprise/5.jpg';
import ent6 from '@/assets/backgrounds/enterprise/6.jpg';
import ent7 from '@/assets/backgrounds/enterprise/7.jpg';

const BTL_IMAGE_MAP = [btl0, btl1, btl2, btl3, btl4];
const ENTERPRISE_IMAGE_MAP = [ent0, ent1, ent2, ent3, ent4, ent5, ent6, ent7];

const randomImage = (str: string, imageMap: string[]): string => {
  let hash = 0,
    i,
    chr;
  if (!str) return imageMap[0];
  if (str.length === 0) return imageMap[0];
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }

  const index = Math.abs(hash) % (imageMap.length - 1);
  return imageMap[index];
};

export const randomBTLImage = (str: string): string => {
  return randomImage(str, BTL_IMAGE_MAP);
};

export const randomEnterpriseImage = (str: string): string => {
  return randomImage(str, ENTERPRISE_IMAGE_MAP);
};

export const isImage = (extension: string) => {
  return ['png', 'jpg', 'jpeg', 'gif'].includes(extension?.toLowerCase());
};
