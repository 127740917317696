import React from 'react';
import {makeStyles} from '@mui/styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import {colors} from '../theme/colors';
import {Tooltip, useTheme} from '@mui/material';

export interface DialogCloseProps {
  onClose: () => void;
  positionLeft?: boolean;
}

const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    top: 16,
    borderRadius: 8,
    padding: 3,
    zIndex: 1,
    backgroundColor: colors.white,
  },
  rightClose: {
    right: 16,
  },
  leftClose: {
    left: 16,
  },
});

export const DialogClose: React.FC<DialogCloseProps> = props => {
  const classes = useStyles();
  const {palette} = useTheme();

  return (
    <Tooltip title="Close">
      <IconButton
        aria-label="close"
        className={`${classes.closeButton} ${
          props.positionLeft ? classes.leftClose : classes.rightClose
        }`}
        onClick={props.onClose}
        size="large"
      >
        <CloseRoundedIcon sx={{color: palette.grey[600], fontSize: 22}} />
      </IconButton>
    </Tooltip>
  );
};
