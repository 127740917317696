import PageContainer from '@/components/PageContainer';
import Layout from '@/layout';
import {
  ArkLoader,
  CommunityFilterProvider,
  CommunityProvider,
  EmptyList,
  UserProfileProvider,
} from 'common';
import React, {useEffect} from 'react';
import {useIntercom} from 'react-use-intercom';
import AdminRoot from './Admin/AdminRoot';
import ConciergeRoot from './Concierge/ConciergeRoot';
import LandlordRoot from './Landlord/LandlordRoot';
import ManagerRoot from './Manager/ManagerRoot';
import {useUserTypeContext} from '@/contexts/UserTypeContext/UserTypeContext';
import {useFeatures} from '@/hooks/useFeatures/useFeatures';
import {NotificationContext} from '@/hooks/useInitializeNotifications';
import {useMFA} from '@/hooks/useMFA';
import InitialSetup from '@/pages/ENT/ProfilePage/InitialSetupDialog';
import {Identify, identify} from '@amplitude/analytics-browser';
import {compact} from 'lodash';
import {edgesToNodes} from '@/utils/edgesToNodes';
import {SideBarAccordionProvider} from '@/contexts/SideBarAccordionContext';

const AuthenticatedRoot: React.FC = () => {
  const {clientAdmin, manager, concierge, email, loading} =
    useUserTypeContext();
  const {update} = useIntercom();

  useEffect(() => {
    const identifyObj = new Identify();
    if (email) identifyObj.set('email', email);
    if (clientAdmin) identifyObj.postInsert('Permission Levels', 'Admin');
    if (manager) identifyObj.postInsert('Permission Levels', 'Manager');
    if (concierge) identifyObj.postInsert('Permission Levels', 'Concierge');
    identify(identifyObj);
  }, [clientAdmin, manager, concierge, email]);

  React.useEffect(() => {
    if (manager?.email) {
      update({
        email: manager.email,
      });
    }
  }, [update, manager]);

  const [{MFA}] = useFeatures();
  const {mfaDevices, initialized} = useMFA();
  const [openDialog, setOpenDialog] = React.useState(false);

  const onClose = async () => {
    setOpenDialog(false);
  };

  React.useEffect(() => {
    if (initialized && MFA && !mfaDevices.app && !mfaDevices.text) {
      setOpenDialog(true);
    }
  }, [MFA, setOpenDialog, mfaDevices, initialized]);

  if (loading) {
    return <ArkLoader />;
  }

  if (manager && manager?.client?.clientType === 'BTL') {
    return (
      <UserProfileProvider>
        <LandlordRoot />
      </UserProfileProvider>
    );
  }

  if (
    manager &&
    manager?.client?.clientType === 'ENT' &&
    (manager?.community?.totalCount || 0) > 0
  ) {
    return (
      <UserProfileProvider>
        <NotificationContext>
          <CommunityProvider
            communities={edgesToNodes(manager.community?.edges)}
          >
            <CommunityFilterProvider>
              <SideBarAccordionProvider>
                <ManagerRoot isAdmin={Boolean(clientAdmin)} />
                <InitialSetup onClose={onClose} open={openDialog} />
              </SideBarAccordionProvider>
            </CommunityFilterProvider>
          </CommunityProvider>
        </NotificationContext>
      </UserProfileProvider>
    );
  }

  if (clientAdmin) {
    return (
      <UserProfileProvider>
        <CommunityProvider
          communities={edgesToNodes(clientAdmin?.communities?.edges)}
        >
          <CommunityFilterProvider>
            <AdminRoot />
          </CommunityFilterProvider>
          <InitialSetup onClose={onClose} open={openDialog} />
        </CommunityProvider>
      </UserProfileProvider>
    );
  }

  if (concierge && (concierge?.communities?.totalCount || 0) > 0) {
    return (
      <UserProfileProvider>
        <CommunityProvider
          communities={compact(edgesToNodes(concierge.communities?.edges))}
        >
          <CommunityFilterProvider>
            <ConciergeRoot />
            <InitialSetup onClose={onClose} open={openDialog} />
          </CommunityFilterProvider>
        </CommunityProvider>
      </UserProfileProvider>
    );
  }

  return (
    <Layout>
      <PageContainer>
        <EmptyList text="You are not currently assigned to any communities, please get in touch with your administrator" />
      </PageContainer>
    </Layout>
  );
};

export default AuthenticatedRoot;
