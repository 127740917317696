import {
  ListItem,
  ListItemContent,
  ListItemEnd,
  ListItemTitle,
} from '@/components/list/ENT';
import {ListItemAvatar} from '@/components/list/ENT/ListItemAvatar';
import {MarketplaceItemFragment} from '@/generated/graphql';
import {fullImageUrl} from '@/utils/files';
import {LocalMall, LocationCity, Notes, Person} from '@mui/icons-material';
import {Box, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useNavigate} from 'react-router-dom';
import {MarketplaceStatusPill} from './MarketplaceStatusPill';
import {useLogEvent} from '@/hooks/useLogEvent/useLogEvent';

type Props = {
  item: MarketplaceItemFragment;
};

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
  },
}));

export const MarketplaceItem = ({item}: Props) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const logEvent = useLogEvent();

  return (
    <ListItem
      onClick={() => {
        navigate(`/marketplace/${item.id}`);
        logEvent({
          name: 'Marketplace Page - Marketplace List Item Clicked',
          data: {marketplaceItemId: item.id},
        });
      }}
    >
      <ListItemContent
        leftAdornment={
          <ListItemAvatar
            src={
              item.images.edges
                ? fullImageUrl(item.images.edges[0]?.node?.image)
                : undefined
            }
            icon={<LocalMall fontSize="large" />}
          />
        }
      >
        <ListItemTitle>{item.title}</ListItemTitle>
        <Box display="flex" gap={0.5} alignItems="center">
          <Person className={styles.icon} />
          <Typography color="textSecondary" mr={2}>
            {item.user.firstName} {item.user.lastName}
          </Typography>
          <LocationCity className={styles.icon} />
          <Typography color="textSecondary" mr={2}>
            {item.community.name}
          </Typography>
        </Box>
        <Box display="flex" gap={0.5} alignItems="flex-start">
          <Notes className={styles.icon} />
          <Typography
            color="textSecondary"
            variant="body2"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {item.description}
          </Typography>
        </Box>
      </ListItemContent>
      <ListItemEnd>
        <MarketplaceStatusPill
          isClosed={Boolean(item.closedOn)}
          isArchived={Boolean(item?.archivedOn)}
        />
      </ListItemEnd>
    </ListItem>
  );
};
