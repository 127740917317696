import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import UndoRoundedIcon from '@mui/icons-material/UndoRounded';

import {Typography} from '@mui/material';
import {FileThumbnail} from '@/components/FileThumbnail';

type PackageTimelineItemProps = {
  headerText: string;
  dateText?: string;
  bodyText: string;
  boldBodyText?: string;
  imageUrl?: string | null;
};

const PackageTimelineItem = ({
  headerText,
  dateText,
  bodyText,
  boldBodyText,
  imageUrl,
}: PackageTimelineItemProps) => {
  const DeliveryPhoto = () => {
    if (imageUrl) {
      return (
        <>
          <Typography variant="subtitle1" mb={1}>
            Delivery photo
          </Typography>
          <FileThumbnail
            sx={{mb: 2}}
            file={`${import.meta.env.VITE_IMAGE_PREFIX || ''}${imageUrl}`}
            imageOnly
          />
        </>
      );
    }
    return (
      <Typography variant="subtitle1" mb={1}>
        No delivery photo for this package.
      </Typography>
    );
  };

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot color="secondary">
          {headerText === 'Reminder' ? (
            <UndoRoundedIcon sx={{fontSize: 12}} />
          ) : (
            <DoneRoundedIcon sx={{fontSize: 12}} />
          )}
        </TimelineDot>
        {headerText !== 'Delivered' && (
          <TimelineConnector sx={{bgcolor: 'secondary.main'}} />
        )}
      </TimelineSeparator>
      <TimelineContent sx={{pt: '12px', px: 2, pb: '12px'}}>
        <Typography variant="h4" mb={2}>
          {headerText}
        </Typography>
        <Typography variant="subtitle1">{dateText}</Typography>
        <Typography variant="body1" paddingBottom={2}>
          {bodyText}
          {boldBodyText && <strong>{boldBodyText}</strong>}.
        </Typography>
        {headerText === 'Delivered' && <DeliveryPhoto />}
      </TimelineContent>
    </TimelineItem>
  );
};

export default PackageTimelineItem;
