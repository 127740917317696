import React, {useContext} from 'react';
import {useGetUserQuery} from '../generated/graphql';
import {Identify, identify, init} from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';

interface UserProfileContext {
  id?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  roles?: string[];
  mobile?: string | null;
  communityManagerCommunities?: string[];
}

const defaultUserState: UserProfileContext = {
  id: undefined,
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  roles: ['DE'],
  mobile: undefined,
};

export const userProfileContext =
  React.createContext<UserProfileContext>(defaultUserState);

interface UserProfileProviderProps {
  children: React.ReactNode;
}

export const useUserProfile = () => {
  const context = useContext(userProfileContext);
  if (!context) {
    throw new Error('useUserProfile must be used within a UserProfileProvider');
  }
  return context;
};

export const UserProfileProvider = (props: UserProfileProviderProps) => {
  const [state, setState] =
    React.useState<UserProfileContext>(defaultUserState);
  const {data: userData} = useGetUserQuery();

  React.useEffect(() => {
    const roles: string[] = [];
    if (userData?.getUser) {
      if (userData.getUser.clientAdmin?.id) {
        roles.push('AD');
      }
      if (userData.getUser.communityManager?.isActive) {
        roles.push('CM');
      }
      if (userData.getUser.manager?.isActive) {
        roles.push('MA');
      }
      if (userData.getUser.concierge?.isActive) {
        roles.push('CO');
      }
      if (roles.length === 0) {
        roles.push('DE');
      }

      setState(state => {
        return {
          ...state,
          id: userData?.getUser?.id,
          email: userData?.getUser?.email,
          firstName: userData?.getUser?.firstName,
          lastName: userData?.getUser?.lastName,
          mobile: userData?.getUser?.mobile,
          roles,
          communityManagerCommunities:
            userData?.getUser?.communityManager?.communities?.edges.map(
              edge => {
                if (!edge?.node) {
                  throw new Error('Unexpected null or undefined node');
                }
                return edge.node.id;
              },
            ) || [],
        };
      });
    }
    Sentry.setUser({
      email: userData?.getUser?.email,
    });
    const manager = userData?.getUser?.manager;
    if (manager && manager.client?.clientType) {
      const identifyObj = new Identify().set('Permission level', 'Manager');

      const apiKey =
        manager.client?.clientType === 'ENT'
          ? import.meta.env.VITE_AMPLITUDE_API_KEY_ENT
          : import.meta.env.VITE_AMPLITUDE_API_KEY_BTL;

      if (apiKey) {
        init(apiKey, manager.id);
      }

      identify(identifyObj);
    }
  }, [userData]);

  return (
    <userProfileContext.Provider value={state}>
      {props.children}
    </userProfileContext.Provider>
  );
};
