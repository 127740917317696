import {
  NotificationHookFragment,
  useCreateDeviceMutation,
  useFindNotificationLazyQuery,
} from '@/generated/graphql';
import {Box, Snackbar, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {captureException} from '@sentry/react';
import {MessagePayload, getToken, onMessage} from 'firebase/messaging';
import React, {useEffect} from 'react';
import ArkSymbol from '../assets/logos/ark_symbol_black.svg';
import {messaging} from '../firebase';

const useStyles = makeStyles(theme => ({
  symbol: {
    width: 24,
    height: 24,
    marginRight: 16,
  },
  snackbar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderRadius: 8,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
    padding: '16px 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
}));

export const useNotifications = () => {
  const [createNewDevice] = useCreateDeviceMutation();
  const status = window.Notification?.permission;

  const subscribe = React.useCallback(async () => {
    try {
      const token = await getToken(messaging(), {
        vapidKey: import.meta.env.VITE_VAPID_KEY,
      });
      if (token) {
        await createNewDevice({
          variables: {
            input: {
              token,
              platform: 'manager-dashboard',
              type: 'web',
            },
          },
        });
      }
    } catch (e) {
      captureException(e);
    }
  }, [createNewDevice]);

  React.useEffect(() => {
    if (status === 'granted') {
      subscribe();
    }
  }, [status, subscribe]);

  return {status, subscribe};
};

type ListenerType = (notification: NotificationHookFragment) => void;

const notificationContext = React.createContext<
  | {
      addListener: (
        listener: ListenerType,
        persistedName?: string,
      ) => () => void;
      notify: (payload: MessagePayload) => void;
    }
  | undefined
>(undefined);

type NotificationProviderProps = {
  children: React.ReactNode;
};

export const NotificationContext = ({children}: NotificationProviderProps) => {
  const styles = useStyles();
  const [listeners, setListeners] = React.useState<ListenerType[]>([]);
  const [message, setMessage] = React.useState<string>();
  const [fetchNotification] = useFindNotificationLazyQuery();
  const [, setPersistedNames] = React.useState<string[]>([]);

  const addListener = React.useCallback(
    (listener: ListenerType, persistedName?: string) => {
      if (!persistedName) {
        setListeners(listeners => [...listeners, listener]);
        return () => {
          setListeners(listeners => listeners.filter(l => l !== listener));
        };
      } else {
        setPersistedNames(names => {
          if (names.includes(persistedName)) {
            return names;
          }
          setListeners(listeners => [...listeners, listener]);
          return [...names, persistedName];
        });
      }
      return () => undefined;
    },
    [],
  );

  const notify = React.useCallback(
    async (payload: MessagePayload) => {
      setMessage(payload?.notification?.body);
      if (payload?.data?.id) {
        const data = await fetchNotification({
          variables: {id: payload.data.id},
        });
        listeners.forEach(
          listener =>
            data.data?.notification && listener(data.data.notification),
        );
      }
    },
    [listeners, fetchNotification],
  );

  useEffect(
    () =>
      onMessage(messaging(), payload => {
        notify(payload);
      }),
    [notify],
  );

  return (
    <notificationContext.Provider value={{addListener, notify}}>
      {children}
      <Snackbar
        open={!!message}
        onClose={() => setMessage(undefined)}
        message={message}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        sx={{zIndex: 99999}}
      >
        <Box className={styles.snackbar}>
          <Box display="flex" alignItems="center" mb={1} flexDirection="row">
            <img src={ArkSymbol} className={styles.symbol} alt="Ark Logo" />
            <Typography sx={{fontSize: 16, fontWeight: 500}}>
              Update from Ark
            </Typography>
          </Box>
          <Typography sx={{fontSize: 14}}>{message}</Typography>
        </Box>
      </Snackbar>
    </notificationContext.Provider>
  );
};

export const useSubscribeToNotifications = (
  callback: (notification?: NotificationHookFragment) => void | Promise<void>,
  persistedName?: string,
) => {
  const context = React.useContext(notificationContext);

  const savedListener = React.useRef<undefined | ListenerType>();

  useEffect(() => {
    savedListener.current = callback;
  }, [callback]);

  React.useEffect(() => {
    return context?.addListener(
      notification =>
        savedListener.current && savedListener.current(notification),
      persistedName,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedListener, persistedName]);

  if (!context) {
    console.warn(
      'useSubscribeToNotifications must be used within a NotificationContext',
    );
  }
};
