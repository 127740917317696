import React from 'react';
import {UserCommunityItemFragment} from '../generated/graphql';

type CommunityContext = {
  communities?: UserCommunityItemFragment[];
};

const defaultState = {
  communities: [],
};

export const communityContext =
  React.createContext<CommunityContext>(defaultState);

export const useCommunityContext = () => {
  const context = React.useContext(communityContext);
  if (context === undefined) {
    throw new Error(
      'useCommunityContext must be used within a CommunityProvider',
    );
  }
  return context;
};

type CommunityProviderProps = {
  children: React.ReactNode;
} & CommunityContext;

export const CommunityProvider = ({
  children,
  communities,
}: CommunityProviderProps) => {
  return (
    <communityContext.Provider
      value={{
        communities,
      }}
    >
      {children}
    </communityContext.Provider>
  );
};
