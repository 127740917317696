export default function AccordionSummary() {
  return {
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          marginTop: 11,
          marginBottom: 11,
        },
      },
    },
  };
}
