import React from 'react';
import {useGetFeaturesQuery} from '@/generated/graphql';

export const spacedToCamel = (str: string) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word : word.toUpperCase();
    })
    .replace(/\s+/g, '');

type FeatureObject = [
  {
    [feature: string]: {isBeta: string};
  },
  boolean,
];

export const useFeatures = (): FeatureObject => {
  const {data, loading} = useGetFeaturesQuery();

  const features = React.useMemo(() => {
    if (!data?.getClient?.features) return {};
    return data.getClient?.features.reduce(
      (obj, feature) =>
        feature.name
          ? Object.assign(obj, {
              [spacedToCamel(feature.name)]: {isBeta: feature.isBeta},
            })
          : obj,
      {},
    );
  }, [data]);
  return [features, loading];
};
